import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { BCInput, BCPopover } from "@components/index";
import SearchContent from "./components/SearchContent";
import {
  useLazyGetAppsQuery,
  useLazyGetConsultanciesFilterQuery,
  useLazyGetPartnersQuery,
} from "@services/index";
import debounce from "lodash/debounce";
import "./assets/style.scss";

const SearchList = () => {
  const { t } = useTranslation();

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [selectedMenu, setSelectedMenu] = useState<string>("menu1");
  const [datas, setDatas] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [
    getApps,
    {
      isLoading: getAppsisLoading,
      isSuccess: getAppsisSuccess,
      isError: getAppsisErrors,
      error: getAppserror,
      data: filteredApps,
      fulfilledTimeStamp: appsTimeS,
    },
  ] = useLazyGetAppsQuery();

  const [
    getConsultancies,
    {
      isLoading: getConsultanciesisLoading,
      isSuccess: getConsultanciesisSuccess,
      isError: getConsultanciesisErrors,
      error: getConsultancieserror,
      data: filteredConsultancies,
      fulfilledTimeStamp: consultanciesTimeS,
    },
  ] = useLazyGetConsultanciesFilterQuery();

  const [
    getPartners,
    {
      isLoading: getPartnersisLoading,
      isSuccess: getPartnersisSuccess,
      isError: getPartnersisErrors,
      error: getPartnerserror,
      data: filteredPartners,
      fulfilledTimeStamp: partnerTimeS,
    },
  ] = useLazyGetPartnersQuery();

  const handleSearch = (searchTerm: string, selectedMenu: string) => {
    try {
      if (selectedMenu == "menu1") {
        getApps({
          APPNAME: searchTerm,
          APPTYPE: [],
          SECTOR: [],
          CATEGORY: [],
          MANUFACTURER: "",
          ISPAID: "",
          MINFEE: "",
          MAXFEE: "",
          ISDEMOAPP: "",
          MULTILANGUAGES: "",
          POINT: 0,
        });
      }

      if (selectedMenu == "menu2") {
        getConsultancies({
          CONSULTANCYNAME: searchTerm,
          LISTTYPE: [],
          SECTOR: [],
          CATEGORY: [],
          SERVICETYPE: "",
          ISPAID: [],
          MINFEE: "",
          MAXFEE: "",
        });
      }

      if (selectedMenu == "menu3") {
        getPartners({
          name: searchTerm,
          country: "",
          city: "",
          industryInformation: null,
          services: null,
          supportGivenProducts: null,
          segments: null,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm, selectedMenu) => {
      handleSearch(searchTerm, selectedMenu);
    }, 1000),
    []
  );

  useEffect(() => {
    if (name) {
      setLoading(true);
      debouncedSearch(name, selectedMenu);
    } else {
      setDatas([]);
    }
  }, [name, selectedMenu]);

  useEffect(() => {
    if (getAppsisSuccess || getConsultanciesisSuccess || getPartnersisSuccess) {
      setDatas([]);
      let combinedDatas: any = [];

      if (selectedMenu == "menu1") {
        combinedDatas = [...(Array.isArray(filteredApps) ? filteredApps : [])];
      }
      if (selectedMenu == "menu2") {
        combinedDatas = [
          ...(Array.isArray(filteredConsultancies)
            ? filteredConsultancies
            : []),
        ];
      }
      if (selectedMenu == "menu3") {
        combinedDatas = [
          ...(Array.isArray(filteredPartners) ? filteredPartners : []),
        ];
      }

      setDatas(combinedDatas);
      setLoading(false);
    }
  }, [appsTimeS, consultanciesTimeS, partnerTimeS]);

  useEffect(() => {
    if (getAppsisErrors || getConsultanciesisErrors || getPartnersisErrors) {
      setLoading(false);
    }
  }, [getAppsisErrors, getConsultanciesisErrors, getPartnersisErrors]);

  const handleInputChange = (e: any) => {
    setName(e.target.value);
  };

  const handleInputFocus = () => {
    setIsOpenPopover(true);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };

  return (
    <BCPopover
      className="searchListPopover"
      trigger={"click"}
      placement="bottom"
      content={
        <SearchContent
          data={datas}
          loading={loading}
          setIsOpenPopover={setIsOpenPopover}
          setSelectedMenu={setSelectedMenu}
        />
      }
      open={isOpenPopover}
      arrow={false}
      onOpenChange={handleOpenChange}
    >
      <BCInput
        allowClear
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        value={name}
        placeholder={t("000145")}
        suffix={<SearchOutlined />}
        className="searchListInput"
      />
    </BCPopover>
  );
};

export default SearchList;
