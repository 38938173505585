import { BCCol, BCForm, BCFormItem, BCInput, BCInputTextArea, BCRow, BCSelect } from '@components/index'

const Contact = () => {

    return (
        <BCForm>
            <BCRow >
                <BCCol span={24}>
                    <BCFormItem label="E-posta" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Şirket E-posta adresini yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow >
                <BCCol span={24}>
                    <BCFormItem label="İş Telefonu" required labelCol={{ span: 24 }}>
                        <BCInput addonBefore={<BCSelect />} placeholder='İş telefonunuzu yazınız' />
                    </BCFormItem >
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem label="Mobil" required labelCol={{ span: 24 }}>
                        <BCInput addonBefore={<BCSelect />} placeholder='Mobil numaranızı yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem label="Web Adresi" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Web adresinizi yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem label="Adres" required labelCol={{ span: 24 }}>
                        <BCInputTextArea placeholder='Şirket adresinizi yazınız' rows={3} />
                    </BCFormItem>
                </BCCol>
            </BCRow>
        </BCForm>
    )
}

export default Contact