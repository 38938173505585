import { useState } from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { openModal, closeModal } from "@store/index";
import { BCButton, BCModal, BCTabs } from "@components/index";
import General from "./tabs/general";
import Contact from "./tabs/contact";
import AppealAndConditions from "./tabs/appealAndConditions";
import "./assets/style.scss";

const PartnerAppeal = (props: any) => {
  const { parameters } = props;

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const tabs = [
    {
      key: "1",
      label: "Genel",
      children: <General />,
    },
    {
      key: "2",
      label: "İletişim",
      children: <Contact />,
    },
    {
      key: "3",
      label: "Başvuru ve Koşullar",
      children: <AppealAndConditions />,
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(closeModal());
  };

  return (
    <BCModal
      open={isModalOpen}
      title="Partner Başvurusu"
      onCancel={handleCancel}
      className="partnerAppealContainer"
    >
      <BCTabs items={tabs} activeKey={String(activeTab)} />
      <div className="partnerAppealFooter">
        <div>
          <Space.Compact block>
            <BCButton
              disabled={activeTab == 1}
              onClick={() => setActiveTab(activeTab - 1)}
              style={{
                backgroundColor: activeTab == 1 ? "#e8e3de" : "#fff",
                color: "#00104E",
              }}
              content={<LeftOutlined />}
            />

            <BCButton
              disabled={activeTab == 3}
              onClick={() => setActiveTab(activeTab + 1)}
              style={{
                backgroundColor: activeTab == 3 ? "#e8e3de" : "#fff",
                color: "#00104E",
              }}
              content={<RightOutlined />}
            />
          </Space.Compact>
        </div>
        <div>
          {activeTab == 3 ? (
            <BCButton
              content="Gönder"
              style={{ backgroundColor: "#00104E", color: "#fff" }}
              onClick={() => dispatch(openModal({ modal: "Information" }))}
            />
          ) : null}
          <BCButton
            content="İptal"
            onClick={handleCancel}
            style={{
              backgroundColor: "#fff",
              color: "#00104E",
              marginLeft: "5px",
            }}
          />
        </div>
      </div>
    </BCModal>
  );
};

export default PartnerAppeal;
