import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { IPartnerSliceState } from "./entities";

const initialState: IPartnerSliceState = {
  filters: [],
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    partnerFilter: (state, action: PayloadAction<IPartnerSliceState>) => {
      state.filters = action.payload.filters;
    },
  },
});

export const { partnerFilter } = partnerSlice.actions;
export default partnerSlice.reducer;
export const selectCurrentPartnerFilters = (state: RootState) =>
  state.partner.filters;
