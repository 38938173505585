import { Menu } from 'antd';
import { IMenuProps } from './entities'

const BCMenu = (props: IMenuProps) => {
    const { collapsed, items, mode, style, className, defaultSelectedKeys, onClick } = props;

    return (
        <Menu
            onClick={onClick}
            defaultSelectedKeys={defaultSelectedKeys}
            mode={mode}
            theme='light'
            inlineCollapsed={collapsed}
            items={items}
            className={className}
            style={style}
        />
    )
}

export default BCMenu