import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import type { MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
// import { FaRegUser } from "react-icons/fa";
// import { SlLock } from "react-icons/sl";
import { FaRegStar } from "react-icons/fa";
// import { GrHistory } from "react-icons/gr";
import { TbApps } from "react-icons/tb";
import { PiSignOutBold } from "react-icons/pi";
import {
  BCButton,
  BCLayout,
  BCLayoutContent,
  BCLayoutSider,
  BCMenu,
  BCSpin,
} from "@components/index";
import { logOut, selectCurrentUser } from "@store/index";
import "./assets/style.scss";

const DashLayout = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerInfo: any = useSelector(selectCurrentUser);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [logoutLoading, setLogoutLoading] = useState(false);

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      label,
      key,
      icon,
      children,
      type,
    } as MenuItem;
  }

  const tenantMenuItems = () => {
    let menuNumber: number = 51;

    const menuItems = customerInfo?.tenantInfoDetails?.map(
      (tenant: any, index: number) => {
        const linkLabel = `${tenant.firstName} ${tenant.lastName}`;
        const link = (
          <Link to={`http://${tenant.tenantURL}`} target="_blank">
            {linkLabel}
          </Link>
        );

        const menuItem = getItem(link, `menu${menuNumber}`);
        menuNumber += 1;

        return menuItem;
      }
    );

    return menuItems || [];
  };

  const items: MenuProps["items"] = [
    // getItem("Profilim", 'menu1', <Link to={'/dash'}><FaRegUser size='18px' /></Link>),
    // getItem("Güvenlik ayarları", 'menu2', <Link to={'security'}><SlLock size='18px' /></Link>),
    getItem(t("000077"), "menu3", <FaRegStar size="18px" />, [
      getItem(<Link to={"favorites/apps"}>{t("000133")}</Link>, "menu31"),
      getItem(
        <Link to={"favorites/consultancies"}>{t("000134")}</Link>,
        "menu32"
      ),
      getItem(<Link to={"favorites/partners"}>{t("000135")}</Link>, "menu33"),
    ]),
    // getItem("Sipariş geçmişim", 'menu4', <Link to={'orderHistory'}><GrHistory size='18px' /></Link>),
    { type: "divider" },
    getItem(
      "Synergy Portal",
      "menu5",
      <TbApps size="18px" />,
      tenantMenuItems()
    ),
    getItem(
      t("000079"),
      "menu6",
      logoutLoading ? <BCSpin /> : <PiSignOutBold size="18px" />
    ),
  ];

  const onBreakpoint = (broken: boolean) => {
    setCollapsed(broken);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    setLogoutLoading(true);

    setTimeout(() => {
      dispatch(logOut());
      setLogoutLoading(false);
    }, 1800);
  };

  const getDefaultSelectedKey = () => {
    switch (pathname) {
      case "/dash":
        return ["menu1"];
      case "/dash/security":
        return ["menu2"];
      case "/dash/favorites/apps":
        return ["menu31"];
      case "/dash/favorites/consultancies":
        return ["menu32"];
      case "/dash/favorites/partners":
        return ["menu33"];
      case "/dash/orderHistory":
        return ["menu4"];
      default:
        return ["menu1"];
    }
  };

  const handleMenuClick = (e: any) => {
    if (e.key == "menu6") {
      handleLogout();
    }
  };

  return (
    <BCLayout className="dashContainer">
      <BCLayoutSider
        collapsed={collapsed}
        collapsedWidth={"46px"}
        className="dashSider"
        onBreakpoint={onBreakpoint}
      >
        <BCButton
          onClick={toggleCollapsed}
          className="dashCollepseButton"
          type="text"
          content={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />

        <BCMenu
          items={items}
          mode="inline"
          collapsed={collapsed}
          className="dashSiderMenu"
          defaultSelectedKeys={getDefaultSelectedKey()}
          onClick={handleMenuClick}
        />
      </BCLayoutSider>
      <BCLayoutContent>
        <Outlet />
      </BCLayoutContent>
    </BCLayout>
  );
};

export default DashLayout;
