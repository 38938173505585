import { Avatar } from 'antd';
import { IAvatarProps } from './entities';


const BCAvatar: React.FC<IAvatarProps>  = (props) => {
    const { gap, icon, shape, size, src, style, className } = props;

    return (
        <Avatar
            size={size}
            icon={icon}
            shape={shape}
            gap={gap}
            src={src}
            style={style}
            className={className}
        />
    )
}

export default BCAvatar