import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BCButton, BCCard, BCCol, BCRow } from '..'
import { ICarouselCardPartnerProps } from './entities'
import './assets/style.scss'


const BCCarouselCardPartner = (props: ICarouselCardPartnerProps) => {
    const { cover, loading, actions, coverLetter, producer, average, comment, partnerName, shortCode } = props;

    const { t } = useTranslation()
    const { pathname } = useLocation()
    const customHeadStyle: React.CSSProperties = { backgroundColor: "#d23135", color: "#fff", position: "absolute", left: "-1px", top: "-1px", borderRadius: "8px 0 8px 0" }
    const customBodyStyle: React.CSSProperties = { display: "flex", flexDirection: "column", gap: "8px", paddingBottom: 0 }
    const navigationUrl = pathname == "/partners" ? `${shortCode}/detail` : `partners/${shortCode}/detail`

    return (
        <Link to={navigationUrl} >
            <BCCard
                cover={cover}
                actions={actions}
                hoverable
                size='small'
                headStyle={customHeadStyle}
                bodyStyle={customBodyStyle}
                className='CarouselCardPartnerContainer'
            >
                <BCRow className='CardPartnerHeader' justify={'space-between'} >
                    <BCCol span={16} className='partnerName'>{partnerName}</BCCol>
                    <BCCol span={7} className='platinumSegment'>PLATINUM</BCCol>
                </BCRow>
                <BCRow className='coverLetter'>
                    {coverLetter}
                </BCRow>
                {/* <BCRow justify={'end'}>
                    <BCCol >
                        <BCButton text={t('000021')} onClick={(e) => {
                            e.preventDefault();
                        }} />
                    </BCCol>
                </BCRow> */}
            </BCCard>
        </Link>
    )
}

export default BCCarouselCardPartner