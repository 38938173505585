import { Layout } from 'antd';
import { ILayoutFooterProps } from './entities';


const BCLayoutFooter = (props: ILayoutFooterProps) => {
    const { className, style } = props;
    const { Footer } = Layout;

    return (
        <Footer
            className={className}
            style={style}
        >
            {props.children}
        </Footer>
    )
}

export default BCLayoutFooter