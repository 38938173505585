import { Form } from 'antd';
import { IFormItemProps } from './entities';

const BCFormItem = (props: IFormItemProps) => {
	const {
		label,
		labelAlign,
		name,
		colon,
		hasFeedback,
		validateDebounce,
		validateStatus,
		tooltip,
		help,
		dependencies,
		rules,
		required,
		wrapperCol,
		labelCol
	} = props;

	return (
		<Form.Item
			label={label}
			labelAlign={labelAlign}
			name={name}
			colon={colon}
			hasFeedback={hasFeedback}
			validateDebounce={validateDebounce}
			validateStatus={validateStatus}
			tooltip={tooltip}
			help={help}
			dependencies={dependencies}
			rules={rules}
			required={required}
			wrapperCol={wrapperCol}
			labelCol={labelCol}
			style={{ marginBottom: '2px' }}
		>
			{props.children}
		</Form.Item>
	);
};

export default BCFormItem;
