import { useTranslation } from "react-i18next";
import { PageTopCarousel, FeaturedAppCarousel, ApplicationCarousel } from '@features/index'
import { useGetOpportunityAppsQuery, useGetTopTenAppsQuery } from '@services/index'
import './assets/style.scss'

const Home = () => {

  const { t } = useTranslation()

  const breakpoints = {
    500: {
      slidesPerView: 1.2,
      spaceBetween: 10,
    },
    600: {
      slidesPerView: 1.4,
      spaceBetween: 10,
    },
    700: {
      slidesPerView: 1.8,
      spaceBetween: 10,
    },
    750: {
      slidesPerView: 2.1,
      spaceBetween: 10,
    },
    800: {
      slidesPerView: 2.5,
      spaceBetween: 10,
    },
    850: {
      slidesPerView: 2.7,
      spaceBetween: 10,
    },
    960: {
      slidesPerView: 2.9,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 3.1,
      spaceBetween: 10,
    },
    1124: {
      slidesPerView: 3.3,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 3.5,
      spaceBetween: 10,
    },
    1280: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
  }

  const {
    data: topTenApps,
    isLoading: topTenAppsisLoading,
    isSuccess: topTenAppsisSuccess,
    isError: topTenAppsisError,
    error: topTenAppserror
  } = useGetTopTenAppsQuery()

  const {
    data: opportunityApps,
    isLoading: opportunityAppsisLoading,
    isSuccess: opportunityAppsisSuccess,
    isError: opportunityAppsisError,
    error: opportunityAppserror
  } = useGetOpportunityAppsQuery()


  return (
    <>
      <PageTopCarousel />
      <div className='homeContainer'>
        <FeaturedAppCarousel breakpoints={breakpoints} />
        <ApplicationCarousel loading={topTenAppsisLoading} success={topTenAppsisSuccess} data={topTenApps} headerTittle={t('000008')} applicationType='topTenApps' />
        <ApplicationCarousel loading={opportunityAppsisLoading} success={opportunityAppsisSuccess} data={opportunityApps} headerTittle={t('000009')} applicationType='opportunityApps' />
      </div>
    </>
  )
}

export default Home