import { Col } from 'antd';
import { IColProps } from './entities';

const BCCol = (props: IColProps) => {
    const { flex, offset, order, pull, push, span, style, xs, sm, md, lg, xl, xxl, className } = props

    return (
        <Col
            flex={flex}
            offset={offset}
            order={order}
            pull={pull}
            push={push}
            span={span}
            style={{ ...style }}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            xxl={xxl}
            className={className}
        >
            {props.children}
        </Col>
    )
}

export default BCCol