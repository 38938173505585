import { Space } from 'antd';
import { ISpaceProps } from './entities';

const BCSpace = (props: ISpaceProps) => {
    const { direction, size, align, wrap, split, style, className } = props;

    return (
        <Space
            direction={direction}
            size={size}
            align={align}
            wrap={wrap}
            split={split}
            style={style}
            className={className}
        >
            {props.children}
        </Space>
    )
}

export default BCSpace