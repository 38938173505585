import { ServiceList, baseApi } from "@services/index"
import {
    ICreateConsultancyRequest,
    ICreateConsultancyResponse,
    ICreateFavConsultanciesRequest,
    ICreateFavConsultanciesResponse,
    IDeleteFavConsultanciesRequest,
    IDeleteFavConsultanciesResponse,
    IGetConsultanciesFilterRequest,
    IGetConsultanciesFilterResponse,
    IGetConsultancyDetailRequest,
    IGetConsultancyDetailResponse,
    IGetFavConsultanciesListResponse,
    IGetFeaturedConsultancyResponse,
    IGetGetTopTenConsultancyResponse,
    IGetOppurtunityConsultancyResponse
} from "./entities"

export const consultancyApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        GetConsultanciesFilter: builder.query<any, IGetConsultanciesFilterRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.GetConsultanciesFilter,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: IGetConsultanciesFilterResponse) => response.result
        }),
        GetConsultancyDetail: builder.query<any, IGetConsultancyDetailRequest>({
            query: (SYSTEMNAME) => ({
                url: ServiceList.baseQuery2.GetConsultancyDetail,
                method: 'POST',
                body: SYSTEMNAME
            }),
            transformResponse: (response: IGetConsultancyDetailResponse) => response.result,
            providesTags: ['Consultancy']
        }),
        GetFeaturedConsultancy: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetFeaturedConsultancy,
                method: 'GET',
            }),
            transformResponse: (response: IGetFeaturedConsultancyResponse) => response.result
        }),
        GetTopTenConsultancy: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetTopTenConsultancy,
                method: 'GET',
            }),
            transformResponse: (response: IGetGetTopTenConsultancyResponse) => response.result
        }),
        GetOppurtunityConsultancy: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetOppurtunityConsultancy,
                method: 'GET',
            }),
            transformResponse: (response: IGetOppurtunityConsultancyResponse) => response.result
        }),
        CreateConsultancyRequest: builder.mutation<any, ICreateConsultancyRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateConsultancyRequest,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: ICreateConsultancyResponse) => response,
            invalidatesTags: ['Consultancy']
        }),
        CreateFavConsultancies: builder.mutation<any, ICreateFavConsultanciesRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateFavConsultancies,
                method: 'POST',
                body: parameters
            }),
            transformResponse: (response: ICreateFavConsultanciesResponse) => response,
            invalidatesTags: ['Consultancy']
        }),
        DeleteFavConsultancies: builder.mutation<any, IDeleteFavConsultanciesRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.DeleteFavConsultancies,
                method: 'POST',
                body: parameters
            }),
            transformResponse: (response: IDeleteFavConsultanciesResponse) => response,
            invalidatesTags: ['Consultancy']
        }),
        GetFavConsultanciesList: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetFavConsultanciesList,
                method: 'GET',
            }),
            transformResponse: (response: IGetFavConsultanciesListResponse) => response.result,
            providesTags: ['Consultancy']
        }),
    })
})

export const {
    useGetConsultanciesFilterQuery,
    useLazyGetConsultanciesFilterQuery,
    useLazyGetConsultancyDetailQuery,
    useGetFeaturedConsultancyQuery,
    useGetTopTenConsultancyQuery,
    useGetOppurtunityConsultancyQuery,
    useCreateConsultancyRequestMutation,
    useCreateFavConsultanciesMutation,
    useDeleteFavConsultanciesMutation,
    useLazyGetFavConsultanciesListQuery
} = consultancyApi 