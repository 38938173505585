import { Tooltip } from 'antd';
import { ITooltipProps } from './entities';

const BCTooltip = (props: ITooltipProps) => {
	const { title, arrow, color, open, placement, trigger, zIndex, content } = props;

	return (
		<Tooltip
			title={title}
			arrow={arrow}
			open={open}
			placement={placement}
			trigger={trigger}
			zIndex={zIndex}
			color={color}
		>
			{content}
		</Tooltip>
	);
};

export default BCTooltip;
