import { Progress } from 'antd';
import { IProgressProps } from './entities';

const BCProgress = (props: IProgressProps) => {
    const { percent, status, showInfo, size, type, format } = props;

    return (
        <Progress
            percent={percent}
            status={status}
            showInfo={showInfo}
            size={size}
            type={type}
            format={format}
            strokeColor={'#FFAC42'}
        />
    )
}

export default BCProgress