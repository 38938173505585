import { useTranslation } from "react-i18next";
import { BCSpace } from "@components/index";
import { logoWhite } from "@assets/index";
import "./assets/style.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footerContainer">
      <img src={logoWhite} />
      <BCSpace className="footerNavigationContainer">
        {/* <div>
          <BCButton
            type='text'
            size='small'
            text={t('000106')}
          />
          <BCButton
            type='text'
            size='small'
            text={t('000107')}
          />
          <BCButton
            type='text'
            size='small'
            text={t('000108')}
          />
        </div> */}
        {/* <BCButton
          type='text'
          size='small'
          text={`© 2020 Bimser. ${t('000109')}`}
        /> */}
        <span>{`© 2024 Bimser. ${t("000109")}`}</span>
      </BCSpace>
      <span></span>
    </div>
  );
};

export default Footer;
