import { Card } from 'antd';
import { IMetaProps } from './entities';
import './assets/style.scss'

const BCMeta = (props: IMetaProps) => {
    const { title, description, avatar, style, className } = props;
    const { Meta } = Card;

    return (
        <Meta
            avatar={avatar}
            title={title}
            description={description}
            style={style}
            className={className}
        />
    )
}

export default BCMeta