import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import {
  BCButton,
  BCCol,
  BCDateRangePicker,
  BCForm,
  BCFormItem,
  BCInputTextArea,
  BCModal,
  BCRow,
  BCSegmented,
  BCSelect,
} from "@components/index";
import { useCreateConsultancyRequestMutation } from "@services/index";
import { closeModal, openModal } from "@store/index";
import "./assets/style.scss";

const ConsultancyRequest = (props: any) => {
  const { parameters } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [canModalClose, setCanModalClose] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [sessionType, setSessionType] = useState<number | string>(2);
  const [participantNumber, setParticipantNumber] = useState<number>(1);
  const [datePreference, setDatePreference] = useState({
    DatePreferenceStart: null,
    DatePreferenceEnd: null,
  });
  const [date, setDate] = useState<number>(1);
  const [message, setMessage] = useState<string>("");
  const [sendDisabled, setSendDisabled] = useState<boolean>(true);

  useEffect(() => {
    // Check if both DatePreferenceStart and DatePreferenceEnd are null
    const isDate =
      datePreference.DatePreferenceStart === null &&
      datePreference.DatePreferenceEnd === null;
    const isMessage = message === "";

    setSendDisabled(isDate || isMessage);
  }, [datePreference, message]);

  const [createRequest, { isLoading, isSuccess, isError, error, data }] =
    useCreateConsultancyRequestMutation();

  const createConsultancyRequest = () => {
    try {
      const data = {
        Provider: parameters.providerprofile,
        Service: parameters.documentid,
        ServiceText: parameters.consultancyname,
        SessionType: sessionType,
        ParticipantNumber: participantNumber,
        DatePreferenceStart: datePreference["DatePreferenceStart"],
        DatePreferenceEnd: datePreference["DatePreferenceEnd"],
        Date: date,
        Message: message,
      };
      createRequest(data);
    } catch (error) {
      console.log("createConsultancyRequest", error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        openModal({
          modal: "Information",
          parameters: {
            title: t("000020"),
            message: t("000080"),
            description: t("000099"),
          },
        })
      );
      setCanModalClose(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading) setCanModalClose(false);
  }, [isLoading]);

  useEffect(() => {
    if (isError) setCanModalClose(true);
  }, [isError]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };

  const handleSessionTypeChange = (value: number | string) => {
    setSessionType(value);
  };

  const handleParticipantNumberChange = (value: number, option: any) => {
    setParticipantNumber(value);
  };

  const handleDatePreferenceChange = (startDate: any, endDate: any) => {
    setDatePreference((prev) => ({
      ...prev,
      DatePreferenceStart: startDate,
      DatePreferenceEnd: endDate,
    }));
  };

  const handleDateChange = (value: number, option: any) => {
    setDate(value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleCancel = () => {
    if (canModalClose) {
      setIsModalOpen(false);
      dispatch(closeModal());
    }
  };

  return (
    <BCModal
      open={isModalOpen}
      title={t("000020")}
      onCancel={handleCancel}
      centered
      width={800}
      footer={[
        <BCButton
          content={t("000092")}
          disabled={sendDisabled}
          onClick={createConsultancyRequest}
          loading={isLoading}
          style={{
            backgroundColor: sendDisabled ? "#F5F5F5" : "#00104E",
            color: sendDisabled ? "#B8B8B8" : "#fff",
          }}
        />,
        <BCButton
          content={t("000083")}
          onClick={handleCancel}
          style={{ backgroundColor: "#fff", color: "#00104E" }}
        />,
      ]}
      className="consultancyRequestContainer"
    >
      <BCForm>
        <BCRow justify={"space-between"} align={"bottom"}>
          <BCCol span={11}>
            <BCRow>
              <BCCol span={24}>
                <BCFormItem label={t("000084")} labelCol={{ span: 24 }}>
                  <BCSelect value={parameters.providerprofile} disabled />
                </BCFormItem>
              </BCCol>
            </BCRow>
            <BCRow justify={"space-between"}>
              <BCCol span={14}>
                <BCFormItem label={t("000096")} labelCol={{ span: 24 }}>
                  <BCSegmented
                    options={[
                      {
                        label: t("000067"),
                        value: 1,
                      },
                      {
                        label: t("000066"),
                        value: 2,
                      },
                    ]}
                    value={sessionType}
                    onChange={handleSessionTypeChange}
                    block
                  />
                </BCFormItem>
              </BCCol>
              <BCCol span={9}>
                <BCFormItem label={t("000100")} labelCol={{ span: 24 }}>
                  <BCSelect
                    options={[
                      { label: "1-25", value: 1 },
                      { label: "25-50", value: 2 },
                      { label: "50-75", value: 3 },
                    ]}
                    value={participantNumber}
                    onChange={handleParticipantNumberChange}
                  />
                </BCFormItem>
              </BCCol>
            </BCRow>
            <BCRow justify={"space-between"}>
              <BCCol span={14}>
                <BCFormItem label={t("000101")} labelCol={{ span: 24 }}>
                  <BCDateRangePicker
                    onChange={handleDatePreferenceChange}
                    disabledDate={disabledDate}
                  />
                </BCFormItem>
              </BCCol>
              <BCCol span={9}>
                <BCFormItem label=" " labelCol={{ span: 24 }}>
                  <BCSelect
                    options={[
                      { label: t("000094"), value: 1 },
                      { label: t("000095"), value: 2 },
                    ]}
                    value={date}
                    onChange={handleDateChange}
                  />
                </BCFormItem>
              </BCCol>
            </BCRow>
          </BCCol>
          <BCCol span={12}>
            <BCFormItem label={t("000097")} labelCol={{ span: 24 }}>
              <BCInputTextArea
                rows={7}
                placeholder={t("000098")}
                showCount
                maxLength={100}
                onChange={handleMessageChange}
              />
            </BCFormItem>
          </BCCol>
        </BCRow>
      </BCForm>
    </BCModal>
  );
};

export default ConsultancyRequest;
