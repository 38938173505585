import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import type { DatePickerProps } from 'antd';
// import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  BCButton,
  BCCol,
  BCForm,
  BCFormItem,
  BCInputNumber,
  BCModal,
  BCRow,
  BCSelect,
} from "@components/index";
import { closeModal, openModal, selectCurrentUser } from "@store/index";
import {
  useCreateCollectMutation,
  useCreateSubscriptionMutation,
} from "@services/index";
import "./assets/style.scss";
import { useTranslation } from "react-i18next";
import { useBlobUrl } from "@utils/index";

const BuyApp = (props: any) => {
  const { parameters } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer: any = useSelector(selectCurrentUser);

  const [canModalClose, setCanModalClose] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedInstance, setSelectedInstance] = useState<string>(
    customer?.tenantInfoDetails[0]?.tenantUID || ""
  );
  const [paymentPlanTime, setPaymentPlanTime] = useState<number>(1);
  // const [isChecked, setIsChecked] = useState({
  //     TrialVersion: false,
  //     BPMGenesis: false,
  //     CSPStore: false,
  //     AcceptAll: false
  // });

  const [
    createSubscription,
    {
      isLoading: createSubscriptionIsLoading,
      isSuccess: createSubscriptionIsSuccess,
      isError: createSubscriptionIsError,
      error: createSubscriptionError,
      data: createSubscriptionData,
    },
  ] = useCreateSubscriptionMutation();

  const [
    createCollect,
    {
      isLoading: createCollectIsLoading,
      isSuccess: createCollectIsSuccess,
      isError: createCollectIsError,
      error: createCollectError,
      data: createCollectData,
    },
  ] = useCreateCollectMutation();

  const createBuyAppRequest = () => {
    const data = {
      tenantvalue: selectedInstance,
      applicationvalue: parameters.appdocumentid,
      periodicamount: parameters.periodicamount,
      applicationfee: parameters.applicationfee,
      wagestatus: parameters.applicationfee ? 2 : 1,
      monthlyannualy: parameters.mounthlyannuallyvalue,
      moneytype: parameters.moneytypevalue,
    };
    createSubscription(data);
  };

  const getInstanceOptions = () => {
    return (
      customer?.tenantInfoDetails?.map((tenant: any) => ({
        label: `${tenant.firstName} ${tenant.lastName}`,
        value: tenant.tenantUID,
      })) || []
    );
  };

  const createCollectRequest = () => {
    try {
      const data = {
        Price: paymentPlanTime * parameters.applicationfee,
        TenantValue: selectedInstance,
        ApplicationValue: parameters.appdocumentid,
        PaymentPlanTime: paymentPlanTime,
        PaymentPlan: Number(parameters.mounthlyannuallyvalue),
        Currency: parameters.moneytypevalue == "1" ? "TL" : "USD",
      };
      createCollect(data);
    } catch (error) {
      console.log("createCollectRequest", error);
    }
  };

  useEffect(() => {
    if (createSubscriptionIsSuccess) {
      createCollectRequest();
      dispatch(
        openModal({
          modal: "Information",
          parameters: {
            title: t("000016"),
            message: t("000080"),
            description: t("000081"),
          },
        })
      );
      setCanModalClose(true);
    }
  }, [createSubscriptionIsSuccess]);

  useEffect(() => {
    if (createSubscriptionIsLoading) setCanModalClose(false);
  }, [createSubscriptionIsLoading]);

  useEffect(() => {
    if (createSubscriptionIsError) setCanModalClose(true);
  }, [createSubscriptionIsError]);

  const handleInstanceChange = (value: string, option: any) => {
    setSelectedInstance(value);
  };

  const handlePaymentPlanTimeChange = (value: string | number | null) => {
    setPaymentPlanTime(value as number);
  };

  // const handleChecked = (e: CheckboxChangeEvent) => {
  //     setIsChecked({
  //         ...isChecked, [e.target.name as string]: e.target.checked
  //     })
  // }

  const handleCancel = () => {
    if (canModalClose) {
      setIsModalOpen(false);
      dispatch(closeModal());
    }
  };

  return (
    <BCModal
      open={isModalOpen}
      title={t("000016")}
      onCancel={handleCancel}
      footer={[
        <BCButton
          content={t("000082")}
          onClick={createBuyAppRequest}
          loading={createSubscriptionIsLoading}
          style={{ backgroundColor: "#00104E", color: "#fff" }}
        />,
        <BCButton
          content={t("000083")}
          onClick={handleCancel}
          style={{ backgroundColor: "#fff", color: "#00104E" }}
        />,
      ]}
      width={400}
      className="buyAppContainer"
    >
      <BCRow justify={"space-between"}>
        <BCCol span={10} style={{ margin: "20px 0" }}>
          <div>
            <img width={100} src={useBlobUrl(parameters?.logo)} />
          </div>
        </BCCol>
        <BCCol span={14} style={{ marginTop: "20px 0" }}>
          <div style={{ fontSize: "16px", fontWeight: 600 }}>
            {parameters.appname}
          </div>
          <div style={{ fontSize: "14px", color: "#c9c9c9" }}>
            {t("000084")}
          </div>
          <div>{parameters.partnersname}</div>
        </BCCol>
      </BCRow>
      <BCForm>
        <BCRow>
          <BCCol span={24}>
            <BCFormItem label={t("000085")} labelCol={{ span: 24 }}>
              <BCSelect
                options={getInstanceOptions()}
                defaultValue={getInstanceOptions()?.[0]?.label}
                onChange={handleInstanceChange}
              />
            </BCFormItem>
          </BCCol>
        </BCRow>
        {/* <BCRow>
                    <BCCol>
                        <BCCheckbox name='TrialVersion' label='Deneme sürümü kullanma koşulları' checked={isChecked.TrialVersion} onChange={handleChecked} />
                    </BCCol>
                    <BCCol>
                        <BCCheckbox name='BPMGenesis' label='BPM Genesis hizmet koşulları ve gizlilik politikası' checked={isChecked.BPMGenesis} onChange={handleChecked} />
                    </BCCol>
                    <BCCol>
                        <BCCheckbox name='CSPStore' label='CSP Store hizmet koşulları ve gizlilik politikası' checked={isChecked.CSPStore} onChange={handleChecked} />
                    </BCCol>
                    <BCCol>
                        <BCCheckbox name='AcceptAll' label='Tümünü kabul ediyorum' checked={isChecked.AcceptAll} onChange={handleChecked} />
                    </BCCol>
                </BCRow> */}
        <BCRow>
          <BCCol span={12}>
            <BCFormItem label={t("000086")} labelCol={{ span: 24 }}>
              <BCInputNumber
                value={paymentPlanTime}
                onChange={handlePaymentPlanTimeChange}
                min={1}
              />
              {` /${parameters.mounthlyannually}`}
            </BCFormItem>
          </BCCol>
          <BCCol span={12}>
            <BCFormItem label=" " labelCol={{ span: 24 }}>
              <span style={{ fontWeight: "700" }}>{t("000087")}:</span>{" "}
              <span style={{ fontWeight: "600" }}>{`${(
                paymentPlanTime * parameters.applicationfee
              ).toLocaleString(
                parameters.moneytypevalue == "1" ? "tr-TR" : "en-US",
                {
                  style: "currency",
                  currency: parameters.moneytypevalue == "1" ? "TRY" : "USD",
                }
              )}`}</span>
            </BCFormItem>
          </BCCol>
        </BCRow>
      </BCForm>
    </BCModal>
  );
};

export default BuyApp;
