import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FsLightbox from "fslightbox-react";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegCirclePlay } from "react-icons/fa6";
import { BCRow, BCCol } from "@components/index";
import { IGeneralInformationTabProps } from "./entities";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const GeneralInformationTab = ({ partner }: IGeneralInformationTabProps) => {
  const { t } = useTranslation();

  const [urls, setUrls] = useState<any>([]);
  const [nodes, setNodes] = useState<any>([]);

  useEffect(() => {
    // const videos = consultancy?.video?.split(',')
    const images = partner?.Gallery?.split(",");

    // const videosUrl = videos?.map((video: any) => `https://saas8402cs.blob.core.windows.net/portal-store-storage/${video}`) || []
    const imagesUrl = images?.map((image: any) => useBlobUrl(image)) || [];

    // const videosNode = videos?.map((video: any) => (
    //     <video width="100%" height="100%" controls style={{ objectFit: "fill" }}>
    //         <source src={`https://saas8402cs.blob.core.windows.net/portal-store-storage/${video}`} type="video/mp4" />
    //     </video>
    // )) || []
    const imagesNode =
      images?.map((image: any) => (
        <div className="swiperSlideItem">
          <div className="swiperContentPreview">
            <span>
              <AiOutlineEye size="20px" /> Preview
            </span>
          </div>
          <img className="swiperContentImage" src={useBlobUrl(image)} alt="" />
        </div>
      )) || [];

    setUrls([...imagesUrl]);
    setNodes([...imagesNode]);
  }, [partner.DOCUMENTID]);

  const getDetailInformations = () => {
    return partner.DETAIL ? <>{parse(partner.DETAIL)}</> : <></>;
  };

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const getTypes = () => {
    const types = urls?.map((url: string) => {
      if (url.endsWith(".mp4")) {
        return "video";
      }
      if (
        url.endsWith(".png") ||
        url.endsWith(".jpg") ||
        url.endsWith(".jpeg")
      ) {
        return "image";
      }
    });

    return types;
  };

  return (
    <div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={urls}
        slide={lightboxController.slide}
        exitFullscreenOnClose={true}
        loadOnlyCurrentSource={true}
        maxYoutubeVideoDimensions={{ width: 1920, height: 1080 }}
        types={getTypes()}
      />
      {nodes.length ? (
        <Swiper
          className="swiperPartnerDetailCarouselContainer"
          modules={[Navigation, Pagination, A11y, Autoplay]}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          autoplay={{
            delay: 90000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          breakpoints={{
            650: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            800: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            960: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1300: {
              slidesPerView: 3.2,
              spaceBetween: 10,
            },
          }}
        >
          {nodes?.map((source: any, index: any) => {
            return (
              <SwiperSlide key={index}>
                <div
                  onClick={() => openLightboxOnSlide(index + 1)}
                  className="swiperSlideItemContainer"
                >
                  {source}
                </div>
              </SwiperSlide>
            );
          })}
          <div className="navigationContainer">
            <span className="swiper-button-prev" />
            <span className="swiper-button-next" />
          </div>
        </Swiper>
      ) : null}
      <div style={{ minHeight: "70vh", paddingRight: "10px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px 0",
          }}
        >
          {getDetailInformations()}
        </div>
        <div>
          <BCRow wrap>
            <BCCol
              xs={23}
              sm={23}
              md={11}
              lg={11}
              xl={7}
              xxl={7}
              style={{ marginRight: "30px" }}
            >
              <h4
                style={{ borderBottom: "1px solid #B8B8B8", padding: "10px 0" }}
              >
                {t("000045")}
              </h4>
              <ul style={{ marginLeft: "7%" }}>
                <li>{partner?.ESTABLISHMENT}</li>
              </ul>
            </BCCol>
            {Number(partner?.EMPLOYEES) > 0 ? (
              <BCCol xs={23} sm={23} md={11} lg={11} xl={7} xxl={7}>
                <h4
                  style={{
                    borderBottom: "1px solid #B8B8B8",
                    padding: "10px 0",
                  }}
                >
                  {t("000046")}
                </h4>
                <ul style={{ marginLeft: "7%" }}>
                  <li>{partner.EMPLOYEES}+</li>
                </ul>
              </BCCol>
            ) : null}
          </BCRow>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformationTab;
