import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BCButton,
  BCCol,
  BCForm,
  BCFormItem,
  BCInput,
  BCModal,
  BCRow,
} from "@components/index";
import { openModal } from "@store/index";
import { useSendResetPasswordMailMutation } from "@services/index";
import "./assets/style.scss";

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [canModalClose, setCanModalClose] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isEmail = email == "";

    setSendButtonDisabled(isEmail);
  }, [email]);

  const [
    sendResetPasswordMail,
    {
      isLoading: sendResetIsLoading,
      isSuccess: sendResetIsSuccess,
      isError: sendResetIsError,
      error: sendResetError,
      data: sendResetData,
    },
  ] = useSendResetPasswordMailMutation();

  const handleClickSendResetPasswordMail = () => {
    const data = { emailAddress: email };
    sendResetPasswordMail(data);
  };

  useEffect(() => {
    if (sendResetIsSuccess) {
      setCanModalClose(true);
    }
  }, [sendResetIsSuccess]);

  useEffect(() => {
    if (sendResetIsLoading) setCanModalClose(false);
  }, [sendResetIsLoading]);

  useEffect(() => {
    if (sendResetIsError) setCanModalClose(true);
  }, [sendResetIsError]);

  const handleCancel = () => {
    if (canModalClose) {
      setIsModalOpen(false);
      dispatch(openModal({ modal: "Login" }));
    }
  };

  return (
    <BCModal
      open={isModalOpen}
      title={t("000105")}
      onCancel={handleCancel}
      footer={[
        <BCButton
          content={t("000092")}
          onClick={handleClickSendResetPasswordMail}
          disabled={sendButtonDisabled}
          loading={sendResetIsLoading}
          style={{
            backgroundColor: sendButtonDisabled ? "#F5F5F5" : "#00104E",
            color: sendButtonDisabled ? "#B8B8B8" : "#fff",
          }}
        />,
        <BCButton
          content={t("000083")}
          onClick={handleCancel}
          style={{ backgroundColor: "#fff", color: "#00104E" }}
        />,
      ]}
      width={400}
      className="resetPasswordContainer"
    >
      <BCRow justify={"center"}>
        <BCCol span={21}>{t("000111")}</BCCol>
      </BCRow>
      <BCForm>
        <BCRow justify={"center"}>
          <BCCol span={22}>
            <BCFormItem label={t("000110")} labelCol={{ span: 24 }} required>
              <BCInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </BCFormItem>
          </BCCol>
        </BCRow>
      </BCForm>
    </BCModal>
  );
};

export default ResetPassword;
