import { useEffect, useLayoutEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Avatar, Tag } from "antd";
import {
  StarOutlined,
  CommentOutlined,
  UserAddOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  BCButton,
  BCCol,
  BCLayoutContent,
  BCLayoutSider,
  BCCardSameApp,
  BCRow,
  BCTabs,
  BCLayout,
  BCCardMeta,
} from "@components/index";
import { openModal, selectCurrentUser } from "@store/index";
import { GetDocumentWithIcon, useBlobUrl } from "@utils/index";
import {
  selectAllCategories,
  selectAllSectors,
  useCreateFavAppsMutation,
  useDeleteFavAppsMutation,
  useLazyGetAppDetailQuery,
  useLazyGetAppsQuery,
  useLazyGetEvaluationBySystemNameQuery,
} from "@services/index";
import DetailedInformationTab from "./tabs/detailedInformationTab";
import ReviewsTab from "./tabs/reviewsTab";
import "./assets/style.scss";

const ApplicationDetail = () => {
  const dispatch = useDispatch();
  const { systemname } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const allCategories = useSelector(selectAllCategories);
  const allSectors = useSelector(selectAllSectors);
  const user = useSelector(selectCurrentUser);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [
    getAppDetail,
    {
      isLoading: getDetailisLoading,
      isSuccess: getDetailisSuccess,
      isError: getDetailisError,
      error: getDetailerror,
      data: app,
    },
  ] = useLazyGetAppDetailQuery();

  useEffect(() => {
    if (systemname) {
      const data = {
        appSystemName: systemname,
        ispreview: pathname.split("/").includes("preview"),
      };
      getAppDetail(data);
      getEvaluationBySystemName(systemname);
    }
  }, [systemname]);

  const [
    getEvaluationBySystemName,
    {
      data: evaluations,
      isLoading: getEvaluationisLoading,
      isSuccess: getEvaluationisSuccess,
      isError: getEvaluationisError,
      error: getEvaluationerror,
    },
  ] = useLazyGetEvaluationBySystemNameQuery();

  const [
    getApps,
    {
      isLoading: getAppsisLoading,
      isSuccess: getAppsisSuccess,
      isError: getAppsisError,
      error: getAppserror,
      data: filteredApps,
    },
  ] = useLazyGetAppsQuery();

  useEffect(() => {
    if (getDetailisSuccess && app && allCategories.length) {
      const appCategories = app.category?.split(",");
      const matchingCategories =
        allCategories
          ?.filter((category: any) =>
            appCategories.includes(category.DEFINITION)
          )
          ?.map((category: any) => category.DOCUMENTID) || [];

      if (matchingCategories.length) {
        const requestData = {
          APPNAME: "",
          APPTYPE: [],
          SECTOR: [],
          CATEGORY: matchingCategories,
          MANUFACTURER: "",
          ISPAID: "",
          MINFEE: "",
          MAXFEE: "",
          ISDEMOAPP: 0,
          MULTILANGUAGES: "",
          POINT: 0,
        };

        getApps(requestData);
      }
    }
  }, [getDetailisSuccess, app, allCategories]);

  const [
    addToFavorites,
    {
      isLoading: addToFavoritesisLoading,
      isSuccess: addToFavoritesisSuccess,
      isError: addToFavoritesisError,
      error: addToFavoriteserror,
      data: favoriteApp,
    },
  ] = useCreateFavAppsMutation();

  const [
    removeFromFavorites,
    {
      isLoading: removeFromFavoritesisLoading,
      isSuccess: removeFromFavoritesisSuccess,
      isError: removeFromFavoritesisError,
      error: removeFromFavoriteserror,
      data: removedFavoriteApp,
    },
  ] = useDeleteFavAppsMutation();

  const handleClickAddToFavorites = () => {
    try {
      if (user) addToFavorites({ APPS: app.appdocumentid });
      else dispatch(openModal({ modal: "Login" }));
    } catch (error) {
      console.log("addToFavoritesError", error);
    }
  };

  const handleClickRemoveFromFavorites = () => {
    try {
      if (user) removeFromFavorites({ DOCUMENTID: app.appdocumentid });
      else dispatch(openModal({ modal: "Login" }));
    } catch (error) {
      console.log("removeFromFavoritesError", error);
    }
  };

  const tabs = [
    {
      key: "1",
      label: t("000030"),
      children: <DetailedInformationTab app={app} />,
    },
    {
      key: "2",
      label: `${t("000031")} (${evaluations?.length})`,
      children: <ReviewsTab evaluations={evaluations} />,
    },
  ];

  let content = null;

  if (getDetailisSuccess && app) {
    const auxiliarydocuments = app.auxiliarydocuments
      ?.split(",")
      ?.map((document: any) => {
        const documentUrl = document.split("|")[0];
        const documentName = document.split("|")[1];

        return { documentUrl, documentName };
      });

    const appCategories = app.category?.split(",");
    const appSectors = app.sector?.split(",");
    const appLabels = app.labels?.split(",");

    const matchingCategories = allCategories.filter((category: any) =>
      appCategories.includes(category.DEFINITION)
    );
    const matchingSectors = allSectors.filter((sector: any) =>
      appSectors?.includes(sector.SECTORDEFINITION)
    );

    const getFavoriteButton = () => {
      if (app?.favorite)
        return (
          <BCButton
            onClick={handleClickRemoveFromFavorites}
            loading={removeFromFavoritesisLoading}
            disabled={removeFromFavoritesisLoading}
            block
            content={t("000116")}
          />
        );
      if (!app?.favorite)
        return (
          <BCButton
            onClick={handleClickAddToFavorites}
            loading={addToFavoritesisLoading}
            disabled={addToFavoritesisLoading}
            block
            content={t("000019")}
          />
        );
    };

    const favoriteButton = getFavoriteButton();

    const createdAt = app?.createdate
      ? new Intl.DateTimeFormat("tr", {
          dateStyle: "medium",
        }).format(new Date(app.createdate))
      : null;

    content = (
      <div className="applicationDetailContainer">
        <div className="detailHeader">
          <img className="banner" src={useBlobUrl(app.showcase)} />
          <Avatar
            size={100}
            shape="square"
            icon={<img src={useBlobUrl(app.icon)} />}
          />
        </div>
        <div className="contentTop">
          <BCLayout>
            <BCLayoutContent>
              <div>
                <span className="appName">{app.appname}</span>
                {app.compatibleversion ? `(${app.compatibleversion})` : null}
              </div>
              <div className="partnersName">{app.partnersname}</div>
              <p className="summaryInformation">{app.summaryinformation}</p>
              {app.average || app.comment ? (
                <div className="applicationReview">
                  {app.average ? (
                    <span>
                      <StarOutlined />
                      {app.average}
                    </span>
                  ) : null}
                  {app.comment ? (
                    <span>
                      <CommentOutlined />
                      {app.comment}
                    </span>
                  ) : null}
                </div>
              ) : null}
              <div className="generalInformations">
                <BCRow>
                  <BCCol className="informationLabel">{t("000024")}</BCCol>
                  <BCCol>
                    {matchingCategories?.map((category: any) => {
                      return (
                        <Tag
                          className="informationTag"
                          color={category?.DEFINITIONCOLOR}
                        >
                          {category.DEFINITION}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000025")}</BCCol>
                  <BCCol>
                    {matchingSectors?.map((sector: any) => {
                      return (
                        <Tag
                          className="informationTag"
                          color={sector?.DEFINITIONCOLOR}
                        >
                          {sector.SECTORDEFINITION}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000026")}</BCCol>
                  <BCCol>
                    {appLabels?.map((label: any) => {
                      return (
                        <Tag className="informationTag" color="#ced2dd">
                          {label}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000027")}</BCCol>
                  <BCCol>
                    {auxiliarydocuments?.map(
                      (document: {
                        documentUrl: string;
                        documentName: string;
                      }) => {
                        return (
                          <span className="informationTag documentTag">
                            <a
                              href={useBlobUrl(document.documentUrl)}
                              target="_blank"
                            >
                              {GetDocumentWithIcon(document)}
                            </a>
                          </span>
                        );
                      }
                    )}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000028")}</BCCol>
                  <BCCol>{createdAt}</BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000029")}</BCCol>
                  <BCCol>{app.compatibleversion}</BCCol>
                </BCRow>
              </div>
            </BCLayoutContent>
            <BCLayoutSider width={230}>
              <div className="buttonsContainer">
                {app.applicationfee ? (
                  <span className="price">{`${app.applicationfee.toLocaleString(
                    app.moneytypevalue == "1" ? "tr-TR" : "en-US",
                    {
                      style: "currency",
                      currency: app.moneytypevalue == "1" ? "TRY" : "USD",
                    }
                  )} /${
                    app.mounthlyannually == "Aylık" ? t("000022") : t("000023")
                  }`}</span>
                ) : null}
                <div className="modalButtonsContainer">
                  <BCButton
                    onClick={() =>
                      dispatch(openModal({ modal: "BuyApp", parameters: app }))
                    }
                    block
                    content={t("000016")}
                  />
                  {app.day ? (
                    <BCButton
                      onClick={() =>
                        dispatch(
                          openModal({ modal: "FreeTrial", parameters: app })
                        )
                      }
                      block
                      content={t("000017")}
                    />
                  ) : null}
                  <BCButton
                    onClick={() =>
                      dispatch(
                        openModal({
                          modal: "PresentationRequest",
                          parameters: app,
                        })
                      )
                    }
                    block
                    content={t("000018")}
                  />
                </div>
                {favoriteButton}
              </div>
            </BCLayoutSider>
          </BCLayout>
        </div>
        <div className="contentBottom">
          <BCLayout>
            <BCLayoutContent>
              <BCTabs items={tabs} />
            </BCLayoutContent>
            <BCLayoutSider width={270}>
              <div className="siderContainer">
                <div className="partnerInformations">
                  <h3>{t("000032")}</h3>
                  <BCCardMeta
                    title={t("000033")}
                    description={app.partnersname}
                    avatar={<UserAddOutlined />}
                  />
                  <BCCardMeta
                    title={t("000034")}
                    description={
                      <BCButton
                        content={app.partnerswebsite}
                        type="link"
                        target="_blank"
                        href={app.partnerswebsite}
                      />
                    }
                    avatar={<GlobalOutlined />}
                  />
                  <BCCardMeta
                    title={t("000035")}
                    description={
                      <BCButton
                        content={app.partnersemail}
                        type="link"
                        href={`mailto:${app.partnersemail}`}
                      />
                    }
                    avatar={<MailOutlined />}
                  />
                  <BCCardMeta
                    title={t("000036")}
                    description={
                      <BCButton
                        content={app.partnersphone}
                        type="link"
                        href={`tel:${app.partnersphone}`}
                      />
                    }
                    avatar={<PhoneOutlined />}
                  />
                </div>
                <div className="similarAppsContainer">
                  <h3>{t("000037")}</h3>
                  <div className="similarApps">
                    {getAppsisSuccess && filteredApps.length
                      ? filteredApps.slice(0, 4)?.map((sameApp: any) => {
                          return (
                            <Link
                              to={`/applications/${sameApp.systemname}/detail`}
                            >
                              <BCCardSameApp
                                bordered
                                hoverable
                                avatar={useBlobUrl(sameApp.icon)}
                                title={sameApp.appname}
                                description={sameApp.producer}
                                average={sameApp.average}
                              />
                            </Link>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </BCLayoutSider>
          </BCLayout>
        </div>
      </div>
    );
  }
  return content;
};

export default ApplicationDetail;
