import { Routes, Route } from "react-router-dom";
import {
  MainLayout,
  RequireAuthLayout,
  NotFound,
  DashLayout,
} from "@layouts/index";
import {
  Home,
  Applications,
  Partners,
  Consultancies,
  ApplicationDetail,
  PartnerDetail,
  ConsultancyDetail,
  Profile,
  Security,
  Favorites,
  OrderHistory,
} from "@pages/index";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />

        <Route path="applications">
          <Route index element={<Applications />} />
          <Route path="filter/:id" element={<Applications />} />
          <Route path=":systemname/detail" element={<ApplicationDetail />} />
          <Route path=":systemname/preview" element={<ApplicationDetail />} />
        </Route>

        <Route path="consultancies">
          <Route index element={<Consultancies />} />
          <Route path="filter/:id" element={<Consultancies />} />
          <Route path=":systemname/detail" element={<ConsultancyDetail />} />
          <Route path=":systemname/preview" element={<ConsultancyDetail />} />
        </Route>

        <Route path="partners">
          <Route index element={<Partners />} />
          <Route path="filter/:id" element={<Partners />} />
          <Route path=":shortCode/detail" element={<PartnerDetail />} />
          <Route path=":shortCode/preview" element={<PartnerDetail />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<RequireAuthLayout />}>
          <Route path="dash" element={<DashLayout />}>
            <Route index element={<Profile />} />
            <Route path="security" element={<Security />} />
            <Route path="favorites">
              <Route path="apps" element={<Favorites />} />
              <Route path="consultancies" element={<Favorites />} />
              <Route path="partners" element={<Favorites />} />
            </Route>
            <Route path="orderHistory" element={<OrderHistory />} />
          </Route>
        </Route>
        {/* End Protected Routes */}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
