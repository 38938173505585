import { Tree, ConfigProvider } from "antd";
import { ITreeProps } from "./entities";
import "./assets/style.scss";

const BCTree = (props: ITreeProps) => {
  const { treeData, onCheck, checkedKeys, style, onExpand } = props;

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 0,
        },
      }}
    >
      <Tree
        onExpand={onExpand}
        selectable={false}
        checkable={true}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        defaultCheckedKeys={[]}
        defaultExpandAll={true}
        treeData={treeData}
        blockNode
        style={style}
      />
    </ConfigProvider>
  );
};

export default BCTree;
