import { BCNotification } from "@components/index";
import { ErrorCodes } from "@services/index";

function customErrorHandler(e: any) {
  if (e?.payload?.originalStatus === 404) {
    BCNotification({
      type: "error",
      message: "Hata! Sunucu tarafında bir hata oluştu.",
    });
  } else {
    let errorCode: string = getTaleException(e?.payload?.data?.exception);
    let message =
      ErrorCodes[errorCode] || "Hata! Sunucu tarafında bir hata oluştu.";
    BCNotification({ type: "error", message });
  }
}

function getTaleException(e: any): string {
  if (e?.InnerException) {
    return getTaleException(e?.InnerException);
  } else if (e?.ExternalException) {
    return getTaleException(e?.ExternalException);
  }
  return e["Code"];
}

export { customErrorHandler };
