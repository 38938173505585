import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import { closeModal, openModal, selectCurrentUser } from "@store/index";
import {
  BCButton,
  BCCol,
  BCDatePicker,
  BCForm,
  BCFormItem,
  BCInputTextArea,
  BCModal,
  BCRow,
  BCSegmented,
  BCSelect,
} from "@components/index";
import { useCreateAppIntroductionMutation } from "@services/index";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

interface Tenant {
  firstName: string;
  lastName: string;
  tenantUID: string;
}

const presentationRequest = (props: any) => {
  const { parameters } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer: any = useSelector(selectCurrentUser);

  const [canModalClose, setCanModalClose] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [selectedInstance, setSelectedInstance] = useState<string>(
    customer?.tenantInfoDetails[0]?.tenantUID || ""
  );
  const [sessionType, setSessionType] = useState<number>(2);
  const [datePreference, setDatePreference] = useState<string>("");
  const [date, setDate] = useState<number>(1);
  const [message, setMessage] = useState<string>("");
  const [sendDisabled, setSendDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isInstance = selectedInstance == "";
    const isDate = datePreference == "";
    const isMessage = message == "";

    setSendDisabled(isInstance || isDate || isMessage);
  }, [selectedInstance, datePreference, message]);

  const [
    createAppIntroduction,
    {
      isLoading: createAppIntroductionIsLoading,
      isSuccess: createAppIntroductionIsSuccess,
      isError: createAppIntroductionIsError,
      error: createAppIntroductionError,
      data: createAppIntroductionData,
    },
  ] = useCreateAppIntroductionMutation();

  const createAppIntroductionRequest = () => {
    try {
      const createDate = dayjs().format();

      const data = {
        Customer: selectedInstance,
        Application: parameters.appdocumentid,
        CreateDate: createDate,
        RequestDate: datePreference,
        Session: sessionType,
        Date: date,
        Explanation: message,
      };
      createAppIntroduction(data);
    } catch (error) {
      console.log("createAppIntroductionRequest", error);
    }
  };

  const getInstanceOptions = (): { label: string; value: string }[] => {
    return (customer?.tenantInfoDetails || []).map(
      ({ firstName, lastName, tenantUID }: Tenant) => ({
        label: `${firstName} ${lastName}`,
        value: tenantUID,
      })
    );
  };

  useEffect(() => {
    if (createAppIntroductionIsSuccess) {
      dispatch(
        openModal({
          modal: "Information",
          parameters: {
            title: t("000018"),
            message: t("000080"),
            description: t("000091"),
          },
        })
      );
      setCanModalClose(true);
    }
  }, [createAppIntroductionIsSuccess]);

  useEffect(() => {
    if (createAppIntroductionIsLoading) setCanModalClose(false);
  }, [createAppIntroductionIsLoading]);

  useEffect(() => {
    if (createAppIntroductionIsError) setCanModalClose(true);
  }, [createAppIntroductionIsError]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf("day");
  };

  const handleInstanceChange = (value: string, option: any) => {
    setSelectedInstance(value);
  };

  const handleSessionTypeChange = (value: number | string) => {
    if (typeof value === "number") setSessionType(value);
  };

  const handleDatePreferenceChange = (date: any) => {
    setDatePreference(date);
  };

  const handleDateChange = (value: number, option: any) => {
    setDate(value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleCancel = () => {
    if (canModalClose) {
      setIsModalOpen(false);
      dispatch(closeModal());
    }
  };

  return (
    <BCModal
      open={isModalOpen}
      title={t("000018")}
      onCancel={handleCancel}
      centered
      width={800}
      footer={[
        <BCButton
          content={t("000092")}
          disabled={sendDisabled}
          onClick={createAppIntroductionRequest}
          loading={createAppIntroductionIsLoading}
          style={{
            backgroundColor: sendDisabled ? "#F5F5F5" : "#00104E",
            color: sendDisabled ? "#B8B8B8" : "#fff",
          }}
        />,
        <BCButton
          content={t("000083")}
          onClick={handleCancel}
          style={{ backgroundColor: "#fff", color: "#00104E" }}
        />,
      ]}
      className="presentationRequestContainer"
    >
      <BCForm>
        <BCRow justify={"space-between"} align={"bottom"}>
          <BCCol span={11}>
            <BCRow justify={"start"}>
              <BCCol span={10} style={{ margin: "20px 0" }}>
                <div>
                  <img width={100} src={useBlobUrl(parameters.logo)} />
                </div>
              </BCCol>
              <BCCol span={14} style={{ margin: "20px 0" }}>
                <div style={{ fontSize: "16px", fontWeight: 600 }}>
                  {parameters.appname}
                </div>
                <div style={{ fontSize: "14px", color: "#c9c9c9" }}>
                  {t("000084")}
                </div>
                <div>{parameters.partnersname}</div>
              </BCCol>
            </BCRow>
            <BCRow>
              <BCCol span={24}>
                <BCFormItem label={t("000085")} labelCol={{ span: 24 }}>
                  <BCSelect
                    options={getInstanceOptions()}
                    onChange={handleInstanceChange}
                    defaultValue={getInstanceOptions()?.[0]?.label}
                  />
                </BCFormItem>
              </BCCol>
            </BCRow>
            <BCRow justify={"space-between"}>
              <BCCol span={14}>
                <BCFormItem label={t("000093")} labelCol={{ span: 24 }}>
                  <BCDatePicker
                    onChange={handleDatePreferenceChange}
                    disabledDate={disabledDate}
                  />
                </BCFormItem>
              </BCCol>
              <BCCol span={9}>
                <BCFormItem label=" " labelCol={{ span: 24 }}>
                  <BCSelect
                    options={[
                      { label: t("000094"), value: 1 },
                      { label: t("000095"), value: 2 },
                    ]}
                    value={date}
                    onChange={handleDateChange}
                  />
                </BCFormItem>
              </BCCol>
            </BCRow>
            <BCRow justify={"space-between"}>
              <BCCol span={14}>
                <BCFormItem label={t("000096")} labelCol={{ span: 24 }}>
                  <BCSegmented
                    options={[
                      {
                        label: t("000067"),
                        value: 1,
                      },
                      {
                        label: t("000066"),
                        value: 2,
                      },
                    ]}
                    value={sessionType}
                    onChange={handleSessionTypeChange}
                    block
                  />
                </BCFormItem>
              </BCCol>
            </BCRow>
          </BCCol>
          <BCCol span={12}>
            <BCFormItem label={t("000097")} labelCol={{ span: 24 }}>
              <BCInputTextArea
                rows={10}
                placeholder={t("000098")}
                showCount
                maxLength={100}
                onChange={handleMessageChange}
              />
            </BCFormItem>
          </BCCol>
        </BCRow>
      </BCForm>
    </BCModal>
  );
};

export default presentationRequest;
