import { useSelector } from "react-redux";
import { selectCurrentUser } from "@store/index";

export const useCustomer = () => {
  const userFromRedux: any = useSelector(selectCurrentUser);
  const userFromLocalStorage = localStorage.getItem("customer");

  if (userFromRedux) {
    return userFromRedux;
  } else {
    if (userFromLocalStorage) {
      const customer = JSON.parse(userFromLocalStorage);
      return customer;
    } else {
      return null;
    }
  }
};
