const useBlobUrl = (name: string): string => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  switch (subdomain) {
    case "store":
      return `https://saas8402cs.blob.core.windows.net/portal-store-storage/${name}`;
    case "prestore":
      return `https://saas8402cs.blob.core.windows.net/preportal-store-storage/${name}`;
    default:
      return `https://saas8402cs.blob.core.windows.net/preportal-store-storage/${name}`;
  }
};

export { useBlobUrl };
