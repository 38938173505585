import { Link, useLocation } from 'react-router-dom';
import { FaMessage, FaStar } from "react-icons/fa6";
import { BCCard, BCCol, BCRow, BCSpace } from '..'
import { ICarouselCardConsultancyProps } from './entities'
import './assets/style.scss'


const BCCarouselCardConsultancy = (props: ICarouselCardConsultancyProps) => {
    const { cover, loading, actions, summaryinformation, providerprofile, average, comment, isFree, consultancyname, systemname } = props;

    const { pathname } = useLocation()
    const customHeadStyle: React.CSSProperties = { backgroundColor: "#d23135", color: "#fff", position: "absolute", left: "-1px", top: "-1px", borderRadius: "8px 0 8px 0" }
    const customBodyStyle: React.CSSProperties = { display: "flex", flexDirection: "column", gap: "10px", paddingBottom: 0 }
    const navigationUrl = pathname == "/consultancies" ? `${systemname}/detail` : `consultancies/${systemname}/detail`

    return (
        <Link to={navigationUrl} className='carouselCardConsultancyContainer'>
            <BCCard
                cover={cover}
                actions={actions}
                hoverable
                size='small'
                title={isFree ? 'Ücretsiz' : ""}
                headStyle={customHeadStyle}
                bodyStyle={customBodyStyle}
            >
                <BCRow justify={'space-between'}>
                    <BCCol className='carouselCardConsultancyName'>{consultancyname}</BCCol>
                    <BCCol></BCCol>
                </BCRow>
                <BCRow className='carouselCardConsultancySummaryInformation'>
                    {summaryinformation}
                </BCRow>
                <BCRow className='cardBottom' justify={'space-between'}>
                    <BCCol>
                        {providerprofile}
                    </BCCol>
                    <BCSpace size={'large'}>
                        {/* <BCCol className='reviewContainer'>
                            <span className='average'><FaStar color="#9D9D9D" size="16px" /></span>
                            <span>{average ? average : 5}</span>
                        </BCCol>
                        <BCCol className='reviewContainer'>
                            <span className='comment'><FaMessage color="#9D9D9D" size="16px" /></span>
                            <span>{comment ? comment : 0}</span>
                        </BCCol> */}
                    </BCSpace>
                </BCRow>
            </BCCard>
        </Link>
    )
}

export default BCCarouselCardConsultancy