import { useState } from "react";
import BCButton from "@components/BCButton";
import BCTree from "@components/BCTree";
import { BsCaretRightFill } from "react-icons/bs";
import { selectAllCategories, selectAllSectors } from "@services/index";
import {
  consultancyFilter,
  selectCurrentConsultancyFilters,
} from "@store/index";
import { useDispatch, useSelector } from "react-redux";
import { TreeProps } from "antd";
import { useTranslation } from "react-i18next";
import { BCInputNumber } from "@components/index";
import { useNavigate } from "react-router-dom";

const FilterSide = (props: any) => {
  const {
    disabledInputNumber,
    setDisabledInputNumber,
    setMinFee,
    setMaxFee,
    setFeeFilter,
    feeFilter,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const allCategories = useSelector(selectAllCategories);
  const allSectors = useSelector(selectAllSectors);
  const currentFilters = useSelector(selectCurrentConsultancyFilters);

  const [currentMinFee, setCurrentMinFee] = useState<number>(0);
  const [currentMaxFee, setCurrentMaxFee] = useState<number>(50000);
  const [disabledFeeButton, setDisabledFeeButton] = useState<boolean>(false);

  const handleMinFeeChange = (value: string | number | null) => {
    setDisabledFeeButton(true);
    setCurrentMinFee(value as number);
  };
  const handleMaxFeeChange = (value: string | number | null) => {
    setDisabledFeeButton(true);
    setCurrentMaxFee(value as number);
  };
  const handleFeeClick = () => {
    setMinFee(currentMinFee);
    setMaxFee(currentMaxFee);
    setFeeFilter(!feeFilter);
    setDisabledFeeButton(false);
  };

  const resetFilter = () => {
    dispatch(consultancyFilter({ filters: [] }));
    setDisabledInputNumber(true);
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeys: any, info: any) => {
    if (checkedKeys && location.pathname != "/consultancies/filter/all")
      navigate("/consultancies/filter/all");

    setDisabledInputNumber(!checkedKeys.includes("41"));

    const filteredCheckedNodes = info.checkedNodesPositions
      ?.filter((item: any) => {
        if (!item.node.children || item.node.children?.length < 2) {
          item.node.position = item.pos;
          return true;
        }
        return false;
      })
      .map((item: any) => item.node);

    dispatch(consultancyFilter({ filters: filteredCheckedNodes }));
  };

  const treeData = [
    {
      title: <b>{t("000054")}</b>,
      checkable: false,
      key: "0",
      children: [
        { title: t("000007"), key: "01", value: 1 },
        { title: t("000064"), key: "02", value: 2 },
        { title: t("000009"), key: "03", value: 3 },
      ],
    },
    {
      title: <b>{t("000025")}</b>,
      checkable: false,
      key: "1",
      children: allSectors?.map((sector: any) => {
        return {
          title: sector.SECTORDEFINITION,
          key: sector.DOCUMENTID,
          value: sector.DOCUMENTID,
        };
      }),
    },
    {
      title: <b>{t("000024")}</b>,
      checkable: false,
      key: "2",
      children: allCategories?.map((category: any) => {
        return {
          title: category.DEFINITION,
          key: category.DOCUMENTID,
          value: category.DOCUMENTID,
        };
      }),
    },
    {
      title: <b>{t("000065")}</b>,
      checkable: false,
      key: "3",
      children: [
        { title: t("000066"), key: "31", value: 0 },
        { title: t("000067"), key: "32", value: 1 },
      ],
    },
    {
      title: <b>{t("000057")}</b>,
      checkable: false,
      key: "4",
      children: [
        {
          title: t("000058"),
          key: "41",
          value: 2,
          children: [
            {
              title: (
                <div>
                  <BCInputNumber
                    onChange={handleMinFeeChange}
                    max={currentMaxFee}
                    min={0}
                    placeholder="0"
                    disabled={disabledInputNumber}
                    style={{ width: "50px" }}
                  />
                  -
                  <BCInputNumber
                    onChange={handleMaxFeeChange}
                    min={currentMinFee}
                    placeholder="50000"
                    disabled={disabledInputNumber}
                    style={{ width: "50px" }}
                  />
                  <BCButton
                    onClick={handleFeeClick}
                    style={{
                      padding: 0,
                      height: "100%",
                      display: disabledFeeButton ? "inline-block" : "none",
                    }}
                    icon={<BsCaretRightFill />}
                  />
                </div>
              ),
              key: "411",
              checkable: false,
            },
          ],
        },
        { title: t("000059"), key: "42", value: 1 },
      ],
    },
  ];

  return (
    <>
      <div className="consultanciesLayoutSiderTop">
        <span>{t("000052")}</span>
        <BCButton
          content={t("000053")}
          size="small"
          disabled={!currentFilters?.length}
          onClick={resetFilter}
        />
      </div>
      <BCTree
        checkedKeys={currentFilters?.map((i) => i.key)}
        treeData={treeData}
        onCheck={onCheck}
      />
    </>
  );
};

export default FilterSide;
