import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaMessage, FaStar } from "react-icons/fa6";
import { BCCard, BCCol, BCRow, BCSpace } from '..'
import { ICarouselCardAppProps } from './entities'
import './assets/style.scss'


const BCCarouselCardApp = (props: ICarouselCardAppProps) => {
    const { cover, loading, actions, summaryinformation, producer, average, comment, isFree, appname, systemname } = props;

    const { t } = useTranslation()
    const { pathname } = useLocation()
    const customHeadStyle: React.CSSProperties = { backgroundColor: "#d23135", color: "#fff", position: "absolute", left: "-1px", top: "-1px", borderRadius: "8px 0 8px 0" }
    const customBodyStyle: React.CSSProperties = { display: "flex", flexDirection: "column", gap: "10px", paddingBottom: 0 }
    const navigationUrl = pathname == "/applications" ? `${systemname}/detail` : `applications/${systemname}/detail`

    return (
        <Link to={navigationUrl} className='carouselCardAppContainer'>
            <BCCard
                cover={cover}
                actions={actions}
                hoverable
                size='small'
                title={isFree ? t('000059') : ""}
                headStyle={customHeadStyle}
                bodyStyle={customBodyStyle}
            >
                <BCRow justify={'space-between'}>
                    <BCCol className='carouselCardAppAppName'>{appname}</BCCol>
                    <BCCol></BCCol>
                </BCRow>
                <BCRow className='carouselCardAppSummaryInformation'>
                    {summaryinformation}
                </BCRow>
                <BCRow className='cardBottom' justify={'space-between'}>
                    <BCCol >
                        {producer}
                    </BCCol>
                    <BCSpace size={'large'}>
                        {average ? <BCCol className='reviewContainer'>
                            <span className='average'><FaStar color="#9D9D9D" size="16px" /></span>
                            <span>{average}</span>
                        </BCCol> : null}
                        {comment ? <BCCol className='reviewContainer'>
                            <span className='comment'><FaMessage color="#9D9D9D" size="16px" /></span>
                            <span>{comment}</span>
                        </BCCol> : null}
                    </BCSpace>
                </BCRow>
            </BCCard>
        </Link>
    )
}

export default BCCarouselCardApp