import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BCCol,
  BCFlex,
  BCForm,
  BCModal,
  BCRow,
  BCFormItem,
  BCInput,
  BCButton,
  BCCheckbox,
  BCInputPassword,
} from "@components/index";
import { logoDark } from "@assets/index";
import { ILoginProps } from "../entities";
import { useLazyGetCustomerInfoQuery, useLoginMutation } from "@services/index";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  selectCurrentModal,
  selectCurrentToken,
} from "@store/index";
import "./assets/style.scss";

const Login = (props: ILoginProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modal } = useSelector(selectCurrentModal);
  const token = useSelector(selectCurrentToken);
  const localRememberMe = Boolean(Number(localStorage.getItem("rememberMe")));

  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(
    localRememberMe || false
  );
  const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    const isEmail = email == "";
    const isPassword = password == "";

    setLoginButtonDisabled(isEmail || isPassword);
  }, [email, password]);

  const [
    login,
    {
      isLoading: loginIsLoading,
      isSuccess: loginIsSuccess,
      isError: loginIsError,
      error: loginError,
      data: loginData,
    },
  ] = useLoginMutation();

  const [getCustomerInfo, { isLoading, isSuccess, isError, error, data }] =
    useLazyGetCustomerInfoQuery();

  const handleLogin = () => {
    try {
      const data = {
        language: "tr-TR",
        username: email,
        password: password,
        rememberMe: rememberMe,
      };

      login(data);
    } catch (error) {
      console.log("handleLoginError", error);
    }
  };

  const handleRememberMe = (e: CheckboxChangeEvent) => {
    setRememberMe(e.target.checked);
    localStorage.setItem("rememberMe", e.target.checked ? "1" : "0");
  };

  const handleClickForgotPassword = () => {
    dispatch(openModal({ modal: "ResetPassword" }));
  };

  const getCustomer = async () => {
    try {
      const data = await getCustomerInfo().unwrap();

      if (data) {
        dispatch(closeModal());
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      getCustomer();
    }
  }, [token]);

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(closeModal());
  };

  return (
    <BCModal
      open={isModalOpen}
      title={t("000002")}
      onCancel={handleCancel}
      centered
      width={400}
      className="loginContainer"
    >
      <BCFlex vertical>
        <BCRow
          justify={"center"}
          align={"middle"}
          className="loginTopContainer"
        >
          <BCCol span={12}>
            <img src={logoDark} />
          </BCCol>
        </BCRow>
        <BCForm className="loginFormContainer">
          <BCRow>
            <BCCol span={24}>
              <BCFormItem label={t("000035")} required labelCol={{ span: 24 }}>
                <BCInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </BCFormItem>
            </BCCol>
          </BCRow>
          <BCRow>
            <BCCol span={24}>
              <BCFormItem label={t("000103")} required labelCol={{ span: 24 }}>
                <BCInputPassword
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </BCFormItem>
            </BCCol>
          </BCRow>
          <BCRow justify={"space-between"} align={"middle"}>
            <BCCol>
              <BCCheckbox
                label={t("000104")}
                onChange={handleRememberMe}
                checked={rememberMe}
              />
            </BCCol>
            <BCCol>
              <BCButton
                onClick={handleClickForgotPassword}
                type="text"
                content={t("000105")}
              />
            </BCCol>
          </BCRow>
          <BCRow justify={"center"}>
            <BCCol span={12}>
              <BCButton
                htmlType="submit"
                block
                content={t("000002")}
                size={"large"}
                type="primary"
                className="submitButton"
                onClick={handleLogin}
                loading={loginIsLoading || isLoading}
                disabled={loginButtonDisabled}
                style={{
                  backgroundColor: loginButtonDisabled ? "#F5F5F5" : "#00104E",
                  color: loginButtonDisabled ? "#B8B8B8" : "#fff",
                }}
              />
            </BCCol>
          </BCRow>
        </BCForm>
      </BCFlex>
    </BCModal>
  );
};

export default Login;
