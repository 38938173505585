import { Empty } from 'antd';
import { IEmptyProps } from './entities';


const BCEmpty = (props: IEmptyProps) => {
    const { className, style } = props;

    return (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className={className}
            style={style}
        />
    )
}

export default BCEmpty