import {
  createSelector,
  createEntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { ServiceList, baseApi } from "@services/index";
import {
  IGetCategoriesResponse,
  GetCategoriesResult,
  IGetSectorsResponse,
  GetSectorsResult,
  IGetServicesResponse,
  GetServicesResult,
  IGetProductsResponse,
  GetProductsResult,
  IGetSegmentsResponse,
  GetSegmentsResult,
} from "./entities";

const categoriesAdapter = createEntityAdapter<GetCategoriesResult>({});
interface CategoryState extends EntityState<GetCategoriesResult> {}
const initialCategoryState: CategoryState = categoriesAdapter.getInitialState();

const sectorsAdapter = createEntityAdapter<GetSectorsResult>();
interface SectorState extends EntityState<GetSectorsResult> {}
const initialSectorState: SectorState = sectorsAdapter.getInitialState();

const servicesAdapter = createEntityAdapter<GetServicesResult>({});
interface ServiceState extends EntityState<GetServicesResult> {}
const initialServiceState: ServiceState = servicesAdapter.getInitialState();

const productsAdapter = createEntityAdapter<GetProductsResult>({});
interface ProductState extends EntityState<GetProductsResult> {}
const initialProductState: ProductState = productsAdapter.getInitialState();

const segmentsAdapter = createEntityAdapter<GetSegmentsResult>({});
interface SegmentState extends EntityState<GetSegmentsResult> {}
const initialSegmentState: SegmentState = segmentsAdapter.getInitialState();

export const filterApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    GetCategories: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetCategories,
        method: "GET",
      }),
      transformResponse: (responseData: IGetCategoriesResponse) => {
        const loadedCategories = responseData?.result?.map(
          (category: GetCategoriesResult) => {
            category.id = category.DOCUMENTID;
            return category;
          }
        );
        return categoriesAdapter.setAll(initialCategoryState, loadedCategories);
      },
      keepUnusedDataFor: 900,
    }),
    GetSectors: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetSectors,
        method: "GET",
      }),
      transformResponse: (responseData: IGetSectorsResponse) => {
        const loadedSectors = responseData?.result?.map(
          (sector: GetSectorsResult) => {
            sector.id = sector.DOCUMENTID;
            return sector;
          }
        );
        return sectorsAdapter.setAll(initialSectorState, loadedSectors);
      },
      keepUnusedDataFor: 900,
    }),
    GetServices: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetServices,
        method: "GET",
      }),
      transformResponse: (responseData: IGetServicesResponse) => {
        const loadedServices = responseData?.result?.map(
          (service: GetServicesResult) => {
            service.id = service.DOCUMENTID;
            return service;
          }
        );
        return servicesAdapter.setAll(initialServiceState, loadedServices);
      },
      keepUnusedDataFor: 900,
    }),
    GetProducts: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetProducts,
        method: "GET",
      }),
      transformResponse: (responseData: IGetProductsResponse) => {
        const loadedProducts = responseData?.result?.map(
          (product: GetProductsResult) => {
            product.key = `${product.ProductValue + "0"}`;
            product.id = product.ProductValue;
            return product;
          }
        );
        return productsAdapter.setAll(initialProductState, loadedProducts);
      },
      keepUnusedDataFor: 900,
    }),
    GetSegments: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetSegments,
        method: "GET",
      }),
      transformResponse: (responseData: IGetSegmentsResponse) => {
        const loadedSegments = responseData?.result?.map(
          (segment: GetSegmentsResult) => {
            segment.key = `${segment.ID + "00"}`;
            segment.id = segment.ID;
            return segment;
          }
        );
        return segmentsAdapter.setAll(initialSegmentState, loadedSegments);
      },
      keepUnusedDataFor: 900,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetSectorsQuery,
  useGetServicesQuery,
  useGetProductsQuery,
  useGetSegmentsQuery,
} = filterApi;

/////////////////////categories////////////////////////////////////////////////////////////
// returns the query result object
export const selectCategoriesResult =
  filterApi.endpoints.GetCategories.select();
// creates memoized selector
const selectCategoriesData = createSelector(
  selectCategoriesResult,
  (categoriesResult) => categoriesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCategories,
  selectById: selectCategoryById,
  selectIds: selectCategoryIds,
  // Pass in a selector that returns the users slice of state
} = categoriesAdapter.getSelectors(
  (state: any) => selectCategoriesData(state) ?? initialCategoryState
);
////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////sectors////////////////////////////////////////////////////////////////////
// returns the query result object
export const selectSectorsResult = filterApi.endpoints.GetSectors.select();
// creates memoized selector
const selectSectorsData = createSelector(
  selectSectorsResult,
  (sectorsResult) => sectorsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllSectors,
  selectById: selectSectorById,
  selectIds: selectSectorIds,
  // Pass in a selector that returns the users slice of state
} = sectorsAdapter.getSelectors(
  (state: any) => selectSectorsData(state) ?? initialSectorState
);
////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////services////////////////////////////////////////////////////////////////////
// returns the query result object
export const selectServicesResult = filterApi.endpoints.GetServices.select();
// creates memoized selector
const selectServicesData = createSelector(
  selectServicesResult,
  (servicesResult) => servicesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllServices,
  selectById: selectServiceById,
  selectIds: selectServiceIds,
  // Pass in a selector that returns the users slice of state
} = servicesAdapter.getSelectors(
  (state: any) => selectServicesData(state) ?? initialServiceState
);
////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////products////////////////////////////////////////////////////////////////////
// returns the query result object
export const selectProductsResult = filterApi.endpoints.GetProducts.select();
// creates memoized selector
const selectProductsData = createSelector(
  selectProductsResult,
  (productsResult) => productsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
  // Pass in a selector that returns the users slice of state
} = productsAdapter.getSelectors(
  (state: any) => selectProductsData(state) ?? initialProductState
);
////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////segments////////////////////////////////////////////////////////////////////
// returns the query result object
export const selectSegmentsResult = filterApi.endpoints.GetSegments.select();
// creates memoized selector
const selectSegmentsData = createSelector(
  selectSegmentsResult,
  (segmentsResult) => segmentsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllSegments,
  selectById: selectSegmentById,
  selectIds: selectSegmentIds,
  // Pass in a selector that returns the users slice of state
} = segmentsAdapter.getSelectors(
  (state: any) => selectSegmentsData(state) ?? initialSegmentState
);
////////////////////////////////////////////////////////////////////////////////////////////////
