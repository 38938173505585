import { Link } from 'react-router-dom';
import { Avatar, Card } from 'antd';
import { FaMessage, FaStar } from "react-icons/fa6";
import { AiOutlineUser } from "react-icons/ai";
import { BCCard, BCCol, BCFlex, BCRow, BCSpace } from '..'
import { ICardConsultancyProps } from './entities';
import './assets/style.scss'


const BCCardConsultancy = (props: ICardConsultancyProps) => {
    const { consultancyname, avatar, summaryinformation, providerprofile, average, comment, navigationUrl } = props;

    return (
        <Link to={`${navigationUrl}`} className='cardConsultancyContainer'>
            <BCCard
                hoverable
                size='default'
                bodyStyle={{ padding: "16px 14px 13px 14px" }}
            >
                <BCFlex vertical justify='space-between' gap={3}>
                    <BCRow>
                        <BCCol>
                            <Card.Meta
                                avatar={<Avatar size={80} src={avatar} shape='square' />}
                                title={consultancyname}
                                description={summaryinformation}
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            />
                        </BCCol>
                    </BCRow>
                    <BCRow className='cardBottom' justify={'space-between'} align={'bottom'}>
                        <BCCol style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                            <span><AiOutlineUser /></span>
                            <span>{providerprofile}</span>
                        </BCCol>
                        <BCSpace size={'large'}>
                            {/* <BCCol className='reviewContainer'>
                                <span className='average'><FaStar color="#9D9D9D" size="16px" /></span>
                                <span>{average ? average : 5}</span>
                            </BCCol>
                            <BCCol className='reviewContainer'>
                                <span className='comment'><FaMessage color="#9D9D9D" size="16px" /></span>
                                <span>{comment ? comment : 0}</span>
                            </BCCol> */}
                        </BCSpace>
                    </BCRow>
                </BCFlex>
            </BCCard>
        </Link>
    )
}

export default BCCardConsultancy