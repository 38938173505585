import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Avatar, Tag } from "antd";
import {
  UserAddOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaGithub,
  FaGitlab,
  FaInstagram,
} from "react-icons/fa";
// import { SlHome } from "react-icons/sl";
import {
  selectAllSectors,
  useCreateFavPartnersMutation,
  useDeleteFavPartnersMutation,
  useLazyGetEvaluationByPartnerCodeQuery,
  useLazyGetPartnerDetailQuery,
} from "@services/index";
import {
  BCButton,
  BCCardMeta,
  BCCol,
  BCLayout,
  BCLayoutContent,
  BCLayoutSider,
  BCRow,
  BCSpace,
  BCTabs,
} from "@components/index";
import { openModal, selectCurrentUser } from "@store/index";
import { topSlideBackground } from "@assets/index";
import GeneralInformationTab from "./tabs/GeneralInformationTab";
// import ApplicationsTab from './tabs/ApplicationsTab'
// import ConsultancyServicesTab from './tabs/ConsultancyServicesTab'
import ReviewsTab from "./tabs/ReviewsTab";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const PartnerDetail = () => {
  const dispatch = useDispatch();
  const { shortCode } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const allSectors = useSelector(selectAllSectors);
  const user = useSelector(selectCurrentUser);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [
    getPartnerDetail,
    {
      isLoading: getDetailisLoading,
      isSuccess: getDetailisSuccess,
      isError: getDetailisError,
      error: getDetailerror,
      data: partner,
    },
  ] = useLazyGetPartnerDetailQuery();

  useEffect(() => {
    if (shortCode) {
      getPartnerDetail({
        shortCode: shortCode,
        ispreview: pathname.split("/").includes("preview"),
      });
      getEvaluationByPartnerCode(shortCode);
    }
  }, [shortCode]);

  const [
    getEvaluationByPartnerCode,
    {
      data: evaluations,
      isLoading: getEvaluationisLoading,
      isSuccess: getEvaluationisSuccess,
      isError: getEvaluationisError,
      error: getEvaluationerror,
    },
  ] = useLazyGetEvaluationByPartnerCodeQuery();

  const [
    addToFavorites,
    {
      isLoading: addToFavoritesisLoading,
      isSuccess: addToFavoritesisSuccess,
      isError: addToFavoritesisError,
      error: addToFavoriteserror,
      data: favoriteApp,
    },
  ] = useCreateFavPartnersMutation();

  const [
    removeFromFavorites,
    {
      isLoading: removeFromFavoritesisLoading,
      isSuccess: removeFromFavoritesisSuccess,
      isError: removeFromFavoritesisError,
      error: removeFromFavoriteserror,
      data: removedFavoriteApp,
    },
  ] = useDeleteFavPartnersMutation();

  const handleClickAddToFavorites = () => {
    try {
      if (user) addToFavorites({ PARTNER: partner.DOCUMENTID });
      else dispatch(openModal({ modal: "Login" }));
    } catch (error) {
      console.log("addToFavoritesError", error);
    }
  };

  const handleClickRemoveFromFavorites = () => {
    try {
      if (user) removeFromFavorites({ DOCUMENTID: partner.DOCUMENTID });
      else dispatch(openModal({ modal: "Login" }));
    } catch (error) {
      console.log("removeFromFavoritesError", error);
    }
  };

  const tabs = [
    {
      key: "1",
      label: t("000043"),
      children: <GeneralInformationTab partner={partner} />,
    },
    // {
    //   key: '2',
    //   label: t('000003'),
    //   children: <ApplicationsTab />,
    // },
    // {
    //   key: '3',
    //   label: t('000004'),
    //   children: <ConsultancyServicesTab />,
    // },
    {
      key: "4",
      label: `${t("000031")} (${evaluations?.length})`,
      children: <ReviewsTab evaluations={evaluations} />,
    },
  ];

  let content = null;

  if (getDetailisSuccess && partner) {
    const partnerSectors = partner.IndustryInformationText?.split(",");
    const matchingSectors = allSectors.filter((sector: any) =>
      partnerSectors?.includes(sector.SECTORDEFINITION)
    );

    const getSegmentContent = () => {
      switch (partner.SegmentText) {
        case "PLATINIUM":
          return <div className="platinumSegment">PLATINUM</div>;
        case "GOLD":
          return <div className="goldSegment">GOLD</div>;
        default:
          return <div className="silverSegment">SILVER</div>;
      }
    };

    const getFavoriteButton = () => {
      if (partner?.FAVORITE)
        return (
          <BCButton
            onClick={handleClickRemoveFromFavorites}
            loading={removeFromFavoritesisLoading}
            disabled={removeFromFavoritesisLoading}
            block
            content={t("000116")}
          />
        );
      if (!partner?.FAVORITE)
        return (
          <BCButton
            onClick={handleClickAddToFavorites}
            loading={addToFavoritesisLoading}
            disabled={addToFavoritesisLoading}
            block
            content={t("000019")}
          />
        );
    };

    const favoriteButton = getFavoriteButton();
    const segmentContent = getSegmentContent();

    content = (
      <div className="partnerDetailContainer">
        <div className="detailHeader">
          <img className="banner" src={topSlideBackground} />
          <Avatar
            size={100}
            shape="square"
            icon={<img src={useBlobUrl(partner.Logo)} />}
          />
        </div>
        <div className="contentTop">
          <BCLayout>
            <BCLayoutContent>
              <div>
                <span className="partnerName">{partner.Name}</span>
              </div>
              <p className="summaryInformation">{partner.COVERLETTER}</p>
              <div className="generalInformations">
                <BCRow>
                  <BCCol className="informationLabel">{t("000025")}</BCCol>
                  <BCCol>
                    {matchingSectors?.map((sector: any) => {
                      return (
                        <Tag
                          className="informationTag"
                          color={sector?.DEFINITIONCOLOR}
                        >
                          {sector.SECTORDEFINITION}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
              </div>
            </BCLayoutContent>
            <BCLayoutSider width={230}>
              <div className="buttonsContainer">
                {segmentContent}
                <div className="modalButtonsContainer">
                  {/* <BCButton block text={t('000021')} onClick={() => dispatch(openModal({ modal: "PartnerAppeal", parameters: partner }))} /> */}
                  {/* {partner.FAVORITE ? <BCButton onClick={handleClickRemoveFromFavorites} loading={removeFromFavoritesisLoading} block text={t('000116')} />
                  : <BCButton onClick={handleClickAddToFavorites} loading={addToFavoritesisLoading} block text={t('000019')} />} */}
                  {favoriteButton}
                </div>
                <BCSpace direction="horizontal">
                  {partner.LINKEDIN ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.LINKEDIN}
                      icon={<FaLinkedinIn />}
                    />
                  ) : null}
                  {partner.TWITTER ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.TWITTER}
                      icon={<FaTwitter />}
                    />
                  ) : null}
                  {partner.YOUTUBE ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.YOUTUBE}
                      icon={<FaYoutube />}
                    />
                  ) : null}
                  {partner.INSTAGRAM ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.INSTAGRAM}
                      icon={<FaInstagram />}
                    />
                  ) : null}
                  {partner.GITLAB ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.GITLAB}
                      icon={<FaGitlab />}
                    />
                  ) : null}
                  {partner.GITHUB ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.GITHUB}
                      icon={<FaGithub />}
                    />
                  ) : null}
                  {partner.FACEBOOK ? (
                    <BCButton
                      type="link"
                      target="_blank"
                      href={partner.FACEBOOK}
                      icon={<FaFacebook />}
                    />
                  ) : null}
                </BCSpace>
              </div>
            </BCLayoutSider>
          </BCLayout>
        </div>
        <div className="contentBottom">
          <BCLayout>
            <BCLayoutContent>
              <BCTabs items={tabs} />
            </BCLayoutContent>
            <BCLayoutSider width={270}>
              <div className="siderContainer">
                <div className="partnerInformations">
                  <h3>{t("000048")}</h3>
                  <BCCardMeta
                    title={t("000033")}
                    description={partner.Name}
                    avatar={<UserAddOutlined />}
                  />
                  <BCCardMeta
                    title={t("000034")}
                    description={
                      <BCButton
                        content={partner.WebAddress}
                        type="link"
                        target="_blank"
                        href={partner.WebAddress}
                      />
                    }
                    avatar={<GlobalOutlined />}
                  />
                  <BCCardMeta
                    title={t("000035")}
                    description={
                      <BCButton
                        content={partner.WebAddress}
                        type="link"
                        href={`mailto:${partner.WebAddress}`}
                      />
                    }
                    avatar={<MailOutlined />}
                  />
                  <BCCardMeta
                    title={t("000036")}
                    description={
                      <BCButton
                        content={partner.Phone}
                        type="link"
                        href={`tel:${partner.Phone}`}
                      />
                    }
                    avatar={<PhoneOutlined />}
                  />
                  {/* <BCCardMeta title={t('000044')} description={partner.Address} avatar={<SlHome />} buttonText='Haritada göster' buttonType='text' /> */}
                </div>
              </div>
            </BCLayoutSider>
          </BCLayout>
        </div>
      </div>
    );
  }
  return content;
};

export default PartnerDetail;
