import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Card } from 'antd';
import { BCButton, BCCard, BCCol, BCFlex, BCRow, BCSpace } from '..'
import { ICardPartnerProps } from './entities';
import './assets/style.scss'


const BCCardPartner = (props: ICardPartnerProps) => {
    const { partnerName, avatar, summaryinformation, producer, average, comment, navigationUrl, segment, coverLetter } = props;

    const { t } = useTranslation()

    let status;

    if (segment == "Platinium") {
        status = <div className='platinumSegment'>PLATINUM</div>
    } else if (segment == "Gold") {
        status = <div className='goldSegment'>GOLD</div>
    } else {
        status = <div className='silverSegment'>SILVER</div>
    }

    return (
        <Link to={`${navigationUrl}`} >
            <BCCard
                hoverable
                size='default'
                bodyStyle={{ padding: "16px 14px 13px 14px" }}
            >
                <BCFlex vertical justify='space-between' gap={24} className='cardPartnerContainer'>
                    <BCRow>
                        <BCCol className='metaContainer'>
                            <Card.Meta
                                avatar={<Avatar size={70} src={avatar} shape='square' style={{ border: "1px solid #f2f2f2" }} />}
                                title={partnerName}
                                description={status}
                            />
                        </BCCol>
                    </BCRow>
                    <BCRow className='coverLetter'>
                        {coverLetter}
                    </BCRow>
                    {/* <BCRow justify={'space-between'}>
                        <BCCol style={{ display: "flex", flexDirection: "column", gap: "6px" }}>

                        </BCCol>
                        <BCSpace size={'large'}>
                            <BCCol>
                                <BCButton text={t('000021')} onClick={(e) => {
                                    e.preventDefault();
                                }} />
                            </BCCol>
                        </BCSpace>
                    </BCRow> */}
                </BCFlex>
            </BCCard>
        </Link>
    )
}

export default BCCardPartner