import { Input } from 'antd';
import { ITextAreaProps } from './entities';

const BCInputTextArea = (props: ITextAreaProps) => {
    const { autoSize, bordered, maxLength, placeholder, rows, showCount, value, onChange } = props;
    const { TextArea } = Input;


    return (
        <TextArea
            rows={rows}
            autoSize={autoSize}
            bordered={bordered}
            maxLength={maxLength}
            showCount={showCount}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    )
}

export default BCInputTextArea