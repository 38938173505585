import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  BCButton,
  BCCardConsultancy,
  BCDrawer,
  BCLayout,
  BCLayoutContent,
  BCLayoutSider,
  BCSkeleton,
} from "@components/index";
import { FeaturedConsultancyCarousel, PageTopCarousel } from "@features/index";
import {
  useGetOppurtunityConsultancyQuery,
  useGetTopTenConsultancyQuery,
} from "@services/index";
import {
  consultancyFilter,
  selectCurrentConsultancyFilters,
} from "@store/index";
import FilterContent from "./components/filterContent";
import FilterSide from "./components/filterSide";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const Consultancies = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentFilters = useSelector(selectCurrentConsultancyFilters);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isBreakpoint, setIsBreakpoint] = useState<boolean>(false);
  const [disabledInputNumber, setDisabledInputNumber] = useState<boolean>(true);
  const [minFee, setMinFee] = useState<number>(0);
  const [maxFee, setMaxFee] = useState<number>(50000);
  const [feeFilter, setFeeFilter] = useState<boolean>(false);

  const {
    data: topTenConsultancies,
    isLoading: topTenConsultanciesisLoading,
    isSuccess: topTenConsultanciesisSuccess,
    isError: topTenConsultanciesisError,
    error: topTenConsultancieserror,
  } = useGetTopTenConsultancyQuery();

  const {
    data: oppurtunityConsultancies,
    isLoading: oppurtunityConsultanciesisLoading,
    isSuccess: oppurtunityConsultanciesisSuccess,
    isError: oppurtunityConsultanciesisError,
    error: oppurtunityConsultancieserror,
  } = useGetOppurtunityConsultancyQuery();

  const breakpoints = {
    700: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    750: {
      slidesPerView: 1.3,
      spaceBetween: 10,
    },
    800: {
      slidesPerView: 1.5,
      spaceBetween: 10,
    },
    850: {
      slidesPerView: 1.7,
      spaceBetween: 10,
    },
    960: {
      slidesPerView: 1.9,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 2.1,
      spaceBetween: 10,
    },
    1124: {
      slidesPerView: 2.4,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1280: {
      slidesPerView: 3.5,
      spaceBetween: 10,
    },
  };

  const resetFilter = () => {
    dispatch(consultancyFilter({ filters: [] }));
    setDisabledInputNumber(true);
  };

  useEffect(() => {
    if (location.pathname == "/consultancies") resetFilter();
  }, [location]);

  const handleTagClose = (tagKey: string) => {
    const filteredTags = currentFilters?.filter(
      (filter) => filter.key !== tagKey
    );
    dispatch(consultancyFilter({ filters: filteredTags }));

    if (tagKey == "41") setDisabledInputNumber(true);
  };

  const showAllConsultancies = (consultancyType: string) => {
    if (consultancyType == "topTenConsultancies") {
      dispatch(
        consultancyFilter({
          filters: [
            {
              key: "02",
              position: "0-0-1",
              title: "Yeni Danışmanlıklar",
              value: 2,
            },
          ],
        })
      );
    }
    if (consultancyType == "oppurtunityConsultancies") {
      dispatch(
        consultancyFilter({
          filters: [
            { key: "03", position: "0-0-2", title: "Fırsatlar", value: 3 },
          ],
        })
      );
    }
  };

  const onCollapse = (value: boolean, type: "clickTrigger" | "responsive") => {
    setIsBreakpoint(value);
    setCollapsed(value);
  };

  let topTenConsultanciesContent = null;
  let oppurtunityConsultanciesContent = null;

  if (topTenConsultanciesisLoading)
    topTenConsultanciesContent = [0, 1, 2].map(() => {
      return (
        <BCSkeleton
          loading={topTenConsultanciesisLoading}
          paragraph={{ rows: 2 }}
          round={false}
          style={{ width: "350px" }}
        />
      );
    });

  if (oppurtunityConsultanciesisLoading)
    oppurtunityConsultanciesContent = [0, 1, 2].map(() => {
      return (
        <BCSkeleton
          loading={oppurtunityConsultanciesisLoading}
          paragraph={{ rows: 2 }}
          round={false}
          style={{ width: "350px" }}
        />
      );
    });

  if (topTenConsultanciesisSuccess && topTenConsultancies?.length)
    topTenConsultanciesContent = topTenConsultancies
      .slice(0, 9)
      .map((consultancy: any) => {
        return (
          <BCCardConsultancy
            consultancyname={consultancy.consultancyname}
            average={consultancy.average}
            comment={consultancy.comment}
            providerprofile={consultancy.providerprofile}
            summaryinformation={consultancy.summaryinformation}
            navigationUrl={`${consultancy.systemname}/detail`}
            avatar={useBlobUrl(consultancy.icon)}
          />
        );
      });

  if (oppurtunityConsultanciesisSuccess && oppurtunityConsultancies?.length)
    oppurtunityConsultanciesContent = oppurtunityConsultancies
      .slice(0, 9)
      .map((consultancy: any) => {
        return (
          <BCCardConsultancy
            consultancyname={consultancy.consultancyname}
            average={consultancy.average}
            comment={consultancy.comment}
            providerprofile={consultancy.providerprofile}
            summaryinformation={consultancy.summaryinformation}
            navigationUrl={`${consultancy.systemname}/detail`}
            avatar={useBlobUrl(consultancy.icon)}
          />
        );
      });

  return (
    <>
      <PageTopCarousel />
      <BCLayout>
        {collapsed ? (
          <BCDrawer>
            <FilterSide
              disabledInputNumber={disabledInputNumber}
              setDisabledInputNumber={setDisabledInputNumber}
              setMinFee={setMinFee}
              setMaxFee={setMaxFee}
              setFeeFilter={setFeeFilter}
              feeFilter={feeFilter}
            />
          </BCDrawer>
        ) : null}
        <BCLayoutSider
          className="consultanciesLayoutSider"
          style={{ margin: collapsed ? "0" : "20px 0 0 3%" }}
          collapsed={collapsed}
          collapsedWidth={"0"}
          onCollapse={onCollapse}
        >
          <FilterSide
            disabledInputNumber={disabledInputNumber}
            setDisabledInputNumber={setDisabledInputNumber}
            setMinFee={setMinFee}
            setMaxFee={setMaxFee}
            setFeeFilter={setFeeFilter}
            feeFilter={feeFilter}
          />
        </BCLayoutSider>
        <BCLayoutContent className="consultanciesLayoutContent">
          {location.pathname.startsWith("/consultancies/filter/") ? (
            <FilterContent
              handleTagClose={handleTagClose}
              minFee={minFee}
              maxFee={maxFee}
              feeFilter={feeFilter}
            />
          ) : (
            <>
              <div
                style={{ paddingRight: isBreakpoint ? "3%" : "3%" }}
                className="consultanciesFeaturedConsultancyCarousel"
              >
                <FeaturedConsultancyCarousel breakpoints={breakpoints} />
              </div>
              <div className="consultanciesContainer">
                <div>
                  <div className="consultanciesTopContainer">
                    <p>{t("000010")}</p>
                    <BCButton
                      onClick={() =>
                        showAllConsultancies("topTenConsultancies")
                      }
                      href="#/consultancies/filter/all"
                      content={t("000006")}
                      type="text"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                    />
                  </div>
                  <div className="consultanciesContentContainer">
                    {topTenConsultanciesContent}
                  </div>
                </div>
                <div>
                  <div className="consultanciesTopContainer">
                    <p>{t("000009")}</p>
                    <BCButton
                      onClick={() =>
                        showAllConsultancies("oppurtunityConsultancies")
                      }
                      href="#/consultancies/filter/all"
                      content={t("000006")}
                      type="text"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                    />
                  </div>
                  <div className="consultanciesContentContainer">
                    {oppurtunityConsultanciesContent}
                  </div>
                </div>
              </div>
            </>
          )}
        </BCLayoutContent>
      </BCLayout>
    </>
  );
};

export default Consultancies;
