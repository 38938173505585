import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "@utils/index";
import {
  Login,
  Register,
  ConsultancyRequest,
  PresentationRequest,
  FreeTrial,
  InformationModal,
  PartnerAppeal,
  BuyApp,
  ResetPassword,
} from "@features/index";
import { openModal, selectCurrentModal } from "@store/index";

const ModalManager = () => {
  const { modal, parameters } = useSelector(selectCurrentModal);
  const isAuth = useAuth();

  const dispatch = useDispatch();

  const requireAuthModal = (Component: React.FC<any>) => {
    if (isAuth) {
      return <Component parameters={parameters} />;
    } else {
      dispatch(openModal({ modal: "Login" }));
    }
  };

  const renderModal = () => {
    switch (modal) {
      case "Login":
        return <Login />;
      case "Register":
        return <Register />;
      case "ResetPassword":
        return <ResetPassword />;
      case "ConsultancyRequest":
        return requireAuthModal(ConsultancyRequest);
      case "PresentationRequest":
        return requireAuthModal(PresentationRequest);
      case "FreeTrial":
        return requireAuthModal(FreeTrial);
      case "BuyApp":
        return requireAuthModal(BuyApp);
      case "PartnerAppeal":
        return requireAuthModal(PartnerAppeal);
      case "Information":
        return requireAuthModal(InformationModal);
      default:
        return null;
    }
  };

  return renderModal();
};

export default ModalManager;
