import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { LuArrowUpWideNarrow, LuArrowDownWideNarrow } from "react-icons/lu";
import { selectCurrentAppFilters } from "@store/index";
import { useLazyGetAppsQuery } from "@services/index";
import {
  BCButton,
  BCCardApplication,
  BCEmpty,
  BCSelect,
  BCSkeleton,
  BCSpace,
  BCTag,
} from "@components/index";
import { IAppFilterProps } from "./entities";
import "./assets/style.scss";

const FilterContent = (props: IAppFilterProps) => {
  const { handleTagClose, rate, setRate, feeFilter, minFee, maxFee } = props;

  const { t } = useTranslation();
  const filters = useSelector(selectCurrentAppFilters);
  // const [filterArrow, setFilterArrow] = useState(false);

  const [
    getApps,
    {
      isLoading: getAppsisLoading,
      isSuccess: getAppsisSuccess,
      isError: getAppisErrors,
      error: getAppserror,
      data: filteredApps,
    },
  ] = useLazyGetAppsQuery();

  const filterApplications = () => {
    try {
      const APPTYPE =
        filters
          ?.filter((item) => item.position.startsWith("0-0"))
          .map((item) => item.value) || [];
      const SECTOR =
        filters
          ?.filter((item) => item.position.startsWith("0-1"))
          .map((item) => item.value) || [];
      const CATEGORY =
        filters
          ?.filter((item) => item.position.startsWith("0-2"))
          .map((item) => item.value) || [];
      const MANUFACTURER = filters
        ?.filter((item) => item.position.startsWith("0-3"))
        .map((item) => item.value);
      const ISPAID = filters?.find((item) => item.position.startsWith("0-4-0"));
      const ISFREE = filters?.find((item) => item.position.startsWith("0-4-1"));
      const ISDEMOAPP = filters?.find((item) => item.key === "51");
      const MULTILANGUAGES = filters?.find((item) => item.key === "52");
      const POINT = filters?.find((item) => item.position.startsWith("0-6-0"));

      const isPaid = () => {
        if (ISPAID && ISFREE)
          return ""; // Hem ödeme gerektiren hem de ücretsiz bir durum söz konusuysa
        else if (ISPAID)
          return 1; // Sadece ödeme gerektiren bir durum söz konusuysa
        else if (ISFREE) return 0; // Sadece ücretsiz bir durum söz konusuysa
        else return ""; // Ne ödeme gerektiriyor ne de ücretsiz bir durum söz konusuysa
      };

      const isPoint = () => {
        if (POINT) return rate as number;
        else {
          if (setRate) setRate(0);
          return rate as number;
        }
      };

      const requestData = {
        APPNAME: "",
        APPTYPE: APPTYPE,
        SECTOR: SECTOR,
        CATEGORY: CATEGORY,
        MANUFACTURER: MANUFACTURER?.length == 1 ? MANUFACTURER?.[0] : "",
        ISPAID: isPaid(),
        MINFEE: isPaid() == 1 ? (minFee as number) : "",
        MAXFEE: isPaid() == 1 ? (maxFee as number) : "",
        ISDEMOAPP: ISDEMOAPP ? 1 : 0,
        MULTILANGUAGES: MULTILANGUAGES ? 1 : 0,
        POINT: isPoint(),
      };

      getApps(requestData);
    } catch (error) {}
  };

  useEffect(() => {
    filterApplications();
  }, [filters, rate, feeFilter]);

  let filteredAppsContent = null;

  if (getAppsisLoading)
    filteredAppsContent = [0, 1, 2].map(() => {
      return (
        <BCSkeleton
          loading={true}
          paragraph={{ rows: 2 }}
          round={false}
          style={{ width: "350px" }}
        />
      );
    });

  if (getAppsisSuccess && filteredApps?.length)
    filteredAppsContent = filteredApps.map((app: any) => {
      return (
        <BCCardApplication
          appname={app.appname}
          average={app.average}
          comment={app.comment}
          producer={app.producer}
          summaryinformation={app.summaryinformation}
          navigationUrl={`/applications/${app.systemname}/detail`}
          avatar={`https://saas8402cs.blob.core.windows.net/portal-store-storage/${app.icon}`}
        />
      );
    });

  if (!getAppsisLoading && !filteredApps?.length)
    filteredAppsContent = <BCEmpty className="emptyContent" />;

  return (
    <div className="applicationsFiltersContainer">
      {filters?.length ? (
        <div className="filterTagsContainer">
          {filters.map(
            (tag: {
              position: string;
              title: React.ReactNode | string;
              key: string;
              value: string | number;
            }) => {
              return (
                <BCTag
                  closable
                  tagKey={tag.key}
                  handleClose={handleTagClose}
                  className="filterTag"
                >
                  {tag.title}
                  {tag.key == "41" ? ` (${minFee} - ${maxFee})` : null}
                  {tag.key == "61" ? ` (${rate} ve üzeri)` : null}
                </BCTag>
              );
            }
          )}
        </div>
      ) : null}
      <div className="filtersContentContainer">
        <div className="contentTopContainer">
          <span className="FilterHead">{t("000003")}</span>
          <BCSpace size={"middle"} className="filterTopParameters">
            {/* <span>{t('000014')}</span>
                        <BCSelect
                            options={[
                                { value: 'Alfabetik', label: 'Alfabetik' },
                                { value: 'Puan', label: 'Puan' },
                                { value: 'Tarih', label: 'Tarih' },
                            ]}
                            defaultValue='Alfabetik'
                        />
                        <BCButton
                            leftIcon={filterArrow ? <LuArrowUpWideNarrow size='24px' /> : <LuArrowDownWideNarrow size='24px' />}
                            type='text'
                            onClick={() => setFilterArrow(!filterArrow)}
                        /> */}
          </BCSpace>
        </div>
        <div className="contentContainer">{filteredAppsContent}</div>
      </div>
    </div>
  );
};

export default FilterContent;
