import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileLines,
} from "react-icons/fa6";

interface DocumentInfo {
  documentUrl: string;
  documentName: string;
}

const getDocumentIcon = (document: DocumentInfo) => {
  const documentType = document.documentUrl.split(".")[1];
  const documentNameWithType = `${document.documentName}.${documentType}`;

  const wordTypes = ["docx", "doc"];
  const excelTypes = ["xlsx", "xls"];

  const getIcon = (type: string) => {
    if (type === "pdf") {
      return <FaFilePdf />;
    } else if (wordTypes.includes(type)) {
      return <FaFileWord />;
    } else if (excelTypes.includes(type)) {
      return <FaFileExcel />;
    } else {
      return <FaFileLines />;
    }
  };

  const icon = getIcon(documentType);

  return (
    <>
      {icon}
      {documentNameWithType}
    </>
  );
};

export default getDocumentIcon;
