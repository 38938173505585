import { useState } from "react";
import { Drawer } from "antd";
import { BiRightIndent } from "react-icons/bi";
import { BCButton } from "..";
import { IDrawerProps } from "./entities";
import "./assets/style.scss";

const BCDrawer = (props: IDrawerProps) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BCButton
        type="text"
        onClick={showDrawer}
        style={{ padding: 0, marginTop: "24px" }}
        className="drawerButton"
        content={<BiRightIndent size="24px" />}
      />

      <Drawer
        className="drawer"
        placement="left"
        closeIcon={false}
        onClose={onClose}
        open={open}
        width={215}
      >
        {props.children}
      </Drawer>
    </>
  );
};

export default BCDrawer;
