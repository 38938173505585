import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { ArrowRightOutlined } from "@ant-design/icons";
import { BCButton, BCCarouselCardApp, BCSkeleton } from "@components/index";
import { useGetFeaturedAppsQuery } from "@services/index";
import { appFilter } from "@store/index";
import { IFeaturedAppCarouselProps } from "./entities";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const FeaturedAppCarousel = (props: IFeaturedAppCarouselProps) => {
  const { breakpoints } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    data: featuredApps,
    isLoading: featuredAppsisLoading,
    isSuccess: featuredAppsisSuccess,
    isError: featuredAppsisError,
    error: featuredAppserror,
  } = useGetFeaturedAppsQuery();

  const showAllApps = (applicationType: string) => {
    if (applicationType == "featuredApps") {
      dispatch(
        appFilter({
          filters: [
            { key: "01", position: "0-0-0", title: "Öne Çıkanlar", value: 1 },
          ],
        })
      );
    }
  };

  let content = null;

  if (featuredAppsisLoading)
    content = [1, 2, 3, 4].map(() => {
      return (
        <SwiperSlide>
          <BCSkeleton
            loading={featuredAppsisLoading}
            paragraph={{ rows: 4 }}
            round={false}
          />
        </SwiperSlide>
      );
    });

  if (featuredAppsisSuccess && featuredApps?.length)
    content = featuredApps.map((app: any) => {
      return (
        <SwiperSlide>
          <BCCarouselCardApp
            cover={
              <img src={useBlobUrl(app.logo)} className="swiperSlideItem" />
            }
            isFree
            appname={app.appname}
            systemname={app.systemname}
            comment={app.comment}
            producer={app.producer}
            average={app.average}
            summaryinformation={app.summaryinformation}
          />
        </SwiperSlide>
      );
    });

  return (
    <div>
      <div className="featuredAppCarouselTopContainer">
        <p>{t("000007")}</p>
        <BCButton
          onClick={() => showAllApps("featuredApps")}
          href="#/applications/filter/all"
          content={t("000006")}
          type="text"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
        />
      </div>
      <Swiper
        className="swiperFeaturedAppCarouselContainer"
        modules={[Navigation, Pagination, A11y, Autoplay]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        autoplay={{
          delay: 90000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        breakpoints={breakpoints}
      >
        {content}
        <div className="navigationContainer">
          <span className="swiper-button-prev" />
          <span className="swiper-button-next" />
        </div>
      </Swiper>
    </div>
  );
};

export default FeaturedAppCarousel;
