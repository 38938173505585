import { ServiceList, baseApi } from "@services/index";
import {
  IGetCitiesByCountryRequest,
  IGetCitiesByCountryResponse,
  IGetCountriesResponse,
  IGetNationalCodesResponse,
} from "./entities";

export const usefulApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    GetCountries: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetCountries,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
      transformResponse: (response: IGetCountriesResponse) => response.result,
    }),
    GetCitiesByCountry: builder.query<any, IGetCitiesByCountryRequest>({
      query: (countryId) => ({
        url: ServiceList.baseQuery2.GetCitiesByCountry,
        method: "POST",
        body: countryId,
      }),
      transformResponse: (response: IGetCitiesByCountryResponse) =>
        response.result,
    }),
    GetNationalCodes: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetNationalCodes,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
      transformResponse: (response: IGetNationalCodesResponse) =>
        response.result,
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useLazyGetCitiesByCountryQuery,
  useGetNationalCodesQuery,
} = usefulApi;
