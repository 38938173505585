import { Modal } from 'antd';
import { IModalProps } from './entities';
import './assets/style.scss'

const BCModal = (props: IModalProps) => {
    const { title, centered, open, onOk, onCancel, okText, cancelText, okButtonProps, cancelButtonProps, confirmLoading, width, footer, style, className } = props;

    return (
        <Modal
            title={title}
            centered={centered}
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            okText={okText}
            cancelText={cancelText}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            confirmLoading={confirmLoading}
            width={width}
            footer={footer || null}
            style={style}
            className={className}
        >
            {props.children}
        </Modal>
    )
}

export default BCModal