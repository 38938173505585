import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCategoriesQuery,
  useGetSectorsQuery,
  useGetServicesQuery,
  useGetProductsQuery,
  useLazyGetCustomerInfoQuery,
  useGetSegmentsQuery,
} from "@services/index";
import {
  BCLayout,
  BCLayoutContent,
  BCLayoutFooter,
  BCLayoutHeader,
} from "@components/index";
// import { selectAllCategories, selectCategoriesResult, selectCategoryById, selectCategoryIds } from '@services/api/tag/tagApi';
import {
  selectCurrentModal,
  selectCurrentToken,
  setCredentials,
} from "@store/index";
import Header from "./header/Header";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import "./assets/style.scss";
import { useAuth } from "@utils/index";

const MainLayout = () => {
  // //Belirli bir kategoriyi ID'ye göre almak için
  // const specificCategory = useSelector((state) => selectCategoryById(state, 66667));
  // //Tüm kategorileri almak için
  // const allCategories = useSelector(selectAllCategories);
  // //Tüm kategori ID'lerini almak için
  // const categoryIds = useSelector(selectCategoryIds);
  // //Bu seçici 'getCategories' API çağrısının sonuçlarını döndürür. categoriesResult, API çağrısı sonucundaki verileri ve hata durumlarını içerir
  // const categoriesResult = useSelector(selectCategoriesResult);

  const dispatch = useDispatch();
  const token = useAuth();
  const currentToken = useSelector(selectCurrentToken);
  const { modal } = useSelector(selectCurrentModal);

  useEffect(() => {
    if (token) {
      dispatch(setCredentials({ token: token }));
    }
  }, []);

  const [getCustomerInfo, { isLoading, isSuccess, isError, error, data }] =
    useLazyGetCustomerInfoQuery();

  const getUser = async () => {
    try {
      const data = await getCustomerInfo().unwrap();
      if (data) {
        dispatch(setCredentials({ token: currentToken, user: data }));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (currentToken && !modal) {
      getUser();
    }
  }, [currentToken]);

  const {
    data: Sectors,
    isLoading: SectorsisLoading,
    isSuccess: SectorsisSuccess,
    isError: SectorsisError,
    error: Sectorserror,
  } = useGetSectorsQuery();
  const {
    data: Categories,
    isLoading: CategoriesisLoading,
    isSuccess: CategoriesisSuccess,
    isError: CategoriesisError,
    error: Categorieserror,
  } = useGetCategoriesQuery();
  const {
    data: Services,
    isLoading: ServicesisLoading,
    isSuccess: ServicesisSuccess,
    isError: ServicesisError,
    error: Serviceserror,
  } = useGetServicesQuery();
  const {
    data: Products,
    isLoading: ProductsisLoading,
    isSuccess: ProductsisSuccess,
    isError: ProductsisError,
    error: Productserror,
  } = useGetProductsQuery();
  const {
    data: segments,
    isLoading: segmentsIsLoading,
    isSuccess: segmentsIsSuccess,
    isError: segmentsIsError,
    error: segmentsError,
  } = useGetSegmentsQuery();

  return (
    <BCLayout className="mainLayout">
      <BCLayoutHeader className="mainLayoutHeader">
        <Header />
      </BCLayoutHeader>
      <div className="mainLayoutNavbar">
        <Navbar />
      </div>
      <BCLayoutContent className="mainLayoutContent">
        <Outlet />
      </BCLayoutContent>
      <BCLayoutFooter className="mainLayoutFooter">
        <Footer />
      </BCLayoutFooter>
    </BCLayout>
  );
};

export default MainLayout;
