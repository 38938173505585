import { useEffect, useState } from "react";
import { TreeProps } from "antd";
import { BCButton, BCSelect, BCTree } from "@components/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectAllProducts,
  selectAllSectors,
  selectAllSegments,
  selectAllServices,
  useLazyGetCitiesByCountryQuery,
  useGetCountriesQuery,
} from "@services/index";
import { partnerFilter, selectCurrentPartnerFilters } from "@store/index";
import { useTranslation } from "react-i18next";

const FilterSide = (props: any) => {
  const {
    selectedCity,
    setSelectedCity,
    selectedCountry,
    setSelectedCountry,
    disabledCity,
    setDisabledCity,
    disabledCountry,
    setDisabledCountry,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allSectors = useSelector(selectAllSectors);
  const allServices = useSelector(selectAllServices);
  const allProducts = useSelector(selectAllProducts);
  const allSegments = useSelector(selectAllSegments);
  const currentFilters = useSelector(selectCurrentPartnerFilters);

  const [citiesOptions, setCitiesOptions] = useState<any>([]);
  const [countriesOptions, setCountriesOptions] = useState<any>([]);

  const {
    data: countries,
    isLoading: countriesIsLoading,
    isSuccess: countriesIsSuccess,
    isError: countriesIsError,
    error: countriesError,
  } = useGetCountriesQuery();

  const [
    getAllCitiesByCountry,
    {
      isLoading: getCitiesIsLoading,
      isSuccess: getCitiesIsSuccess,
      isError: getCitiesIsError,
      error: getCitiesError,
      data: cities,
    },
  ] = useLazyGetCitiesByCountryQuery();

  useEffect(() => {
    if (getCitiesIsSuccess && cities.length) {
      const newOptions = cities.map((city: any) => ({
        value: city.CityValue,
        label: city.CityText,
      }));

      setCitiesOptions(newOptions);
    }
  }, [getCitiesIsSuccess]);

  useEffect(() => {
    if (countriesIsSuccess && countries.length) {
      const newOptions = countries.map((country: any) => ({
        value: country.CountryValue,
        label: country.CountryText,
      }));

      setCountriesOptions(newOptions);
    }
  }, [countriesIsSuccess]);

  const handleCountryChange = (value: string | number, option: any) => {
    if (option != undefined) {
      setSelectedCountry(option);
      getAllCitiesByCountry({ countryId: option.value });
      setSelectedCity(null);
    } else {
      setSelectedCountry(null);
      setSelectedCity(null);
    }
  };

  const handleCityChange = (value: string | number, option: any) => {
    if (option != undefined) setSelectedCity(option);
    else setSelectedCity(null);
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeys: any, info: any) => {
    if (checkedKeys && location.pathname != "/partners/filter/all")
      navigate("/partners/filter/all");

    if (checkedKeys.includes("51")) {
      setDisabledCountry(false);
      if (selectedCountry) setDisabledCity(false);
    } else {
      setSelectedCountry(null);
      setSelectedCity(null);
      setDisabledCountry(true);
      setDisabledCity(true);
    }

    const filteredCheckedNodes = info.checkedNodesPositions
      ?.filter((item: any) => {
        if (!item.node.children || item.node.children?.length < 2) {
          item.node.position = item.pos;
          return true;
        }
        return false;
      })
      .map((item: any) => item.node);

    dispatch(partnerFilter({ filters: filteredCheckedNodes }));
  };

  const resetFilter = () => {
    dispatch(partnerFilter({ filters: [] }));
    setDisabledCountry(true);
    setDisabledCity(true);
    setSelectedCountry(null);
    setSelectedCity(null);
  };

  const treeData = [
    {
      title: <b>{t("000025")}</b>,
      checkable: false,
      key: "1",
      children: allSectors?.map((sector: any) => {
        return {
          title: sector.SECTORDEFINITION,
          key: sector.DOCUMENTID,
          value: sector.DOCUMENTID,
        };
      }),
    },
    {
      title: <b>{t("000068")}</b>,
      checkable: false,
      key: "2",
      children: allServices?.map((service: any) => {
        return {
          title: service.SERVICEDEFINITION,
          key: service.DOCUMENTID,
          value: service.DOCUMENTID,
        };
      }),
    },
    {
      title: <b>{t("000069")}</b>,
      checkable: false,
      key: "3",
      children: allProducts?.map((product: any) => {
        return {
          title: product.ProductName,
          key: product.key,
          value: product.key,
        };
      }),
    },
    {
      title: <b>{t("000070")}</b>,
      checkable: false,
      key: "4",
      children: allSegments?.map((segment: any) => {
        return { title: segment.Segment, key: segment.key, value: segment.key };
      }),
    },
    {
      title: <b>{t("000071")}</b>,
      checkable: false,
      key: "5",
      children: [
        {
          title: t("000071"),
          key: "51",
          children: [
            {
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <BCSelect
                    options={countriesOptions}
                    onChange={handleCountryChange}
                    disabled={disabledCountry}
                    style={{ width: "120px" }}
                    placeholder={t("000072")}
                    allowClear
                    value={selectedCountry?.label}
                  />
                  <BCSelect
                    options={citiesOptions}
                    onChange={handleCityChange}
                    disabled={disabledCity}
                    style={{ width: "120px" }}
                    placeholder={t("000073")}
                    allowClear
                    value={selectedCity?.label}
                  />
                </div>
              ),
              key: "511",
              checkable: false,
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <div className="partnersLayoutSiderTop">
        <span>{t("000052")}</span>
        <BCButton
          content={t("000053")}
          size="small"
          disabled={!currentFilters?.length}
          onClick={resetFilter}
        />
      </div>
      <BCTree
        checkedKeys={currentFilters?.map((i) => i.key)}
        treeData={treeData}
        onCheck={onCheck}
      />
    </>
  );
};

export default FilterSide;
