import { Tabs } from 'antd';
import { ITabsProps } from './entities';

const BCTabs = (props: ITabsProps) => {
    const { items, activeKey, onChange } = props;

    return (
        <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            centered={false}
            indicatorSize={(origin) => origin - 16}
            size='middle'
            type="line"
            activeKey={activeKey}
        />
    )
}

export default BCTabs