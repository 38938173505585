import { Flex } from "antd";
import { IFlexProps } from "./entities";

const BCFlex = (props: IFlexProps) => {
  const { flex, vertical, justify, align, gap, wrap, className, style } = props;

  return (
    <Flex
      flex={flex}
      vertical={vertical}
      justify={justify}
      align={align}
      gap={gap}
      wrap={wrap}
      className={className}
      style={style}
    >
      {props.children}
    </Flex>
  );
};

export default BCFlex;
