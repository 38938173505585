import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { IApplicationSliceState } from "./entities";

const initialState: IApplicationSliceState = {
  filters: [],
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    appFilter: (state, action: PayloadAction<IApplicationSliceState>) => {
      state.filters = action.payload.filters;
    },
  },
});

export const { appFilter } = applicationSlice.actions;
export default applicationSlice.reducer;
export const selectCurrentAppFilters = (state: RootState) =>
  state.application.filters;
