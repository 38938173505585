import { Tag } from 'antd';
import { ITagProps } from './entities';


const BCTag = (props: ITagProps) => {
    const { bordered, closable, closeIcon, color, icon, handleClose, tagKey, className, style } = props;

    return (
        <Tag
            key={tagKey}
            closable={closable}
            closeIcon={closeIcon}
            onClose={() => {
                if (handleClose && tagKey) {
                    handleClose(tagKey);
                }
            }}
            color={color}
            icon={icon}
            bordered={bordered}
            className={className}
            style={style}
        >
            {props.children}
        </Tag>
    )
}

export default BCTag