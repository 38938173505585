import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { IConsultancySliceState } from "./entities";

const initialState: IConsultancySliceState = {
  filters: [],
};

export const consultancySlice = createSlice({
  name: "consultancy",
  initialState,
  reducers: {
    consultancyFilter: (
      state,
      action: PayloadAction<IConsultancySliceState>
    ) => {
      state.filters = action.payload.filters;
    },
  },
});

export const { consultancyFilter } = consultancySlice.actions;
export default consultancySlice.reducer;
export const selectCurrentConsultancyFilters = (state: RootState) =>
  state.consultancy.filters;
