import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  BCButton,
  BCCarouselCardConsultancy,
  BCSkeleton,
} from "@components/index";
import { useGetFeaturedConsultancyQuery } from "@services/index";
import { consultancyFilter } from "@store/index";
import { IFeaturedConsultancyCarouselProps } from "./entities";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const FeaturedConsultancyCarousel = (
  props: IFeaturedConsultancyCarouselProps
) => {
  const { breakpoints } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    data: featuredConsultancies,
    isLoading: featuredConsultanciesisLoading,
    isSuccess: featuredConsultanciesisSuccess,
    isError: featuredConsultanciesisError,
    error: featuredConsultancieserror,
  } = useGetFeaturedConsultancyQuery();

  const showAllConsultancies = (consultancyType: string) => {
    if (consultancyType == "featuredConsultancies") {
      dispatch(
        consultancyFilter({
          filters: [
            { key: "01", position: "0-0-0", title: "Öne Çıkanlar", value: 1 },
          ],
        })
      );
    }
  };

  let content = null;

  if (featuredConsultanciesisLoading)
    content = [1, 2, 3, 4].map(() => {
      return (
        <SwiperSlide>
          <BCSkeleton
            loading={featuredConsultanciesisLoading}
            paragraph={{ rows: 4 }}
            round={false}
          />
        </SwiperSlide>
      );
    });
  if (featuredConsultanciesisSuccess && featuredConsultancies?.length)
    content = featuredConsultancies.map((consultancy: any) => {
      return (
        <SwiperSlide>
          <BCCarouselCardConsultancy
            cover={
              <img
                src={useBlobUrl(consultancy.logo)}
                className="swiperSlideItem"
              />
            }
            isFree
            consultancyname={consultancy.consultancyname}
            systemname={consultancy.systemname}
            comment={consultancy.comment}
            providerprofile={consultancy.providerprofile}
            average={consultancy.average}
            summaryinformation={consultancy.summaryinformation}
          />
        </SwiperSlide>
      );
    });

  return (
    <>
      <div className="featuredConsultancyCarouselTopContainer">
        <p>{t("000007")}</p>
        <BCButton
          onClick={() => showAllConsultancies("featuredConsultancies")}
          href="#/consultancies/filter/all"
          content={t("000006")}
          type="text"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
        />
      </div>
      <Swiper
        className="swiperFeaturedConsultancyCarouselContainer"
        modules={[Navigation, Pagination, A11y, Autoplay]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        autoplay={{
          delay: 90000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        breakpoints={breakpoints}
      >
        {content}
        <div className="navigationContainer">
          <span className="swiper-button-prev" />
          <span className="swiper-button-next" />
        </div>
      </Swiper>
    </>
  );
};

export default FeaturedConsultancyCarousel;
