import { Link } from 'react-router-dom';
import { Avatar, Card } from 'antd';
import { FaMessage, FaStar } from "react-icons/fa6";
import { BCCard, BCCol, BCFlex, BCRow, BCSpace } from '..'
import { ICardApplicationProps } from './entities';
import './assets/style.scss'


const BCCardApplication = (props: ICardApplicationProps) => {
    const { appname, avatar, summaryinformation, producer, average, comment, navigationUrl } = props;

    return (
        <Link to={`${navigationUrl}`} className='cardApplicationContainer'>
            <BCCard
                hoverable
                size='default'
                bodyStyle={{ padding: "16px 14px 13px 14px" }}
            >
                <BCFlex vertical justify='space-between' gap={16}>
                    <BCRow>
                        <BCCol>
                            <Card.Meta
                                avatar={<Avatar size={80} src={avatar} shape='square' />}
                                title={appname}
                                description={summaryinformation}
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                            />
                        </BCCol>
                    </BCRow>
                    <BCRow className='cardBottom' justify={'space-between'}>
                        <BCCol>
                            {producer}
                        </BCCol>
                        <BCSpace size={'large'}>
                            {average ? <BCCol className='reviewContainer'>
                                <span className='average'><FaStar color="#9D9D9D" size="16px" /></span>
                                <span>{average}</span>
                            </BCCol> : null}
                            {comment ? <BCCol className='reviewContainer'>
                                <span className='comment'><FaMessage color="#9D9D9D" size="16px" /></span>
                                <span>{comment}</span>
                            </BCCol> : null}
                        </BCSpace>
                    </BCRow>
                </BCFlex>
            </BCCard>
        </Link>
    )
}

export default BCCardApplication