const deployAgents = {
	development: {
		deployAgent1: 'ac64f047-d8d2-4da0-83c8-ac611ba37f46',
		deployAgent2: '6c56fe32-bba5-4c93-8c0b-d3a0ee8537ec' // fatura süreci için kullanılan deployagent (şuan için bu projede kullanılmıyor)
	},
	production: {
		deployAgent1: 'fc3399b5-1d20-46da-9c9f-3901b15e3917', // "PPRODUCTS" içeren endpoint'lerde kullanılır.
		deployAgent2: 'e654ccad-24ba-4c81-aa12-11d7739e4d2d', // "BC_Cari_Kayit" içeren endpoint'lerde kullanılır.
		deployAgent3: '7635efc1-89b4-4be1-a774-c33d78946928' // fatura süreci için kullanılan deployagent (şuan için bu projede kullanılmıyor)
	}
};

const baseQueries = {
	development: {
		baseQuery: {
			baseUrl: 'https://preportal.synergynow.io/api',
			bimserEncryptedData:
				'QQz2MlNj/xVZPFQLQSyIknQ1sTr2KX8KV+grlMUgvJ59/nY07mC/axzvC02baUFMO3pbze7xPAgxKSv/7JZaEXxsFY2SEI6MrHhXQQ0cjuriFY1h90tR6OEDb93+rIas4Q1aodp0aDrahHwLlIYdq+ZO+Pjd8nxA1/gbhXIIyKQ1fq+s1bulM1628VFhsjfG',
			bimserFootPrint: 'synergynow.io'
		},
		baseQueryBD: {
			baseUrl: 'https://preportal-bd.synergynow.io/',
			bimserEncryptedData:
				'QQz2MlNj/xVZPFQLQSyIknQ1sTr2KX8KV+grlMUgvJ59/nY07mC/axzvC02baUFMO3pbze7xPAgxKSv/7JZaEXxsFY2SEI6MrHhXQQ0cjuriFY1h90tR6OEDb93+rIas4Q1aodp0aDrahHwLlIYdq+ZO+Pjd8nxA1/gbhXIIyKQ1fq+s1bulM1628VFhsjfG',
			bimserFootPrint: 'synergynow.io'
		}
	},
	production: {
		baseQuery: {
			baseUrl: 'https://portal.synergynow.io/api',
			bimserEncryptedData:
				'bApAinqcuGQZJx4S0pNzqp19gKuM0peDGldJt/YTZELDQoESVtzGZcXE+t/XwLTpNuP99I+604ARWpPox79YzJ3SPmMG5kUOUHz8qW6UTO4aK0Cdu09cSWHPDH9Fs7iywskAnkgL71efevUdIBPO7bnJwY4hgCuIoDpozPZjPhADBowTJnr0/KMMWug+pY2W',
			bimserFootPrint: 'synergynow.io'
		},
		baseQueryBD: {
			baseUrl: 'https://portal-bd.synergynow.io/',
			bimserEncryptedData:
				'bApAinqcuGQZJx4S0pNzqp19gKuM0peDGldJt/YTZELDQoESVtzGZcXE+t/XwLTpNuP99I+604ARWpPox79YzJ3SPmMG5kUOUHz8qW6UTO4aK0Cdu09cSWHPDH9Fs7iywskAnkgL71efevUdIBPO7bnJwY4hgCuIoDpozPZjPhADBowTJnr0/KMMWug+pY2W',
			bimserFootPrint: 'synergynow.io'
		}
	}
};

const hostname = window.location.hostname;
const subdomain = hostname.split('.')[0];

const getBaseQuery = () => {
	switch (subdomain) {
		case 'store':
			return baseQueries['production'];
		case 'prestore':
			return baseQueries['development'];
		default:
			return baseQueries['development'];
	}
};

const getDeployAgent = (url: string) => {
	const extractedUrl = url.split('/')[3];

	switch (subdomain) {
		case 'store':
			return extractedUrl.startsWith('BC_Cari_Kayit')
				? deployAgents['production'].deployAgent2
				: deployAgents['production'].deployAgent1;

		case 'prestore':
			return deployAgents['development'].deployAgent1;

		default:
			return deployAgents['development'].deployAgent1;
	}
};

export { getBaseQuery, getDeployAgent };
