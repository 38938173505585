import { ServiceList, baseApi } from "@services/index"
import {
    ICreateAppIntroductionRequest,
    ICreateAppIntroductionResponse,
    ICreateCollectRequest,
    ICreateCollectResponse,
    ICreateFavAppsRequest,
    ICreateFavAppsResponse,
    ICreateSubscriptionRequest,
    ICreateSubscriptionResponse,
    IDeleteFavAppsRequest,
    IDeleteFavAppsResponse,
    IGetAppDetailRequest,
    IGetAppDetailResponse,
    IGetAppsRequest,
    IGetAppsResponse,
    IGetEvaluationBySystemNameResponse,
    IGetFavAppsListResponse,
    IGetFeaturedAppsResponse,
    IGetOpportunityAppsResponse,
    IGetTopTenAppsResponse
} from "./entities"


export const applicationApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        GetApps: builder.query<any, IGetAppsRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.GetApps,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: IGetAppsResponse) => response.result,
        }),
        GetAppDetail: builder.query<any, IGetAppDetailRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.GetAppDetail,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: IGetAppDetailResponse) => response.result,
            providesTags: ['Application']
        }),
        GetFeaturedApps: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetFeaturedApps,
                method: 'GET',
            }),
            transformResponse: (response: IGetFeaturedAppsResponse) => response.result,
        }),
        GetTopTenApps: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetTopTenApps,
                method: 'GET',
            }),
            transformResponse: (response: IGetTopTenAppsResponse) => response.result,
        }),
        GetOpportunityApps: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetOpportunityApps,
                method: 'GET',
            }),
            transformResponse: (response: IGetOpportunityAppsResponse) => response.result,
        }),
        GetEvaluationBySystemName: builder.query<any, string>({
            query: (systemname) => ({
                url: `${ServiceList.baseQuery2.GetEvaluationBySystemName}${systemname}`,
                method: 'GET',
            }),
            transformResponse: (response: IGetEvaluationBySystemNameResponse) => response.result,
        }),
        CreateSubscription: builder.mutation<any, ICreateSubscriptionRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateSubscription,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: ICreateSubscriptionResponse) => response.result,
            invalidatesTags: ['Application']
        }),
        CreateCollect: builder.mutation<any, ICreateCollectRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateCollect,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: ICreateCollectResponse) => response.result,
            invalidatesTags: ['Application']
        }),
        CreateAppIntroduction: builder.mutation<any, ICreateAppIntroductionRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateAppIntroduction,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: ICreateAppIntroductionResponse) => response.result,
            invalidatesTags: ['Application']
        }),
        CreateFavApps: builder.mutation<any, ICreateFavAppsRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateFavApps,
                method: 'POST',
                body: parameters
            }),
            transformResponse: (response: ICreateFavAppsResponse) => response,
            invalidatesTags: ['Application']
        }),
        DeleteFavApps: builder.mutation<any, IDeleteFavAppsRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.DeleteFavApps,
                method: 'POST',
                body: parameters
            }),
            transformResponse: (response: IDeleteFavAppsResponse) => response,
            invalidatesTags: ['Application']
        }),
        GetFavAppsList: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetFavAppsList,
                method: 'GET',
            }),
            transformResponse: (response: IGetFavAppsListResponse) => response.result,
            providesTags: ['Application']
        }),
    })
})

export const {
    useGetAppsQuery,
    useLazyGetAppsQuery,
    useLazyGetAppDetailQuery,
    useGetFeaturedAppsQuery,
    useGetTopTenAppsQuery,
    useGetOpportunityAppsQuery,
    useLazyGetEvaluationBySystemNameQuery,
    useCreateSubscriptionMutation,
    useCreateCollectMutation,
    useCreateAppIntroductionMutation,
    useCreateFavAppsMutation,
    useDeleteFavAppsMutation,
    useLazyGetFavAppsListQuery
} = applicationApi 