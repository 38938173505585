import { Select } from "antd";
import { ISelectProps } from "./entities";

const BCSelect = (props: ISelectProps) => {
  const {
    allowClear,
    defaultValue,
    disabled,
    loading,
    maxTagCount,
    mode,
    onChange,
    onSelect,
    onSearch,
    options,
    optionRender,
    filterOption,
    placeholder,
    showSearch,
    status,
    suffixIcon,
    value,
    style,
    dropdownStyle,
  } = props;

  return (
    <Select
      allowClear={allowClear}
      defaultValue={defaultValue}
      disabled={disabled}
      loading={loading}
      maxTagCount={maxTagCount}
      mode={mode}
      onChange={onChange}
      onSelect={onSelect}
      onSearch={onSearch}
      options={options}
      optionRender={optionRender}
      filterOption={filterOption}
      placeholder={placeholder}
      showSearch={showSearch}
      status={status}
      suffixIcon={suffixIcon}
      value={value}
      style={style}
      dropdownStyle={dropdownStyle}
    />
  );
};

export default BCSelect;
