import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FeaturedAppCarousel, PageTopCarousel } from "@features/index";
import {
  BCLayout,
  BCLayoutContent,
  BCLayoutSider,
  BCButton,
  BCCardApplication,
  BCSkeleton,
  BCDrawer,
} from "@components/index";
import {
  useGetOpportunityAppsQuery,
  useGetTopTenAppsQuery,
} from "@services/index";
import { appFilter, selectCurrentAppFilters } from "@store/index";
import FilterContent from "./components/filterContent";
import FilterSide from "./components/filterSide";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const Applications = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentFilters = useSelector(selectCurrentAppFilters);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isBreakpoint, setIsBreakpoint] = useState<boolean>(false);
  const [disabledInputNumber, setDisabledInputNumber] = useState<boolean>(true);
  const [disabledRate, setDisabledRate] = useState<boolean>(true);
  const [minFee, setMinFee] = useState<number>(0);
  const [maxFee, setMaxFee] = useState<number>(50000);
  const [rate, setRate] = useState<number>(0);
  const [feeFilter, setFeeFilter] = useState<boolean>(false);

  const {
    data: topTenApps,
    isLoading: topTenAppsisLoading,
    isSuccess: topTenAppsisSuccess,
    isError: topTenAppsisError,
    error: topTenAppserror,
  } = useGetTopTenAppsQuery();

  const {
    data: opportunityApps,
    isLoading: opportunityAppsisLoading,
    isSuccess: opportunityAppsisSuccess,
    isError: opportunityAppsisError,
    error: opportunityAppserror,
  } = useGetOpportunityAppsQuery();

  const breakpoints = {
    700: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    750: {
      slidesPerView: 1.3,
      spaceBetween: 10,
    },
    800: {
      slidesPerView: 1.5,
      spaceBetween: 10,
    },
    850: {
      slidesPerView: 1.7,
      spaceBetween: 10,
    },
    960: {
      slidesPerView: 1.9,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 2.1,
      spaceBetween: 10,
    },
    1124: {
      slidesPerView: 2.4,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1280: {
      slidesPerView: 3.5,
      spaceBetween: 10,
    },
  };

  const resetFilter = () => {
    dispatch(appFilter({ filters: [] }));
    setDisabledInputNumber(true);
    setDisabledRate(true);
    setRate(0);
  };

  useEffect(() => {
    if (location.pathname == "/applications") resetFilter();
  }, [location]);

  const handleTagClose = (tagKey: string) => {
    const filteredTags = currentFilters?.filter(
      (filter) => filter.key !== tagKey
    );
    dispatch(appFilter({ filters: filteredTags }));

    if (tagKey == "41") setDisabledInputNumber(true);
  };

  const showAllApps = (applicationType: string) => {
    if (applicationType == "topTenApps") {
      dispatch(
        appFilter({
          filters: [
            {
              key: "02",
              position: "0-0-1",
              title: "Yeni Uygulamalar",
              value: 2,
            },
          ],
        })
      );
    }
    if (applicationType == "opportunityApps") {
      dispatch(
        appFilter({
          filters: [
            { key: "03", position: "0-0-2", title: "Fırsatlar", value: 3 },
          ],
        })
      );
    }
  };

  const onCollapse = (value: boolean, type: "clickTrigger" | "responsive") => {
    setIsBreakpoint(value);
    setCollapsed(value);
  };

  let topTenAppsContent = null;
  let opportunityAppsContent = null;

  if (topTenAppsisLoading)
    topTenAppsContent = [0, 1, 2].map(() => {
      return (
        <BCSkeleton
          loading={topTenAppsisLoading}
          paragraph={{ rows: 2 }}
          round={false}
          style={{ width: "350px" }}
        />
      );
    });

  if (opportunityAppsisLoading)
    opportunityAppsContent = [0, 1, 2].map(() => {
      return (
        <BCSkeleton
          loading={opportunityAppsisLoading}
          paragraph={{ rows: 2 }}
          round={false}
          style={{ width: "350px" }}
        />
      );
    });

  if (topTenAppsisSuccess && topTenApps?.length)
    topTenAppsContent = topTenApps.slice(0, 9).map((app: any) => {
      return (
        <BCCardApplication
          appname={app.appname}
          average={app.average}
          comment={app.comment}
          producer={app.producer}
          summaryinformation={app.summaryinformation}
          navigationUrl={`${app.systemname}/detail`}
          avatar={useBlobUrl(app.icon)}
        />
      );
    });

  if (opportunityAppsisSuccess && opportunityApps?.length)
    opportunityAppsContent = opportunityApps.slice(0, 9).map((app: any) => {
      return (
        <BCCardApplication
          appname={app.appname}
          average={app.average}
          comment={app.comment}
          producer={app.producer}
          summaryinformation={app.summaryinformation}
          navigationUrl={`${app.systemname}/detail`}
          avatar={useBlobUrl(app.icon)}
        />
      );
    });

  return (
    <>
      <PageTopCarousel />
      <BCLayout>
        {collapsed ? (
          <BCDrawer>
            <FilterSide
              rate={rate}
              setRate={setRate}
              setMinFee={setMinFee}
              setMaxFee={setMaxFee}
              feeFilter={feeFilter}
              setFeeFilter={setFeeFilter}
              disabledInputNumber={disabledInputNumber}
              setDisabledInputNumber={setDisabledInputNumber}
              disabledRate={disabledRate}
              setDisabledRate={setDisabledRate}
            />
          </BCDrawer>
        ) : null}
        <BCLayoutSider
          className="applicationsLayoutSider"
          style={{ margin: collapsed ? "0" : "20px 0 0 3%" }}
          collapsed={collapsed}
          collapsedWidth={"0"}
          onCollapse={onCollapse}
        >
          <FilterSide
            rate={rate}
            setRate={setRate}
            setMinFee={setMinFee}
            setMaxFee={setMaxFee}
            feeFilter={feeFilter}
            setFeeFilter={setFeeFilter}
            disabledInputNumber={disabledInputNumber}
            setDisabledInputNumber={setDisabledInputNumber}
            disabledRate={disabledRate}
            setDisabledRate={setDisabledRate}
          />
        </BCLayoutSider>
        <BCLayoutContent className="applicationsLayoutContent">
          {location.pathname.startsWith("/applications/filter/") ? (
            <FilterContent
              handleTagClose={handleTagClose}
              minFee={minFee}
              maxFee={maxFee}
              rate={rate}
              setRate={setRate}
              feeFilter={feeFilter}
            />
          ) : (
            <>
              <div
                style={{ paddingRight: isBreakpoint ? "3%" : "3%" }}
                className="applicationsFeaturedAppCarousel"
              >
                <FeaturedAppCarousel breakpoints={breakpoints} />
              </div>
              <div className="applicationsContainer">
                <div>
                  <div className="applicationsTopContainer">
                    <p>{t("000008")}</p>
                    <BCButton
                      onClick={() => showAllApps("topTenApps")}
                      href="#/applications/filter/all"
                      content={t("000006")}
                      type="text"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                    />
                  </div>
                  <div className="applicationsContentContainer">
                    {topTenAppsContent}
                  </div>
                </div>
                <div>
                  <div className="applicationsTopContainer">
                    <p>{t("000009")}</p>
                    <BCButton
                      onClick={() => showAllApps("opportunityApps")}
                      href="#/applications/filter/all"
                      content={t("000006")}
                      type="text"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                    />
                  </div>
                  <div className="applicationsContentContainer">
                    {opportunityAppsContent}
                  </div>
                </div>
              </div>
            </>
          )}
        </BCLayoutContent>
      </BCLayout>
    </>
  );
};

export default Applications;
