import { BCButton, BCCard, BCMeta } from "..";
import { ICardMetaProps } from "./entities";
import "./assets/style.scss";

const BCCardMeta = (props: ICardMetaProps) => {
  const {
    title,
    description,
    avatar,
    className,
    style,
    buttonText,
    buttonType,
  } = props;

  return (
    <BCCard
      className="CardMetaContainer"
      bodyStyle={{ display: "flex", flexDirection: "column" }}
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <BCMeta title={title} avatar={avatar} description={description} />
      {buttonText || buttonType ? (
        <BCButton
          content={buttonText}
          type={buttonType}
          style={{ width: "116px", margin: "10px 0 0 18px", padding: "0" }}
        />
      ) : null}
    </BCCard>
  );
};

export default BCCardMeta;
