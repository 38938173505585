import { HashRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import Router from "../router/Router";
import { ModalManager } from "@utils/index";
import { store } from "@store/index";
import { ErrorBoundary } from "@layouts/index";

const BCProvider = () => {
  return (
    <HashRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            components: {
              Form: {
                labelHeight: 16,
              },
              Segmented: {
                itemSelectedBg: "#00104E",
                itemSelectedColor: "#fff",
              },
            },
            token: {
              // borderRadius: 0,
            },
          }}
        >
          <ErrorBoundary>
            <ModalManager />
            <Routes>
              <Route path="/*" element={<Router />} />
            </Routes>
          </ErrorBoundary>
        </ConfigProvider>
      </Provider>
    </HashRouter>
  );
};

export default BCProvider;
