import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StarFilled, UserOutlined } from "@ant-design/icons";
import { BCCol, BCRow, BCProgress, BCRate, BCAvatar } from "@components/index";
import { IReviewsTabProps } from "./entities";

const ReviewsTab = ({ evaluations }: IReviewsTabProps) => {
  const { t } = useTranslation();
  const [average, setAverage] = useState(0);
  const [rates, setRates] = useState([0, 0, 0, 0, 0]);

  useEffect(() => {
    calculateRate();
    calculateRatingsPercentages();
  }, [evaluations]);

  const calculateRate = () => {
    let totalPoints = 0;
    let totalRatings = 0;

    evaluations?.forEach((evaluation: any) => {
      let point: any = evaluation.POINT;
      if (point > 5) point = 5;

      totalPoints += point;
      totalRatings += 1;
    });

    const average = totalRatings !== 0 ? totalPoints / totalRatings : 0;
    const roundedAverage = Math.ceil(average * 10) / 10;

    setAverage(Number(roundedAverage));
  };

  const calculateRatingsPercentages = () => {
    const totalRatings = evaluations.length;
    const ratingCounts = [0, 0, 0, 0, 0]; // [1, 2, 3, 4, 5] puanlar için sayma dizisi

    if (!totalRatings) {
      setRates(ratingCounts);
    } else {
      // Puanları sayma
      evaluations.forEach((evaluation: any) => {
        if (evaluation.POINT >= 1 && evaluation.POINT <= 5) {
          ratingCounts[evaluation.POINT - 1]++;
        }
      });

      // Yüzdeleri hesaplama
      const ratingPercentages = ratingCounts.map(
        (count) => (count / totalRatings) * 100
      );

      setRates(ratingPercentages);
    }
  };

  const roundedAverage = () => {
    if (average > 0 && average < 1) return 0.5;
    else if (average > 1 && average < 2) return 1.5;
    else if (average > 2 && average < 3) return 2.5;
    else if (average > 3 && average < 4) return 3.5;
    else if (average > 4 && average < 5) return 4.5;
    else return average;
  };

  return (
    <div style={{ paddingRight: "10px" }}>
      <BCRow align={"middle"}>
        <BCCol
          xs={24}
          sm={24}
          md={6}
          lg={5}
          xl={4}
          xxl={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#e2dede",
            borderRadius: "10px",
            padding: "14px 0",
            marginRight: "30px",
          }}
        >
          <p style={{ fontSize: "48px", fontWeight: "700" }}>{average}</p>
          <BCRate
            allowHalf
            value={roundedAverage()}
            disabled
            style={{ display: "flex", fontSize: "18px", color: "#FFAC42" }}
          />
          <p>
            {evaluations?.length} {t("000041")}
          </p>
        </BCCol>
        <BCCol
          style={{ paddingLeft: "30px" }}
          xs={24}
          sm={24}
          md={16}
          lg={17}
          xl={18}
          xxl={18}
        >
          <BCProgress
            percent={Number(rates[4])}
            showInfo={true}
            format={() => (
              <span style={{ position: "absolute", left: -30, top: 5 }}>
                5 <StarFilled />{" "}
              </span>
            )}
          />
          <BCProgress
            percent={Number(rates[3])}
            showInfo={true}
            format={() => (
              <span style={{ position: "absolute", left: -30, top: 5 }}>
                4 <StarFilled />{" "}
              </span>
            )}
          />
          <BCProgress
            percent={Number(rates[2])}
            showInfo={true}
            format={() => (
              <span style={{ position: "absolute", left: -30, top: 5 }}>
                3 <StarFilled />{" "}
              </span>
            )}
          />
          <BCProgress
            percent={Number(rates[1])}
            showInfo={true}
            format={() => (
              <span style={{ position: "absolute", left: -30, top: 5 }}>
                2 <StarFilled />{" "}
              </span>
            )}
          />
          <BCProgress
            percent={Number(rates[0])}
            showInfo={true}
            format={() => (
              <span style={{ position: "absolute", left: -27, top: 5 }}>
                1 <StarFilled />{" "}
              </span>
            )}
          />
        </BCCol>
      </BCRow>
      <BCRow>
        {evaluations?.map((evaluation: any) => {
          const createdAt = evaluation.CREATEDDATE
            ? new Intl.DateTimeFormat("tr", {
                timeStyle: "short",
                dateStyle: "medium",
              }).format(new Date(evaluation.CREATEDDATE))
            : null;

          const rateValue = evaluation.POINT > 5 ? 5 : evaluation.POINT;

          return (
            <BCCol span={24} style={{ margin: "40px 0 0 0" }}>
              <div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  <BCAvatar size={44} icon={<UserOutlined />} />
                  <span style={{ fontWeight: "600" }}>
                    {evaluation.EVALUATOR}
                  </span>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <BCRate
                    value={rateValue}
                    disabled
                    style={{
                      display: "flex",
                      fontSize: "12px",
                      color: "#FFAC42",
                    }}
                  />
                  <time style={{ fontSize: "12px" }}>{createdAt}</time>
                </div>
                <div>{evaluation.EXPLANATION}</div>
              </div>
            </BCCol>
          );
        })}
      </BCRow>
    </div>
  );
};

export default ReviewsTab;
