import { Row } from 'antd';
import { IRowProps } from './entities';

const BCRow = (props: IRowProps) => {
    const { gutter, justify, align, wrap, className, style } = props;

    return (
        <Row
            gutter={gutter}
            justify={justify}
            align={align}
            wrap={wrap}
            className={className}
            style={style}
        >
            {props.children}
        </Row>
    )
}

export default BCRow