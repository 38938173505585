import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeProps } from "antd";
import { BCButton, BCInputNumber, BCRate, BCTree } from "@components/index";
import { useDispatch, useSelector } from "react-redux";
import { appFilter, selectCurrentAppFilters } from "@store/index";
import { selectAllCategories, selectAllSectors } from "@services/index";
import { useNavigate } from "react-router-dom";
import { BsCaretRightFill } from "react-icons/bs";

const FilterSide = (props: any) => {
  const {
    rate,
    setRate,
    setMinFee,
    setMaxFee,
    feeFilter,
    setFeeFilter,
    disabledInputNumber,
    setDisabledInputNumber,
    disabledRate,
    setDisabledRate,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentFilters = useSelector(selectCurrentAppFilters);
  const allCategories = useSelector(selectAllCategories);
  const allSectors = useSelector(selectAllSectors);

  const [disabledFeeButton, setDisabledFeeButton] = useState<boolean>(false);
  const [currentMinFee, setCurrentMinFee] = useState<number>(0);
  const [currentMaxFee, setCurrentMaxFee] = useState<number>(50000);

  const handleMinFeeChange = (value: string | number | null) => {
    setDisabledFeeButton(true);
    setCurrentMinFee(value as number);
  };

  const handleMaxFeeChange = (value: string | number | null) => {
    setDisabledFeeButton(true);
    setCurrentMaxFee(value as number);
  };

  const handleFeeClick = () => {
    setMinFee(currentMinFee);
    setMaxFee(currentMaxFee);
    setFeeFilter(!feeFilter);
    setDisabledFeeButton(false);
  };

  const resetFilter = () => {
    dispatch(appFilter({ filters: [] }));
    setDisabledInputNumber(true);
    setDisabledRate(true);
    setRate(0);
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeys: any, info: any) => {
    if (checkedKeys && location.pathname != "/applications/filter/all")
      navigate("/applications/filter/all");

    setDisabledInputNumber(!checkedKeys.includes("41"));
    setDisabledRate(!checkedKeys.includes("61"));

    const filteredCheckedNodes = info.checkedNodesPositions
      ?.filter((item: any) => {
        if (!item.node.children || item.node.children?.length < 2) {
          item.node.position = item.pos;
          return true;
        }
        return false;
      })
      .map((item: any) => item.node);
    dispatch(appFilter({ filters: filteredCheckedNodes }));
  };

  const treeData = [
    {
      title: <b>{t("000054")}</b>,
      checkable: false,
      key: "0",
      children: [
        { title: t("000007"), key: "01", value: 1 },
        { title: t("000008"), key: "02", value: 2 },
        { title: t("000009"), key: "03", value: 3 },
      ],
    },
    {
      title: <b>{t("000025")}</b>,
      checkable: false,
      key: "1",
      children: allSectors?.map((sector: any) => {
        return {
          title: sector.SECTORDEFINITION,
          key: sector.DOCUMENTID,
          value: sector.DOCUMENTID,
        };
      }),
    },
    {
      title: <b>{t("000024")}</b>,
      checkable: false,
      key: "2",
      children: allCategories?.map((category: any) => {
        return {
          title: category.DEFINITION,
          key: category.DOCUMENTID,
          value: category.DOCUMENTID,
        };
      }),
    },
    {
      title: <b>{t("000055")}</b>,
      checkable: false,
      key: "3",
      children: [
        { title: "Bimser", key: "31", value: "BIMSER" },
        { title: t("000056"), key: "32", value: "SOLUTIONPARTNER" },
      ],
    },
    {
      title: <b>{t("000057")}</b>,
      checkable: false,
      key: "4",
      children: [
        {
          title: t("000058"),
          key: "41",
          value: 2,
          children: [
            {
              title: (
                <div>
                  <BCInputNumber
                    onChange={handleMinFeeChange}
                    max={currentMaxFee}
                    min={0}
                    placeholder="0"
                    disabled={disabledInputNumber}
                    style={{ width: "50px" }}
                  />
                  -
                  <BCInputNumber
                    onChange={handleMaxFeeChange}
                    min={currentMinFee}
                    placeholder="50000"
                    disabled={disabledInputNumber}
                    style={{ width: "50px" }}
                  />
                  <BCButton
                    onClick={handleFeeClick}
                    style={{
                      padding: 0,
                      height: "100%",
                      display: disabledFeeButton ? "inline-block" : "none",
                    }}
                    icon={<BsCaretRightFill />}
                  />
                </div>
              ),
              key: "411",
              checkable: false,
            },
          ],
        },
        { title: t("000059"), key: "42", value: 1 },
      ],
    },
    {
      title: <b>{t("000060")}</b>,
      checkable: false,
      key: "5",
      children: [
        { title: t("000061"), key: "51", value: 1 },
        { title: t("000062"), key: "52", value: 1 },
      ],
    },
    {
      title: <b>{t("000063")} (min.)</b>,
      checkable: false,
      key: "6",
      children: [
        {
          title: t("000063"),
          key: "61",
          value: 1,
          children: [
            {
              title: (
                <BCRate
                  disabled={disabledRate}
                  value={rate}
                  setValue={setRate}
                  defaultValue={0}
                  style={{ fontSize: "16px" }}
                  allowHalf={false}
                />
              ),
              key: "611",
              checkable: false,
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <div className="applicationsLayoutSiderTop">
        <span>{t("000052")}</span>
        <BCButton
          content={t("000053")}
          size="small"
          disabled={!currentFilters?.length}
          onClick={resetFilter}
        />
      </div>
      <BCTree
        checkedKeys={currentFilters?.map((i) => i.key)}
        treeData={treeData}
        onCheck={onCheck}
      />
    </>
  );
};

export default FilterSide;
