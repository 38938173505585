import { Layout } from 'antd';
import { ILayoutProps } from './entities';


const BCLayout = (props: ILayoutProps) => {
    const { style, hasSider, className } = props;
    
    return (
        <Layout
            hasSider={hasSider}
            style={{ ...style, backgroundColor: "transparent" }}
            className={className}
        >
            {props.children}
        </Layout>
    )
}

export default BCLayout