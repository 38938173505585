import { InputNumber } from 'antd';
import { IInputNumberProps } from './entities';

const BCInputNumber = (props: IInputNumberProps) => {
    const { onChange, disabled, placeholder, min, max, value, defaultValue, style, className } = props;

    return (
        <InputNumber
            min={min}
            max={max}
            onChange={onChange}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            controls
            size='small'
            placeholder={placeholder}
            style={style}
            className={className}
        />
    )
}

export default BCInputNumber