import { logOut, RootState, setCredentials } from "@store/index";
import { ServiceList, baseApi, getLoginError } from "@services/index";
import BCNotification from "@components/BCNotification";
import {
  IGetCustomerInfoResponse,
  ILoginRequest,
  ILoginResponse,
  ISendResetPasswordMailRequest,
  ITenantRegistrationRequest,
  ITenantRegistrationResponse,
} from "./entities";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    Login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (credentials) => ({
        url: ServiceList.baseQuery1.Login,
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.result?.token) {
            dispatch(setCredentials({ token: data.result.token }));

            let rememberMe = localStorage.getItem("rememberMe");

            if (rememberMe == "1") {
              localStorage.setItem("token", data.result.token);
            } else {
              sessionStorage.setItem("token", data.result.token);
            }
          }
        } catch (error: any) {
          const { message, showCaptcha } = getLoginError(
            error?.error?.data?.exception
          );
          BCNotification({ type: "error", message: message });
        }
      },
    }),
    TenantRegistration: builder.mutation<
      ITenantRegistrationResponse,
      ITenantRegistrationRequest
    >({
      query: (credentials) => ({
        url: ServiceList.baseQuery2.TenantRegistration,
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error: any) {
          const { message, showCaptcha } = getLoginError(
            error?.error?.data?.exception
          );
          BCNotification({ type: "error", message: message });
        }
      },
    }),
    SendResetPasswordMail: builder.mutation<any, ISendResetPasswordMailRequest>(
      {
        query: (parameters) => ({
          url: ServiceList.baseQuery1.SendResetPasswordMail,
          method: "POST",
          body: parameters,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
          } catch (error: any) {}
        },
      }
    ),
    GetCustomerInfo: builder.query<any, void>({
      query: () => ({
        url: ServiceList.baseQuery2.GetCustomerInfo,
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        const token = (getState() as RootState).auth.token;

        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(setCredentials({ token: token, user: data }));
            localStorage.setItem("customer", JSON.stringify(data));
          }
        } catch (error: any) {}
      },
      keepUnusedDataFor: 3600,
      transformResponse: (response: IGetCustomerInfoResponse) =>
        response.result,
    }),
  }),
});

export const {
  useLoginMutation,
  useSendResetPasswordMailMutation,
  useTenantRegistrationMutation,
  useLazyGetCustomerInfoQuery,
} = authApi;
