import { Input } from 'antd';
import { IInputProps } from './entities';
import './assets/style.scss'


const BCInput = (props: IInputProps) => {
    const { placeholder, onChange, onBlur, onFocus, value, maxLength, showCount, status, prefix, suffix, addonAfter, addonBefore, bordered, allowClear, size, style, className } = props;


    return (
        <Input
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            maxLength={maxLength}
            showCount={showCount}
            status={status}
            prefix={prefix}
            suffix={suffix}
            bordered={bordered}
            allowClear={allowClear}
            size={size}
            style={style}
            className={className}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
        />
    )
}

export default BCInput