import { useSelector } from 'react-redux';
import { selectCurrentToken } from '@store/index';

export const useAuth = () => {
    const tokenFromRedux: string | null = useSelector(selectCurrentToken);
    const tokenFromSessionStorage: string | null = sessionStorage.getItem('token');
    const tokenFromLocalStorage: string | null = localStorage.getItem('token');

   return tokenFromRedux || tokenFromSessionStorage || tokenFromLocalStorage
};
