import { Dropdown } from 'antd';
import { IDropdownProps } from './entities';

const BCDropdown = (props: IDropdownProps) => {
    const { items, onClick, selectable, defaultSelectedKeys, overlayClassName, className } = props;

    return (
        <Dropdown
            menu={{
                items,
                onClick,
                selectable,
                defaultSelectedKeys,
            }}
            placement="bottomCenter"
            arrow={true}
            trigger={['click']}
            overlayClassName={overlayClassName}
            className={className}
        >
            {props.children}
        </Dropdown>
    )
}

export default BCDropdown