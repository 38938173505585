import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentPartnerFilters } from '@store/index';
import { useLazyGetPartnersQuery } from '@services/index';
import { BCCardPartner, BCEmpty, BCSkeleton, BCTag } from '@components/index';
import { IPartnerFilterProps } from './entities';
import './assets/style.scss';

const FilterContent = (props: IPartnerFilterProps) => {
	const { handleTagClose, selectedCountry, selectedCity } = props;

	const { t } = useTranslation();
	const filters = useSelector(selectCurrentPartnerFilters);

	const [
		getPartners,
		{
			isLoading: getPartnersisLoading,
			isSuccess: getPartnersisSuccess,
			isError: getPartnersisErrors,
			error: getPartnerserror,
			data: filteredPartners
		}
	] = useLazyGetPartnersQuery();

	const filterPartners = () => {
		try {
			const SECTOR = filters
				?.filter(item => item.position.startsWith('0-0'))
				.map(item => item.value);
			const SERVICES = filters
				?.filter(item => item.position.startsWith('0-1'))
				.map(item => item.value);
			const PRODUCTS = filters
				?.filter(item => item.position.startsWith('0-2'))
				.map((item: any) => Number(item.value.slice(0, 1)));
			const SEGMENTS = filters
				?.filter(item => item.position.startsWith('0-3'))
				.map((item: any) => Number(item.value.slice(0, 1)));

			const requestData = {
				name: '',
				country: selectedCountry?.value || null,
				city: selectedCity?.value || null,
				industryInformation: SECTOR?.length ? SECTOR : null,
				services: SERVICES?.length ? SERVICES : null,
				supportGivenProducts: PRODUCTS?.length ? PRODUCTS : null,
				segments: SEGMENTS?.length ? SEGMENTS : null
			};

			getPartners(requestData);
		} catch (error) {}
	};

	useEffect(() => {
		filterPartners();
	}, [filters, selectedCountry, selectedCity]);

	let filteredPartnersContent = null;

	if (getPartnersisLoading)
		filteredPartnersContent = [0, 1, 2].map(() => {
			return (
				<BCSkeleton
					loading={getPartnersisLoading}
					paragraph={{ rows: 2 }}
					round={false}
					style={{ width: '350px' }}
				/>
			);
		});

	if (getPartnersisSuccess && filteredPartners?.length)
		filteredPartnersContent = filteredPartners.map((partner: any) => {
			const segment = partner.segment || 'Silver';
			const avatarUrl = `https://saas8402cs.blob.core.windows.net/portal-store-storage/${partner.logo}`;

			return (
				<BCCardPartner
					avatar={avatarUrl}
					partnerName={partner.partnerName}
					coverLetter={partner.coverLetter}
					segment={segment}
					navigationUrl={`/partners/${partner.shortCode}/detail`}
				/>
			);
		});

	if (!getPartnersisLoading && !filteredPartners?.length)
		filteredPartnersContent = <BCEmpty className="emptyContent" />;

	return (
		<div className="partnersFiltersContainer">
			{filters?.length ? (
				<div className="filtersTagsContainer">
					{filters.map(
						(tag: {
							position: string;
							title: React.ReactNode | string;
							key: string;
							value: string | number;
						}) => {
							return tag.key != '51' ? (
								<BCTag
									closable
									tagKey={tag.key}
									handleClose={handleTagClose}
									className="filterTag"
								>
									{tag.title}
								</BCTag>
							) : tag.key == '51' && selectedCountry?.label ? (
								<BCTag
									closable
									tagKey={tag.key}
									handleClose={handleTagClose}
									className="filterTag"
								>
									{`Lokasyon (${selectedCountry.label} ${
										selectedCity?.label ? ',' + selectedCity.label : ''
									})`}
								</BCTag>
							) : null;
						}
					)}
				</div>
			) : null}
			<div className="filtersContentContainer">
				<div className="contentTopContainer">
					<span className="FilterHead">{t('000015')}</span>
				</div>
				<div className="contentContainer">{filteredPartnersContent}</div>
			</div>
		</div>
	);
};

export default FilterContent;
