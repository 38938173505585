import { useEffect, useLayoutEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Avatar, Tag } from "antd";
import {
  UserAddOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  BCButton,
  BCCol,
  BCLayoutContent,
  BCLayoutSider,
  BCRow,
  BCLayout,
  BCCardMeta,
  BCCardSameConsultancy,
} from "@components/index";
import { GetDocumentWithIcon, useBlobUrl } from "@utils/index";
import { openModal, selectCurrentUser } from "@store/index";
import {
  selectAllCategories,
  selectAllSectors,
  useCreateFavConsultanciesMutation,
  useDeleteFavConsultanciesMutation,
  useLazyGetConsultanciesFilterQuery,
  useLazyGetConsultancyDetailQuery,
} from "@services/index";
import DetailedInformations from "./components/detailedInformations";
import "./assets/style.scss";

const ConsultancyDetail = () => {
  const dispatch = useDispatch();
  const { systemname } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const allCategories = useSelector(selectAllCategories);
  const allSectors = useSelector(selectAllSectors);
  const user = useSelector(selectCurrentUser);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [
    getConsultancyDetail,
    {
      isLoading: getDetailisLoading,
      isSuccess: getDetailisSuccess,
      isError: getDetailisError,
      error: getDetailerror,
      data: consultancy,
    },
  ] = useLazyGetConsultancyDetailQuery();

  useEffect(() => {
    if (systemname)
      getConsultancyDetail({
        SYSTEMNAME: systemname,
        ispreview: pathname.split("/").includes("preview"),
      });
  }, [systemname]);

  const [
    getConsultancies,
    {
      isLoading: getConsultanciesisLoading,
      isSuccess: getConsultanciesisSuccess,
      isError: getConsultanciesisError,
      error: getConsultancieserror,
      data: filteredConsultancies,
    },
  ] = useLazyGetConsultanciesFilterQuery();

  useEffect(() => {
    if (getDetailisSuccess && consultancy && allCategories.length) {
      const consultancyCategories = consultancy?.category?.split(",");
      const matchingCategories =
        allCategories
          ?.filter((category: any) =>
            consultancyCategories.includes(category.DEFINITION)
          )
          ?.map((category: any) => category.DOCUMENTID) || [];

      if (matchingCategories.length) {
        getConsultancies({
          CONSULTANCYNAME: "",
          LISTTYPE: [],
          SECTOR: [],
          CATEGORY: matchingCategories,
          SERVICETYPE: "",
          ISPAID: [],
          MINFEE: "",
          MAXFEE: "",
        });
      }
    }
  }, [getDetailisSuccess, consultancy, allCategories]);

  const [
    addToFavorites,
    {
      isLoading: addToFavoritesisLoading,
      isSuccess: addToFavoritesisSuccess,
      isError: addToFavoritesisError,
      error: addToFavoriteserror,
      data: favoriteApp,
    },
  ] = useCreateFavConsultanciesMutation();

  const [
    removeFromFavorites,
    {
      isLoading: removeFromFavoritesisLoading,
      isSuccess: removeFromFavoritesisSuccess,
      isError: removeFromFavoritesisError,
      error: removeFromFavoriteserror,
      data: removedFavoriteApp,
    },
  ] = useDeleteFavConsultanciesMutation();

  const handleClickAddToFavorites = () => {
    try {
      if (user) addToFavorites({ CONSULTANCY: consultancy.documentid });
      else dispatch(openModal({ modal: "Login" }));
    } catch (error) {
      console.log("addToFavoritesError", error);
    }
  };

  const handleClickRemoveFromFavorites = () => {
    try {
      if (user) removeFromFavorites({ DOCUMENTID: consultancy.documentid });
      else dispatch(openModal({ modal: "Login" }));
    } catch (error) {
      console.log("removeFromFavoritesError", error);
    }
  };

  let content = null;

  if (getDetailisSuccess && consultancy) {
    const auxiliarydocuments = consultancy.auxiliarydocuments
      ?.split(",")
      ?.map((document: any) => {
        const documentUrl = document.split("|")[0];
        const documentName = document.split("|")[1];

        return { documentUrl, documentName };
      });

    const consultancyCategories = consultancy.category?.split(",");
    const consultancySectors = consultancy.sector?.split(",");
    const consultancyLabels = consultancy.labels?.split(",");

    const matchingCategories = allCategories.filter((category: any) =>
      consultancyCategories.includes(category.DEFINITION)
    );
    const matchingSectors = allSectors.filter((sector: any) =>
      consultancySectors.includes(sector.SECTORDEFINITION)
    );

    const getFavoriteButton = () => {
      if (consultancy?.favorite)
        return (
          <BCButton
            onClick={handleClickRemoveFromFavorites}
            loading={removeFromFavoritesisLoading}
            disabled={removeFromFavoritesisLoading}
            block
            content={t("000116")}
          />
        );
      if (!consultancy?.favorite)
        return (
          <BCButton
            onClick={handleClickAddToFavorites}
            loading={addToFavoritesisLoading}
            disabled={addToFavoritesisLoading}
            block
            content={t("000019")}
          />
        );
    };

    const favoriteButton = getFavoriteButton();

    content = (
      <div className="consultancyDetailContainer">
        <div className="detailHeader">
          <img className="banner" src={useBlobUrl(consultancy.showcase)} />
          <Avatar
            size={100}
            shape="square"
            icon={<img src={useBlobUrl(consultancy.icon)} />}
          />
        </div>
        <div className="contentTop">
          <BCLayout>
            <BCLayoutContent>
              <div>
                <span className="consultancyName">
                  {consultancy.consultancyname}
                </span>
              </div>
              <p className="summaryInformation">
                {consultancy.summaryinformation}
              </p>
              <div className="generalInformations">
                <BCRow>
                  <BCCol className="informationLabel">{t("000024")}</BCCol>
                  <BCCol>
                    {matchingCategories?.map((category: any) => {
                      return (
                        <Tag
                          className="informationTag"
                          color={category?.DEFINITIONCOLOR}
                        >
                          {category.DEFINITION}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000025")}</BCCol>
                  <BCCol>
                    {matchingSectors?.map((sector: any) => {
                      return (
                        <Tag
                          className="informationTag"
                          color={sector?.DEFINITIONCOLOR}
                        >
                          {sector.SECTORDEFINITION}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000026")}</BCCol>
                  <BCCol>
                    {consultancyLabels?.map((label: any) => {
                      return (
                        <Tag className="informationTag" color="#ced2dd">
                          {label}
                        </Tag>
                      );
                    })}
                  </BCCol>
                </BCRow>
                <BCRow>
                  <BCCol className="informationLabel">{t("000027")}</BCCol>
                  <BCCol>
                    {auxiliarydocuments?.map(
                      (document: {
                        documentUrl: string;
                        documentName: string;
                      }) => {
                        return (
                          <span className="informationTag documentTag">
                            <a
                              href={useBlobUrl(document.documentUrl)}
                              target="_blank"
                            >
                              {GetDocumentWithIcon(document)}
                            </a>
                          </span>
                        );
                      }
                    )}
                  </BCCol>
                </BCRow>
              </div>
            </BCLayoutContent>
            <BCLayoutSider width={230}>
              <div className="buttonsContainer">
                {consultancy.consultancyfee ? (
                  <span className="price">{`${consultancy.consultancyfee.toLocaleString(
                    consultancy.moneytype == "₺" ? "tr-TR" : "en-US",
                    {
                      style: "currency",
                      currency: consultancy.moneytype == "₺" ? "TRY" : "USD",
                    }
                  )}`}</span>
                ) : null}
                <BCButton
                  className="consultancyRequestModal"
                  onClick={() =>
                    dispatch(
                      openModal({
                        modal: "ConsultancyRequest",
                        parameters: consultancy,
                      })
                    )
                  }
                  block
                  content={t("000020")}
                />
                {favoriteButton}
              </div>
            </BCLayoutSider>
          </BCLayout>
        </div>
        <div className="contentBottom">
          <BCLayout>
            <BCLayoutContent>
              <DetailedInformations consultancy={consultancy} />
            </BCLayoutContent>
            <BCLayoutSider width={270}>
              <div className="siderContainer">
                <div className="partnerInformations">
                  <h3>{t("000032")}</h3>
                  <BCCardMeta
                    title={t("000033")}
                    description={consultancy.providerprofile}
                    avatar={<UserAddOutlined />}
                  />
                  <BCCardMeta
                    title={t("000034")}
                    description={
                      <BCButton
                        content={consultancy.providerwebsite}
                        type="link"
                        target="_blank"
                        href={consultancy.providerwebsite}
                      />
                    }
                    avatar={<GlobalOutlined />}
                  />
                  <BCCardMeta
                    title={t("000035")}
                    description={
                      <BCButton
                        content={consultancy.provideremail}
                        type="link"
                        href={`mailto:${consultancy.provideremail}`}
                      />
                    }
                    avatar={<MailOutlined />}
                  />
                  <BCCardMeta
                    title={t("000036")}
                    description={
                      <BCButton
                        content={consultancy.providerphone}
                        type="link"
                        href={`tel:${consultancy.providerphone}`}
                      />
                    }
                    avatar={<PhoneOutlined />}
                  />
                </div>
                <div className="similarConsultanciesContainer">
                  <h3>{t("000042")}</h3>
                  <div className="similarConsultancies">
                    {getConsultanciesisSuccess && filteredConsultancies?.length
                      ? filteredConsultancies
                          .slice(0, 4)
                          ?.map((SameConsultancy: any) => {
                            return (
                              <Link
                                to={`/consultancies/${SameConsultancy.systemname}/detail`}
                              >
                                <BCCardSameConsultancy
                                  bordered
                                  hoverable
                                  avatar={useBlobUrl(SameConsultancy.icon)}
                                  title={SameConsultancy.consultancyname}
                                  description={
                                    SameConsultancy.summaryinformation
                                  }
                                  time="4 Gün"
                                />
                              </Link>
                            );
                          })
                      : null}
                  </div>
                </div>
              </div>
            </BCLayoutSider>
          </BCLayout>
        </div>
      </div>
    );
  }
  return content;
};

export default ConsultancyDetail;
