import { Link } from "react-router-dom";
import { Avatar, Card } from "antd";
import { FaMessage, FaStar } from "react-icons/fa6";
import { BCCard, BCCol, BCFlex, BCRow, BCSpace } from "..";
import { ICardFavoriteProps } from "./entities";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const BCCardFavorite = (props: ICardFavoriteProps) => {
  const { data } = props;

  let content = null;

  if (data.APPNAME) {
    content = (
      <Link
        to={`/applications/${data.SYSTEMNAME}/detail`}
        className="cardFavoriteContainer"
      >
        <BCCard
          hoverable
          size="default"
          bodyStyle={{ padding: "16px 14px 13px 14px" }}
        >
          <BCFlex vertical justify="space-between" gap={1}>
            <BCRow>
              <BCCol>
                <Card.Meta
                  avatar={
                    <Avatar
                      size={70}
                      src={useBlobUrl(data.ICON)}
                      shape="square"
                    />
                  }
                  title={data.APPNAME}
                  description={data.SUMMARYINFORMATION}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </BCCol>
            </BCRow>
            <BCRow className="cardBottom" justify={"space-between"}>
              <BCCol>{data.PRODUCER}</BCCol>
              <BCSpace size={"large"}>
                {data.AVERAGE ? (
                  <BCCol className="reviewContainer">
                    <span className="average">
                      <FaStar color="#9D9D9D" size="16px" />
                    </span>
                    <span>{data.AVERAGE}</span>
                  </BCCol>
                ) : null}
                {data.COMMENT ? (
                  <BCCol className="reviewContainer">
                    <span className="comment">
                      <FaMessage color="#9D9D9D" size="16px" />
                    </span>
                    <span>{data.COMMENT}</span>
                  </BCCol>
                ) : null}
              </BCSpace>
            </BCRow>
          </BCFlex>
        </BCCard>
      </Link>
    );
  }

  if (data.CONSULTANCYNAME) {
    content = (
      <Link
        to={`/consultancies/${data.SYSTEMNAME}/detail`}
        className="cardFavoriteContainer"
      >
        <BCCard
          hoverable
          size="default"
          bodyStyle={{ padding: "16px 14px 13px 14px" }}
        >
          <BCFlex vertical justify="space-between" gap={15}>
            <BCRow>
              <BCCol>
                <Card.Meta
                  avatar={
                    <Avatar
                      size={70}
                      src={useBlobUrl(data.ICON)}
                      shape="square"
                    />
                  }
                  title={data.CONSULTANCYNAME}
                  description={data.SUMMARYINFORMATION}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </BCCol>
            </BCRow>
            <BCRow className="cardBottom" justify={"space-between"}>
              <BCCol>{data.PRODUCER}</BCCol>
            </BCRow>
          </BCFlex>
        </BCCard>
      </Link>
    );
  }

  if (data.Name) {
    let status;

    if (data.SegmentText == "PLATINIUM") {
      status = <div className="platinumSegment">PLATINUM</div>;
    } else if (data.SegmentText == "GOLD") {
      status = <div className="goldSegment">GOLD</div>;
    } else {
      status = <div className="silverSegment">SILVER</div>;
    }

    content = (
      <Link
        to={`/partners/${data.ShortCode}/detail`}
        className="cardFavoriteContainer"
      >
        <BCCard
          hoverable
          size="default"
          bodyStyle={{ padding: "16px 14px 13px 14px" }}
        >
          <BCFlex vertical justify="space-between" gap={1}>
            <BCRow>
              <BCCol>
                <Card.Meta
                  avatar={
                    <Avatar
                      size={70}
                      src={useBlobUrl(data.FileId)}
                      shape="square"
                    />
                  }
                  title={data.Name}
                  description={data.COVERLETTER}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </BCCol>
            </BCRow>
            <BCRow className="cardBottom" justify={"space-between"}>
              <BCCol>{status}</BCCol>
              {/* <BCSpace size={'large'}>
                            <BCCol className='reviewContainer'>
                                <span className='average'><FaStar color="#9D9D9D" size="16px" /></span>
                                <span>{data.AVERAGE}</span>
                            </BCCol>
                            <BCCol className='reviewContainer'>
                                <span className='comment'><FaMessage color="#9D9D9D" size="16px" /></span>
                                <span>{data.COMMENT}</span>
                            </BCCol>
                        </BCSpace> */}
            </BCRow>
          </BCFlex>
        </BCCard>
      </Link>
    );
  }

  return content;
};

export default BCCardFavorite;
