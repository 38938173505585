import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  BCButton,
  BCCol,
  BCDatePicker,
  BCForm,
  BCFormItem,
  BCModal,
  BCRow,
  BCSelect,
} from "@components/index";
import { closeModal, openModal, selectCurrentUser } from "@store/index";
import { useCreateSubscriptionMutation } from "@services/index";
// import { IFreeTrialProps } from './entities';
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const FreeTrial = (props: any) => {
  const { parameters } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer: any = useSelector(selectCurrentUser);

  const [canModalClose, setCanModalClose] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedInstance, setSelectedInstance] = useState<string>(
    customer?.tenantInfoDetails[0]?.tenantUID || ""
  );
  // const [isChecked, setIsChecked] = useState({
  //     TrialVersion: false,
  //     BPMGenesis: false,
  //     CSPStore: false,
  //     AcceptAll: false
  // });
  let startDate = dayjs();
  let endDate = dayjs().add(Number(parameters.day), "day");

  const [
    createSubscription,
    {
      isLoading: createSubscriptionIsLoading,
      isSuccess: createSubscriptionIsSuccess,
      isError: createSubscriptionIsError,
      error: createSubscriptionError,
      data: createSubscriptionData,
    },
  ] = useCreateSubscriptionMutation();

  const getInstanceOptions = () => {
    const options = (customer?.tenantInfoDetails || []).map((tenant: any) => ({
      label: `${tenant.firstName} ${tenant.lastName}`,
      value: tenant.tenantUID,
    }));

    return options;
  };

  const createFreeTrialRequest = () => {
    try {
      const data = {
        tenantvalue: selectedInstance,
        applicationvalue: parameters.appdocumentid,
        periodicamount: parameters.periodicamount,
        applicationfee: parameters.applicationfee,
        wagestatus: parameters.applicationfee ? 2 : 1,
        startdate: startDate.format(),
        enddate: endDate.format(),
        monthlyannualy: parameters.mounthlyannuallyvalue,
        moneytype: parameters.moneytypevalue,
      };
      createSubscription(data);
    } catch (error) {
      console.log("createFreeTrialRequest", error);
    }
  };

  useEffect(() => {
    if (createSubscriptionIsSuccess) {
      dispatch(
        openModal({
          modal: "Information",
          parameters: {
            title: t("000017"),
            message: t("000080"),
            description: t("000088"),
          },
        })
      );
      setCanModalClose(true);
    }
  }, [createSubscriptionIsSuccess]);

  useEffect(() => {
    if (createSubscriptionIsLoading) setCanModalClose(false);
  }, [createSubscriptionIsLoading]);

  useEffect(() => {
    if (createSubscriptionIsError) setCanModalClose(true);
  }, [createSubscriptionIsError]);

  // const handleChecked = (e: CheckboxChangeEvent) => {
  //     setIsChecked({
  //         ...isChecked, [e.target.name as string]: e.target.checked
  //     })
  // }

  const onDateChange = (date: any) => {};

  const handleInstanceChange = (value: string, option: any) => {
    setSelectedInstance(value);
  };

  const handleCancel = () => {
    if (canModalClose) {
      setIsModalOpen(false);
      dispatch(closeModal());
    }
  };

  return (
    <BCModal
      open={isModalOpen}
      title={t("000017")}
      onCancel={handleCancel}
      footer={[
        <BCButton
          content={t("000082")}
          onClick={createFreeTrialRequest}
          loading={createSubscriptionIsLoading}
          style={{ backgroundColor: "#00104E", color: "#fff" }}
        />,
        <BCButton
          content={t("000083")}
          onClick={handleCancel}
          style={{ backgroundColor: "#fff", color: "#00104E" }}
        />,
      ]}
      width={400}
      className="freeTrialContainer"
    >
      <BCRow justify={"space-between"}>
        <BCCol span={10} style={{ margin: "20px 0" }}>
          <div>
            <img width={100} src={useBlobUrl(parameters?.logo)} />
          </div>
        </BCCol>
        <BCCol span={14} style={{ margin: "20px 0" }}>
          <div style={{ fontSize: "16px", fontWeight: 600 }}>
            {parameters.appname}
          </div>
          <div style={{ fontSize: "14px", color: "#c9c9c9" }}>
            {t("000084")}
          </div>
          <div>{parameters.partnersname}</div>
        </BCCol>
      </BCRow>
      <BCForm>
        <BCRow justify={"space-between"}>
          <BCCol span={11}>
            <BCFormItem label={t("000089")} labelCol={{ span: 24 }}>
              <BCDatePicker
                onChange={onDateChange}
                value={startDate}
                disabled
              />
            </BCFormItem>
          </BCCol>
          <BCCol span={11}>
            <BCFormItem label={t("000090")} labelCol={{ span: 24 }}>
              <BCDatePicker onChange={onDateChange} value={endDate} disabled />
            </BCFormItem>
          </BCCol>
          <BCCol span={24}>
            <BCFormItem label={t("000085")} labelCol={{ span: 24 }}>
              <BCSelect
                options={getInstanceOptions()}
                onChange={handleInstanceChange}
                defaultValue={getInstanceOptions()[0]}
              />
            </BCFormItem>
          </BCCol>
        </BCRow>
        {/* <BCRow>
                    <BCCol>
                        <BCCheckbox name='TrialVersion' label='Deneme sürümü kullanma koşulları' checked={isChecked.TrialVersion} onChange={handleChecked} />
                    </BCCol>
                    <BCCol>
                        <BCCheckbox name='BPMGenesis' label='BPM Genesis hizmet koşulları ve gizlilik politikası' checked={isChecked.BPMGenesis} onChange={handleChecked} />
                    </BCCol>
                    <BCCol>
                        <BCCheckbox name='CSPStore' label='CSP Store hizmet koşulları ve gizlilik politikası' checked={isChecked.CSPStore} onChange={handleChecked} />
                    </BCCol>
                    <BCCol>
                        <BCCheckbox name='AcceptAll' label='Tümünü kabul ediyorum' checked={isChecked.AcceptAll} onChange={handleChecked} />
                    </BCCol>
                </BCRow> */}
      </BCForm>
    </BCModal>
  );
};

export default FreeTrial;
