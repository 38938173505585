import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { IDataRangePickerProps } from './entities';

const BCDateRangePicker = (props: IDataRangePickerProps) => {
    const { allowEmpty, disabled, onChange, value, format, disabledDate, style, className } = props;
    const { RangePicker } = DatePicker;

    const handleChange = (dates: any, dateStrings: any) => {

        let startDate = null;
        let endDate = null;
        if (dates?.[0]) startDate = dayjs(dates[0]).format()
        if (dates?.[1]) endDate = dayjs(dates[1]).format()

        if (onChange) {
            onChange(startDate, endDate)
        }
    }

    return (
        <RangePicker
            allowEmpty={allowEmpty}
            disabled={disabled}
            onChange={handleChange}
            value={value}
            format={format}
            disabledDate={disabledDate}
            className={className}
            style={style}
        />
    )
}

export default BCDateRangePicker