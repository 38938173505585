import { Spin } from 'antd';
import { ISpinProps } from './entities';

const BCSpin = (props: ISpinProps) => {
    const { delay, indicator, size, spinning, tip, wrapperClassName } = props;

    return (
        <Spin
            tip={tip}
            size={size}
            spinning={spinning}
            delay={delay}
            indicator={indicator}
            wrapperClassName={wrapperClassName}
        >
            {props.children}
        </Spin>
    )
}

export default BCSpin