import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FcOk } from "react-icons/fc";
import { closeModal } from "@store/index";
import { BCButton, BCCol, BCModal, BCRow } from "@components/index";
import { information } from "@assets/index";
import "./assets/style.scss";

const InformationModal = (props: any) => {
  const { parameters } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(closeModal());
  };

  return (
    <BCModal
      open={isModalOpen}
      title={parameters.title}
      onCancel={handleCancel}
      footer={[<BCButton content={t("000102")} onClick={handleCancel} />]}
      width={630}
      className="informationModalContainer"
    >
      <BCRow>
        <BCCol span={12}>
          <img src={information} />
        </BCCol>
        <BCCol className="rightContent" span={12}>
          <div>
            <FcOk size={40} />
            <span>{parameters.message}</span>
          </div>
          <p>{parameters.description}</p>
        </BCCol>
      </BCRow>
    </BCModal>
  );
};

export default InformationModal;
