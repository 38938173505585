import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BCButton, BCCarouselCardPartner, BCSkeleton } from '@components/index';
import { partnerFilter } from '@store/index';
import { IPlatinumPartnerCarouselProps } from './entities';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './assets/style.scss';
import { useBlobUrl } from '@utils/index';

const PlatinumPartnerCarousel = (props: IPlatinumPartnerCarouselProps) => {
	const { breakpoints, partners, getPartnersisLoading, getPartnersisSuccess } = props;

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const showAllPartners = (partnerType: string) => {
		if (partnerType == 'platinum') {
			dispatch(
				partnerFilter({
					filters: [{ key: '700', position: '0-3-2', title: 'PLATINIUM', value: '700' }]
				})
			);
		}
	};

	let content = null;

	if (getPartnersisLoading)
		content = [1, 2, 3, 4].map(() => {
			return (
				<SwiperSlide>
					<BCSkeleton
						loading={getPartnersisLoading}
						paragraph={{ rows: 4 }}
						round={false}
					/>
				</SwiperSlide>
			);
		});

	if (getPartnersisSuccess && partners.length) {
		content = partners
			?.filter((partner: any) => partner.segment == 'Platinium')
			.map((partner: any) => {
				return (
					<SwiperSlide>
						<BCCarouselCardPartner
							cover={
								<img
									src={useBlobUrl(partner.logo)}
									className="swiperSlideItem"
								/>
							}
							partnerName={partner.partnerName}
							shortCode={partner.shortCode}
							// comment={partner.comment}
							// producer={partner.producer}
							// average={partner.average}
							coverLetter={partner.coverLetter}
						/>
					</SwiperSlide>
				);
			});
	}

	return (
		<>
			<div className="platinumPartnerCarouselTopContainer">
				<p>{t('000011')}</p>
				<BCButton
					onClick={() => showAllPartners('platinum')}
					href="#/partners/filter/all"
					content={t('000006')}
					type="text"
					icon={<ArrowRightOutlined />}
					iconPosition="end"
				/>
			</div>
			<Swiper
				className="swiperPlatinumPartnerCarouselContainer"
				modules={[Navigation, Pagination, A11y, Autoplay]}
				navigation={{
					prevEl: '.swiper-button-prev',
					nextEl: '.swiper-button-next'
				}}
				autoplay={{
					delay: 90000,
					disableOnInteraction: false
				}}
				pagination={{
					clickable: true,
					dynamicBullets: true
				}}
				breakpoints={breakpoints}
			>
				{content}
				<div className="navigationContainer">
					<span className="swiper-button-prev" />
					<span className="swiper-button-next" />
				</div>
			</Swiper>
		</>
	);
};

export default PlatinumPartnerCarousel;
