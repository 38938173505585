import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { LuArrowUpWideNarrow, LuArrowDownWideNarrow } from "react-icons/lu";
import { selectCurrentConsultancyFilters } from "@store/index";
import { useLazyGetConsultanciesFilterQuery } from "@services/index";
import {
  BCButton,
  BCCardConsultancy,
  BCEmpty,
  BCSelect,
  BCSkeleton,
  BCSpace,
  BCTag,
} from "@components/index";
import { IConsultancyFilterProps } from "./entities";
import "./assets/style.scss";

const FilterContent = (props: IConsultancyFilterProps) => {
  const { handleTagClose, feeFilter, minFee, maxFee } = props;

  const { t } = useTranslation();
  const filters = useSelector(selectCurrentConsultancyFilters);

  // const [filterArrow, setFilterArrow] = useState(false);

  const [
    consultanciesFilter,
    {
      isLoading: getConsultanciesisLoading,
      isSuccess: getConsultanciesisSuccess,
      isError: getConsultanciesisErrors,
      error: getConsultancieserror,
      data: filteredConsultancies,
    },
  ] = useLazyGetConsultanciesFilterQuery();

  const filterConsultancies = () => {
    try {
      const LISTTYPE =
        filters
          ?.filter((item) => item.position.startsWith("0-0"))
          .map((item) => item.value) || [];
      const SECTOR =
        filters
          ?.filter((item) => item.position.startsWith("0-1"))
          .map((item) => item.value) || [];
      const CATEGORY =
        filters
          ?.filter((item) => item.position.startsWith("0-2"))
          .map((item) => item.value) || [];
      const ONLINE = filters?.find((item) => item.position.startsWith("0-3-0"));
      const FACETOFACE = filters?.find((item) =>
        item.position.startsWith("0-3-1")
      );
      const ISPAID = filters?.find((item) => item.position.startsWith("0-4-0"));
      const ISFREE = filters?.find((item) => item.position.startsWith("0-4-1"));

      const isPaid = () => {
        if (ISPAID && ISFREE)
          return []; // Hem ödeme gerektiren hem de ücretsiz bir durum söz konusuysa
        else if (ISPAID)
          return [1]; // Sadece ödeme gerektiren bir durum söz konusuysa
        else if (ISFREE) return [0]; // Sadece ücretsiz bir durum söz konusuysa
        else return []; // Ne ödeme gerektiriyor ne de ücretsiz bir durum söz konusuysa
      };

      const serviceType = () => {
        if (ONLINE && FACETOFACE) return "";
        else if (ONLINE) return 0;
        else if (FACETOFACE) return 1;
        else return "";
      };

      const requestData = {
        CONSULTANCYNAME: "",
        LISTTYPE: LISTTYPE,
        SECTOR: SECTOR,
        CATEGORY: CATEGORY,
        SERVICETYPE: serviceType(),
        ISPAID: isPaid(),
        MINFEE: isPaid().includes(1) ? (minFee as number) : "",
        MAXFEE: isPaid().includes(1) ? (maxFee as number) : "",
      };

      consultanciesFilter(requestData);
    } catch (error) {}
  };

  useEffect(() => {
    filterConsultancies();
  }, [filters, feeFilter]);

  let filteredConsultanciesContent = null;

  if (getConsultanciesisLoading)
    filteredConsultanciesContent = [0, 1, 2].map(() => {
      return (
        <BCSkeleton
          loading={true}
          paragraph={{ rows: 2 }}
          round={false}
          style={{ width: "350px" }}
        />
      );
    });

  if (getConsultanciesisSuccess && filteredConsultancies?.length)
    filteredConsultanciesContent = filteredConsultancies.map(
      (consultancy: any) => {
        return (
          <BCCardConsultancy
            consultancyname={consultancy.consultancyname}
            average={consultancy.average}
            comment={consultancy.comment}
            providerprofile={consultancy.providerprofile}
            summaryinformation={consultancy.summaryinformation}
            navigationUrl={`/consultancies/${consultancy.systemname}/detail`}
            avatar={`https://saas8402cs.blob.core.windows.net/portal-store-storage/${consultancy.icon}`}
          />
        );
      }
    );

  if (!getConsultanciesisLoading && !filteredConsultancies?.length)
    filteredConsultanciesContent = <BCEmpty className="emptyContent" />;

  return (
    <div className="consultanciesFiltersContainer">
      {filters?.length ? (
        <div className="filtersTagsContainer">
          {filters.map(
            (tag: {
              position: string;
              title: React.ReactNode | string;
              key: string;
              value: string | number;
            }) => {
              return (
                <BCTag
                  closable
                  tagKey={tag.key}
                  handleClose={handleTagClose}
                  className="filterTag"
                >
                  {tag.title}
                  {tag.key == "41" ? ` (${minFee} - ${maxFee})` : null}
                </BCTag>
              );
            }
          )}
        </div>
      ) : null}
      <div className="filtersContentContainer">
        <div className="contentTopContainer">
          <span className="FilterHead">{t("000004")}</span>
          <BCSpace size={"middle"} className="filterTopParameters">
            {/* <span>{t('000014')}</span>
                        <BCSelect
                            options={[
                                { value: 'Alfabetik', label: 'Alfabetik' },
                                { value: 'Puan', label: 'Puan' },
                                { value: 'Tarih', label: 'Tarih' },
                            ]}
                            defaultValue='Alfabetik'
                        />
                        <BCButton
                            leftIcon={filterArrow ? <LuArrowUpWideNarrow size='24px' /> : <LuArrowDownWideNarrow size='24px' />}
                            type='text'
                            onClick={() => setFilterArrow(!filterArrow)}
                        /> */}
          </BCSpace>
        </div>
        <div className="contentContainer">{filteredConsultanciesContent}</div>
      </div>
    </div>
  );
};

export default FilterContent;
