import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HomeOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { BCButton, BCMenu } from "@components/index";
import "./assets/style.scss";

const Navbar = () => {
  const { t } = useTranslation();

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      label,
      key,
      icon,
      children,
      type,
    } as MenuItem;
  }

  const createNavLink = (to: string, label: React.ReactNode | string) => (
    <NavLink to={to}>
      {({ isActive }) => (
        <BCButton
          type="text"
          size="small"
          className="navbarButton"
          style={{
            color: isActive ? "#00104E" : "#707070",
            borderBottom: isActive ? "2px solid #00104E" : "none",
          }}
          content={label}
        />
      )}
    </NavLink>
  );

  const items: MenuProps["items"] = [
    getItem(createNavLink("/", <HomeOutlined className="navIcon" />), "menu1"),
    getItem(createNavLink("/applications", t("000003")), "menu2"),
    getItem(createNavLink("/consultancies", t("000004")), "menu3"),
    getItem(createNavLink("/partners", t("000005")), "menu4"),
  ];

  return (
    <div className="navbar">
      <BCMenu
        items={items}
        mode="horizontal"
        className="navbarMenu"
        defaultSelectedKeys={["menu1"]}
      />
    </div>
  );
};

export default Navbar;
