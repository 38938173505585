import { Segmented, ConfigProvider } from 'antd';
import { ISegmendProps } from './entities';


const BCSegmented = (props: ISegmendProps) => {
    const { block, disabled, onChange, options, size, defaultValue, value } = props;

    return (
        <ConfigProvider
            theme={{
                components: {
                    Segmented: {
                        itemSelectedBg: "#00104E",
                        itemSelectedColor: "#fff",
                    },
                }
            }}
        >
            <Segmented
                options={options}
                disabled={disabled}
                size={size}
                block={block}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
            />
        </ConfigProvider>
    )
}

export default BCSegmented