import { BCCheckbox, BCCol, BCForm, BCFormItem, BCInputTextArea, BCRow, BCSelect } from '@components/index'

const AppealAndConditions = () => {

    return (
        <BCForm>
            <BCRow >
                <BCCol span={24}>
                    <BCFormItem label="Partner Kategorisi" required labelCol={{ span: 24 }}>
                        <BCSelect placeholder="Partner kategorisini seçiniz" />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow >
                <BCCol span={24}>
                    <BCFormItem label="Hizmet Türü" required labelCol={{ span: 24 }}>
                        <BCSelect placeholder="Hizmet türünü seçiniz" />
                    </BCFormItem >
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem label="Mesajınız" required labelCol={{ span: 24 }}>
                        <BCInputTextArea placeholder='Mesajınızı yazınız' rows={5} />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem >
                        <BCCheckbox label='Gizlilik politikasını okudum kabul ediyorum' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem >
                        <BCCheckbox label='Partner sözleşmesini okudum kabul ediyorum' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
        </BCForm>
    )
}

export default AppealAndConditions