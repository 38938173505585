import { Form } from 'antd';
import { IFormProps } from './entities';

const BCForm = (props: IFormProps) => {
	const {
		name,
		size,
		colon,
		disabled,
		labelAlign,
		labelWrap,
		labelCol,
		wrapperCol,
		layout,
		scrollToFirstError,
		onFinish,
		form,
		className
	} = props;

	return (
		<Form
			name={name}
			size={size}
			colon={colon}
			disabled={disabled}
			labelAlign={labelAlign}
			labelWrap={labelWrap}
			labelCol={labelCol}
			wrapperCol={wrapperCol}
			layout={layout}
			scrollToFirstError={scrollToFirstError}
			onFinish={onFinish}
			form={form}
			className={className}
		>
			{props.children}
		</Form>
	);
};

export default BCForm;
