import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input } from 'antd';
import { IInputPasswordProps } from './entities';

const BCInputPassword = (props: IInputPasswordProps) => {
    const { value, onChange } = props;

    return (
        <Input.Password
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            value={value}
            onChange={onChange}
        />
    )
}

export default BCInputPassword