import { Rate } from 'antd';
import { IRateProps } from './entities';
import './assets/style.scss'

const BCRate = (props: IRateProps) => {
    const { allowClear, allowHalf, character, count, defaultValue, disabled, tooltips, style, value, setValue } = props;

    return (
        <Rate
            tooltips={tooltips}
            onChange={setValue}
            value={value}
            defaultValue={defaultValue}
            allowClear={allowClear}
            allowHalf={allowHalf}
            disabled={disabled}
            count={count}
            character={character}
            style={style}
        />
    )
}

export default BCRate