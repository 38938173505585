import dayjs from "dayjs";
import { DatePicker, DatePickerProps } from "antd";
import { IDatePickerProps } from "./entities";

const BCDatePicker = (props: IDatePickerProps) => {
  const { size, disabled, onChange, value, disabledDate } = props;

  const handleChange: DatePickerProps["onChange"] = (date, dateString) => {
    let modifiedDate = null;
    if (date) modifiedDate = dayjs(date).format();

    if (onChange) {
      onChange(modifiedDate);
    }
  };

  return (
    <DatePicker
      onChange={handleChange}
      size={size}
      disabled={disabled}
      style={{ width: "100%" }}
      value={value}
      disabledDate={disabledDate}
    />
  );
};

export default BCDatePicker;
