import { Card } from 'antd';
import { ICardProps } from './entities';

const BCCard: React.FC<ICardProps> = (props) => {
    const { title, extra, size, bordered, hoverable, cover, loading, actions, type, headStyle, bodyStyle, style, className } = props;

    return (
        <Card
            title={title}
            extra={extra}
            size={size}
            bordered={bordered}
            hoverable={hoverable}
            cover={cover}
            loading={loading}
            actions={actions}
            type={type}
            headStyle={headStyle}
            bodyStyle={bodyStyle}
            style={style}
            className={className}
        >
            {props.children}
        </Card>
    )
}

export default BCCard