import { Skeleton } from 'antd';
import { ISkeletonProps } from './entities';

const BCSkeleton = (props: ISkeletonProps) => {
    const { loading, paragraph, round, style } = props;

    return (
        <Skeleton
            avatar
            paragraph={paragraph}
            active
            loading={loading}
            round={round}
            title
            style={style}
        >
            {props.children}
        </Skeleton>
    )
}

export default BCSkeleton