import { ServiceList, baseApi } from "@services/index"
import {
    ICreateFavPartnersRequest,
    ICreateFavPartnersResponse,
    IDeleteFavPartnersRequest,
    IDeleteFavPartnersResponse,
    IGetEvaluationByPartnerCodeResponse,
    IGetFavPartnersListResponse,
    IGetPartnerDetailRequest,
    IGetPartnerDetailResponse,
    IGetPartnersRequest,
    IGetPartnersResponse
} from "./entities"

export const partnerApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        GetPartners: builder.query<any, IGetPartnersRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.GetPartners,
                method: 'POST',
                body: { ...parameters }
            }),
            transformResponse: (response: IGetPartnersResponse) => response.result,
        }),
        GetPartnerDetail: builder.query<any, IGetPartnerDetailRequest>({
            query: (shortCode) => ({
                url: ServiceList.baseQuery2.GetPartnerDetail,
                method: 'POST',
                body: shortCode
            }),
            transformResponse: (response: IGetPartnerDetailResponse) => response.result,
            providesTags: ['Partner']
        }),
        GetEvaluationByPartnerCode: builder.query<any, string>({
            query: (shortcode) => ({
                url: `${ServiceList.baseQuery2.GetEvaluationByPartnerCode + shortcode}`,
                method: 'GET',
            }),
            transformResponse: (response: IGetEvaluationByPartnerCodeResponse) => response.result,
        }),
        CreateFavPartners: builder.mutation<any, ICreateFavPartnersRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.CreateFavPartners,
                method: 'POST',
                body: parameters
            }),
            transformResponse: (response: ICreateFavPartnersResponse) => response,
            invalidatesTags: ['Partner']
        }),
        DeleteFavPartners: builder.mutation<any, IDeleteFavPartnersRequest>({
            query: (parameters) => ({
                url: ServiceList.baseQuery2.DeleteFavPartners,
                method: 'POST',
                body: parameters
            }),
            transformResponse: (response: IDeleteFavPartnersResponse) => response,
            invalidatesTags: ['Partner']
        }),
        GetFavPartnersList: builder.query<any, void>({
            query: () => ({
                url: ServiceList.baseQuery2.GetFavPartnersList,
                method: 'GET',
            }),
            transformResponse: (response: IGetFavPartnersListResponse) => response.result,
            providesTags: ['Partner']
        }),
    })
})

export const {
    useLazyGetPartnersQuery,
    useLazyGetPartnerDetailQuery,
    useLazyGetEvaluationByPartnerCodeQuery,
    useCreateFavPartnersMutation,
    useDeleteFavPartnersMutation,
    useLazyGetFavPartnersListQuery
} = partnerApi 