import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Parallax, Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { topSlideApps, topSlideConsulting, topSlideHome, topSlidePartners } from '@assets/index'
import { IPageTopCarouselProps } from './entities';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './assets/style.scss'

const PageTopCarousel = (props: IPageTopCarouselProps) => {

    const { pathname } = useLocation()
    const { t } = useTranslation()

    const homeText = t('000074')
    const appText = t('000075')
    const consultancyText = t('000112')
    const partnerText = t('000113')

    const dataApp = [
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideHome} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{homeText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideApps} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{appText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideConsulting} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{consultancyText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlidePartners} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{partnerText}</p>
        </SwiperSlide>
    ]

    const dataConsultancy = [
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideConsulting} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{consultancyText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlidePartners} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{partnerText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideHome} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{homeText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideApps} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{appText}</p>
        </SwiperSlide>
    ]

    const dataPartner = [
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlidePartners} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{partnerText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideConsulting} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{consultancyText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideHome} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{homeText}</p>
        </SwiperSlide>,
        <SwiperSlide className='swiperSlideItem'>
            <img className='swiperSlideImage' src={topSlideApps} data-swiper-parallax="-300" />
            <p className='swiperSlideText' data-swiper-parallax="-9000">{appText}</p>
        </SwiperSlide>
    ]

    const renderSliderItems = () => {
        if (pathname == '/' || pathname == '/applications' || pathname == '/applications/filter/all') return dataApp
        if (pathname == '/consultancies' || pathname == '/consultancies/filter/all') return dataConsultancy
        if (pathname == '/partners' || pathname == '/partners/filter/all') return dataPartner
    }

    return (
        <Swiper
            className='swiperPageTopCarouselContainer'
            modules={[Parallax, Navigation, Pagination, A11y, Autoplay]}
            speed={600}
            parallax={true}
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }}
            autoplay={{
                delay: 90000,
                disableOnInteraction: false
            }}
            pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 1 }}
        >
            <div
                slot="container-start"
                className="parallax-bg"
                data-swiper-parallax="-23%"
            ></div>

            {renderSliderItems()?.map((sliderItem: any) => sliderItem)}

            <div className='navigationContainer' >
                <span className='swiper-button-prev' />
                <span className='swiper-button-next' />
            </div>
        </Swiper>
    )
}

export default PageTopCarousel