export const ServiceList = {
  baseQuery1: {
    Login: "/web/Login/Login",
    SendResetPasswordMail: `/web/Password/SendResetPasswordMail`,
  },
  baseQuery2: {
    GetApps: `/deployagent/apps/PPRODUCTS_2/latest/api/AppDefinition_Archive/GetApps`,
    GetAppDetail: `/deployagent/apps/PPRODUCTS_2/latest/api/AppDefinition_Archive/GetAppDetail`,
    GetFeaturedApps: `/deployagent/apps/PPRODUCTS_6/latest/api/FeaturedApp/GetFeaturedApps`,
    GetTopTenApps: `/deployagent/apps/PPRODUCTS_2/latest/api/AppDefinition_Archive/GetTopTenApps`,
    GetOpportunityApps: `/deployagent/apps/PPRODUCTS_6/latest/api/OpportunityApp/GetOpportunityApps`,
    GetEvaluationBySystemName: `/deployagent/apps/PPRODUCTS_2/latest/api/EvaluationEntry/GetEvaluationBySystemName?systemname=`,
    CreateSubscription: `/deployagent/apps/PPRODUCTS_SUBSCRIPTION/latest/api/Subscription_/CreateSubscription`,
    CreateCollect: `/deployagent/apps/PPRODUCTS_5/latest/api/COLLECTFLOW/CreateCollect`,
    CreateAppIntroduction: `/deployagent/apps/PPRODUCTS_2/latest/api/AppIntroduction/CreateAppIntroduction`,
    CreateFavApps: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoriteApps/CreateFavApps`,
    DeleteFavApps: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoriteApps/DeleteFavApps`,
    GetFavAppsList: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoriteApps/GetFavAppsList`,

    GetConsultanciesFilter: `/deployagent/apps/PPRODUCTS_2/latest/api/CONSULTANCY_Process_Archive/GetConsultanciesFilter`,
    GetConsultancyDetail: `/deployagent/apps/PPRODUCTS_2/latest/api/CONSULTANCY_Process_Archive/GetConsultancyDetail`,
    GetFeaturedConsultancy: `/deployagent/apps/PPRODUCTS_6/latest/api/FeaturedConsultancy/GetFeaturedConsultancy`,
    GetTopTenConsultancy: `/deployagent/apps/PPRODUCTS_2/latest/api/CONSULTANCY_Process_Archive/GetTopTenConsultancy`,
    GetOppurtunityConsultancy: `/deployagent/apps/PPRODUCTS_6/latest/api/OpportunityConsultancy/GetOppurtunityConsultancy`,
    CreateConsultancyRequest: `/deployagent/apps/PPRODUCTS_2/latest/api/ConsRequest/CreateConsultancyRequest`,
    CreateFavConsultancies: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoriteConsultancies/CreateFavConsultancies`,
    DeleteFavConsultancies: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoriteConsultancies/DeleteFavConsultancies`,
    GetFavConsultanciesList: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoriteConsultancies/GetFavConsultanciesList`,

    GetPartners: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetPartners`,
    GetPartnerDetail: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetPartnerDetail`,
    GetEvaluationByPartnerCode: `/deployagent/apps/PPRODUCTS_2/latest/api/EvaluationEntry/GetEvaluationByPartnerCode?shortcode=`,
    CreateFavPartners: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoritePartners/CreateFavPartners`,
    DeleteFavPartners: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoritePartners/DeleteFavPartners`,
    GetFavPartnersList: `/deployagent/apps/PPRODUCTS_6/latest/api/FavoritePartners/GetFavPartnersList`,

    GetCategories: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetCategories`,
    GetServices: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetServices`,
    GetSectors: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetSectors`,
    GetSegments: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetSegments`,
    GetProducts: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetProducts`,

    GetCountries: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetCountries`,
    GetCitiesByCountry: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetCitiesByCountry`,
    GetNationalCodes: `/deployagent/apps/PPRODUCTS_4/latest/api/PartnerDefinition/GetNationalCodes`,

    GetCustomerInfo: `/deployagent/apps/BC_Cari_Kayit/latest/api/Form1/GetCustomerInfo`,
    TenantRegistration: `/deployagent/apps/BimserSaaS/latest/api/tenantRegistration/OpenForm`,
  },
};
