import { Link } from "react-router-dom";
import { List } from "antd";
import { BCAvatar } from "..";
import { IListProps } from "./entities";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const BCList = (props: IListProps) => {
  const { data, loading, onClick } = props;

  return (
    <List
      className="listContainer"
      // header={<div>Header</div>}
      // footer={<div>Footer</div>}
      bordered
      loading={loading}
      dataSource={data}
      size="small"
      itemLayout="horizontal"
      renderItem={(item: any, index) => {
        if (item.appname) {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<BCAvatar src={useBlobUrl(item.logo)} />}
                title={
                  <Link
                    onClick={onClick}
                    to={`applications/${item.systemname}/detail`}
                  >
                    {item.appname}
                  </Link>
                }
                description={item.summaryinformation}
              />
            </List.Item>
          );
        }
        if (item.consultancyname) {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<BCAvatar src={useBlobUrl(item.logo)} />}
                title={
                  <Link
                    onClick={onClick}
                    to={`consultancies/${item.systemname}/detail`}
                  >
                    {item.consultancyname}
                  </Link>
                }
                description={item.summaryinformation}
              />
            </List.Item>
          );
        }
        if (item.partnerName) {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<BCAvatar src={useBlobUrl(item.logo)} />}
                title={
                  <Link
                    onClick={onClick}
                    to={`partners/${item.shortCode}/detail`}
                  >
                    {item.partnerName}
                  </Link>
                }
                description={item.coverLetter}
              />
            </List.Item>
          );
        }
      }}
    />
  );
};

export default BCList;
