import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { MenuProps } from "antd";
import { LiaUsersSolid } from "react-icons/lia";
import { PiUsersThreeBold } from "react-icons/pi";
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import {
  BCButton,
  BCEmpty,
  BCLayout,
  BCLayoutContent,
  BCLayoutSider,
  BCList,
  BCMenu,
} from "@components/index";
import { SearchContentProps } from "./entities";
import "./assets/style.scss";

const SearchContent: React.FC<SearchContentProps> = ({
  setIsOpenPopover,
  data,
  setSelectedMenu,
  loading,
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isBreakpoint, setIsBreakpoint] = useState<boolean>(false);

  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      label,
      key,
      icon,
      children,
      type,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem(t("000049"), "menu1", <AppstoreOutlined />),
    getItem(t("000050"), "menu2", <LiaUsersSolid size="18px" />),
    getItem(t("000051"), "menu3", <PiUsersThreeBold size="18px" />),
  ];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setIsOpenPopover(false);
  };

  const onBreakpoint = (broken: boolean) => {
    setIsBreakpoint(broken);
  };

  const onMenuClick = (e: any) => {
    if (setSelectedMenu) setSelectedMenu(e.key);
  };

  let content = null;

  if (loading || data.length)
    content = <BCList onClick={onClose} data={data} loading={loading} />;
  if (!data.length && !loading) content = <BCEmpty className="emptyContent" />;

  return (
    <div
      className="searchListPopoverContainer"
      style={{ width: isBreakpoint ? "94vw" : "500px", height: "360px" }}
    >
      <BCLayout>
        <BCLayoutContent>
          <div className="searchListContentContainer">{content}</div>
        </BCLayoutContent>
        <BCLayoutSider
          collapsed={collapsed}
          collapsedWidth={"40px"}
          width={148}
          onBreakpoint={onBreakpoint}
          className="searchListSiderContainer"
        >
          <div className="searchListSiderContent">
            <BCButton
              type="text"
              onClick={onClose}
              content={<CloseOutlined />}
            />

            <BCMenu
              collapsed={collapsed}
              items={items}
              mode="inline"
              className="searchListMenu"
              defaultSelectedKeys={["menu1"]}
              onClick={onMenuClick}
            />
          </div>
          <BCButton
            type="text"
            onClick={toggleCollapsed}
            className="searchListSiderCollepsedButton"
            content={collapsed ? <LeftOutlined /> : <RightOutlined />}
          />
        </BCLayoutSider>
      </BCLayout>
    </div>
  );
};

export default SearchContent;
