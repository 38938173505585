import { Button } from "antd";
import { IButtonProps } from "./entities";

const BCButton: React.FC<IButtonProps> = (props) => {
  const {
    block,
    className,
    content,
    danger,
    disabled,
    ghost,
    href,
    htmlType,
    icon,
    iconPosition,
    loading,
    shape,
    size,
    style,
    target,
    type,
    onClick,
  } = props;

  return (
    <Button
      block={block}
      className={className}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      href={href}
      htmlType={htmlType}
      icon={icon}
      iconPosition={iconPosition}
      loading={loading}
      shape={shape}
      size={size}
      style={style}
      target={target}
      type={type}
      onClick={onClick}
    >
      {content}
    </Button>
  );
};

export default BCButton;
