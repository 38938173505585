import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAuthSliceState } from "./entities";
import { RootState } from "@store/index";

const initialState: IAuthSliceState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<IAuthSliceState>) => {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
    },
    logOut: (state) => {
      state.token = null;
      state.user = null;
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      localStorage.removeItem("customer");
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => state.auth.token;
