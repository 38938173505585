import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { ArrowRightOutlined } from "@ant-design/icons";
import { BCButton, BCCardApplication, BCSkeleton } from "@components/index";
import { appFilter } from "@store/index";
import { IApplicationCarouselProps } from "./entities";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/style.scss";
import { useBlobUrl } from "@utils/index";

const ApplicationCarousel = (props: IApplicationCarouselProps) => {
  const { headerTittle, data, loading, success, applicationType } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showAllApps = (applicationType: string) => {
    if (applicationType == "topTenApps") {
      dispatch(
        appFilter({
          filters: [
            {
              key: "02",
              position: "0-0-1",
              title: "Yeni Uygulamalar",
              value: 2,
            },
          ],
        })
      );
    }

    if (applicationType == "opportunityApps") {
      dispatch(
        appFilter({
          filters: [
            { key: "03", position: "0-0-2", title: "Fırsatlar", value: 3 },
          ],
        })
      );
    }
  };

  let content = null;

  if (loading)
    content = [1, 2, 3, 4].map(() => {
      return (
        <SwiperSlide>
          <BCSkeleton loading={loading} paragraph={{ rows: 2 }} round={false} />
        </SwiperSlide>
      );
    });

  if (success && data?.length)
    content = data.map(
      (app: {
        appname: string;
        average: number;
        comment: number;
        documentid: number;
        icon: string;
        logo: string;
        producer: string;
        summaryinformation: string;
        systemname: string;
      }) => {
        return (
          <SwiperSlide>
            <BCCardApplication
              navigationUrl={`applications/${app.systemname}/detail`}
              comment={app.comment}
              avatar={useBlobUrl(app.icon)}
              average={app.average}
              producer={app.producer}
              appname={app.appname}
              summaryinformation={app.summaryinformation}
            />
          </SwiperSlide>
        );
      }
    );

  return (
    <div>
      <div className="applicationCarouselTopContainer">
        <p>{headerTittle}</p>
        <BCButton
          onClick={() => showAllApps(applicationType as string)}
          href="#/applications/filter/all"
          content={t("000006")}
          type="text"
          icon={<ArrowRightOutlined />}
          iconPosition="end"
        />
      </div>
      <Swiper
        className="swiperApplicationCarouselContainer"
        modules={[Navigation, Pagination, A11y, Autoplay]}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        autoplay={{
          delay: 99958,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        breakpoints={{
          500: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          520: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          650: {
            slidesPerView: 1.8,
            spaceBetween: 10,
          },
          800: {
            slidesPerView: 2.4,
            spaceBetween: 10,
          },
          915: {
            slidesPerView: 2.8,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1180: {
            slidesPerView: 3.2,
            spaceBetween: 10,
          },
          1290: {
            slidesPerView: 3.6,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
      >
        {content}
        <div className="navigationContainer">
          <span className="swiper-button-prev" />
          <span className="swiper-button-next" />
        </div>
      </Swiper>
    </div>
  );
};

export default ApplicationCarousel;
