import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
	BCButton,
	BCCardPartner,
	BCDrawer,
	BCLayout,
	BCLayoutContent,
	BCLayoutSider,
	BCSkeleton
} from '@components/index';
import { PageTopCarousel, PlatinumPartnerCarousel } from '@features/index';
import { useLazyGetPartnersQuery } from '@services/index';
import { partnerFilter, selectCurrentPartnerFilters } from '@store/index';
import FilterContent from './components/filterContent';
import FilterSide from './components/filterSide';
import './assets/style.scss';
import { useBlobUrl } from '@utils/index';

const Partners = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const currentFilters = useSelector(selectCurrentPartnerFilters);

	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [isBreakpoint, setIsBreakpoint] = useState<boolean>(false);
	const [selectedCountry, setSelectedCountry] = useState<any>(null);
	const [selectedCity, setSelectedCity] = useState<any>(null);
	const [disabledCountry, setDisabledCountry] = useState<boolean>(true);
	const [disabledCity, setDisabledCity] = useState<boolean>(true);

	useEffect(() => {
		if (!selectedCountry) setDisabledCity(true);
		else setDisabledCity(false);
	}, [selectedCountry]);

	const [
		getPartners,
		{
			isLoading: getPartnersisLoading,
			isSuccess: getPartnersisSuccess,
			isError: getPartnersisErrors,
			error: getPartnerserror,
			data: filteredPartners
		}
	] = useLazyGetPartnersQuery();

	useEffect(() => {
		getPartners({
			name: '',
			country: '',
			city: '',
			segments: null,
			industryInformation: null,
			services: null,
			supportGivenProducts: null
		});
	}, []);

	let goldPartnersContent = null;
	let silverPartnersContent = null;

	if (getPartnersisLoading) {
		goldPartnersContent = [0, 1, 2].map(() => {
			return (
				<BCSkeleton
					loading={getPartnersisLoading}
					paragraph={{ rows: 2 }}
					round={false}
					style={{ width: '350px' }}
				/>
			);
		});

		silverPartnersContent = [0, 1, 2].map(() => {
			return (
				<BCSkeleton
					loading={getPartnersisLoading}
					paragraph={{ rows: 2 }}
					round={false}
					style={{ width: '350px' }}
				/>
			);
		});
	}

	if (getPartnersisSuccess && filteredPartners?.length) {
		goldPartnersContent = filteredPartners
			?.filter((partner: any) => partner.segment === 'Gold')
			.slice(0, 9)
			.map((partner: any) => {
				return (
					<BCCardPartner
						avatar={useBlobUrl(partner.logo)}
						partnerName={partner.partnerName}
						coverLetter={partner.coverLetter}
						segment="Gold"
						navigationUrl={`${partner.shortCode}/detail`}
					/>
				);
			});

		silverPartnersContent = filteredPartners
			?.filter((partner: any) => partner.segment === 'Silver' || partner.segment === null)
			.slice(0, 9)
			.map((partner: any) => {
				return (
					<BCCardPartner
						avatar={useBlobUrl(partner.logo)}
						partnerName={partner.partnerName}
						coverLetter={partner.coverLetter}
						segment="Silver"
						navigationUrl={`${partner.shortCode}/detail`}
					/>
				);
			});
	}

	const resetFilter = () => {
		dispatch(partnerFilter({ filters: [] }));
		setDisabledCountry(true);
		setDisabledCity(true);
		setSelectedCountry(null);
		setSelectedCity(null);
	};

	useEffect(() => {
		if (location.pathname == '/partners') resetFilter();
	}, [location]);

	const breakpoints = {
		700: { slidesPerView: 1.1, spaceBetween: 10 },
		750: { slidesPerView: 1.3, spaceBetween: 10 },
		800: { slidesPerView: 1.5, spaceBetween: 10 },
		850: { slidesPerView: 1.7, spaceBetween: 10 },
		960: { slidesPerView: 1.9, spaceBetween: 10 },
		1024: { slidesPerView: 2.1, spaceBetween: 10 },
		1124: { slidesPerView: 2.4, spaceBetween: 10 },
		1200: { slidesPerView: 3, spaceBetween: 10 },
		1280: { slidesPerView: 3.5, spaceBetween: 10 }
	};

	const handleTagClose = (tagKey: string) => {
		const filteredTags = currentFilters?.filter(filter => filter.key !== tagKey);
		dispatch(partnerFilter({ filters: filteredTags }));

		if (tagKey == '51') {
			setSelectedCountry(null);
			setSelectedCity(null);
			setDisabledCountry(true);
			setDisabledCity(true);
		}
	};

	const showAllPartners = (partnerType: string) => {
		if (partnerType == 'gold') {
			dispatch(
				partnerFilter({
					filters: [{ key: '500', position: '0-3-0', title: 'GOLD', value: '500' }]
				})
			);
		}
		if (partnerType == 'silver') {
			dispatch(
				partnerFilter({
					filters: [{ key: '600', position: '0-3-1', title: 'SİLVER', value: '600' }]
				})
			);
		}
	};

	const onCollapse = (value: boolean, type: 'clickTrigger' | 'responsive') => {
		setIsBreakpoint(value);
		setCollapsed(value);
	};

	return (
		<>
			<PageTopCarousel />
			<BCLayout>
				{collapsed ? (
					<BCDrawer>
						<FilterSide
							selectedCity={selectedCity}
							setSelectedCity={setSelectedCity}
							selectedCountry={selectedCountry}
							setSelectedCountry={setSelectedCountry}
							disabledCity={disabledCity}
							setDisabledCity={setDisabledCity}
							disabledCountry={disabledCountry}
							setDisabledCountry={setDisabledCountry}
						/>
					</BCDrawer>
				) : null}
				<BCLayoutSider
					className="partnersLayoutSider"
					style={{ margin: collapsed ? '0' : '20px 0 0 3%' }}
					collapsed={collapsed}
					collapsedWidth={'0'}
					onCollapse={onCollapse}
				>
					<FilterSide
						selectedCity={selectedCity}
						setSelectedCity={setSelectedCity}
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
						disabledCity={disabledCity}
						setDisabledCity={setDisabledCity}
						disabledCountry={disabledCountry}
						setDisabledCountry={setDisabledCountry}
					/>
				</BCLayoutSider>
				<BCLayoutContent className="partnersLayoutContent">
					{location.pathname.startsWith('/partners/filter/') ? (
						<FilterContent
							handleTagClose={handleTagClose}
							selectedCountry={selectedCountry}
							selectedCity={selectedCity}
						/>
					) : (
						<>
							<div
								style={{ paddingRight: isBreakpoint ? '3%' : '3%' }}
								className="partnersPlatinumPartnerCarousel"
							>
								<PlatinumPartnerCarousel
									breakpoints={breakpoints}
									partners={filteredPartners}
									getPartnersisLoading={getPartnersisLoading}
									getPartnersisSuccess={getPartnersisSuccess}
								/>
							</div>
							<div className="partnersContainer">
								<div>
									<div className="partnersTopContainer">
										<p>{t('000012')}</p>
										<BCButton
											onClick={() => showAllPartners('gold')}
											href="#/partners/filter/all"
											content={t('000006')}
											type="text"
											icon={<ArrowRightOutlined />}
											iconPosition="end"
										/>
									</div>
									<div className="partnersContentContainer">
										{goldPartnersContent}
									</div>
								</div>
								<div>
									<div className="partnersTopContainer">
										<p>{t('000013')}</p>
										<BCButton
											onClick={() => showAllPartners('silver')}
											href="#/partners/filter/all"
											content={t('000006')}
											type="text"
											icon={<ArrowRightOutlined />}
											iconPosition="end"
										/>
									</div>
									<div className="partnersContentContainer">
										{silverPartnersContent}
									</div>
								</div>
							</div>
						</>
					)}
				</BCLayoutContent>
			</BCLayout>
		</>
	);
};

export default Partners;
