import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	UserAddOutlined,
	ExportOutlined,
	StarOutlined,
	FieldTimeOutlined,
	AppstoreOutlined,
	DownOutlined
} from '@ant-design/icons';
import { TbLogin, TbDotsVertical } from 'react-icons/tb';
import { IoLanguageSharp } from 'react-icons/io5';
import { logOut, openModal } from '@store/index';
import {
	BCButton,
	BCSpace,
	BCFlex,
	BCPopover,
	BCMeta,
	BCAvatar,
	BCDropdown
} from '@components/index';
import { logoWhite } from '@assets/index';
import SearchList from '@features/searchList';
import { useCustomer, useAuth } from '@utils/index';
import './assets/style.scss';

const Header = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const isAuth = useAuth();
	const user = useCustomer();

	const localLanguage = localStorage.getItem('i18nextLng');

	const [visiblePopover, setVisiblePopover] = useState(false);
	const [openLoginPopover, setOpenLoginPopover] = useState(false);
	const [logoutLoading, setLogoutLoading] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(localLanguage || 'tr-TR');

	const languages = [
		{ key: '1', label: 'TR' },
		{ key: '2', label: 'EN' }
	];

	const handleVisibleChangePopover = (visibleUserPopover: boolean) => {
		setVisiblePopover(visibleUserPopover);
	};
	const hidePopover = () => {
		setVisiblePopover(false);
	};

	const hideLoginPopover = () => {
		setOpenLoginPopover(false);
	};
	const handleOpenChange = (newOpen: boolean) => {
		setOpenLoginPopover(newOpen);
	};

	const handleLogout = () => {
		setLogoutLoading(true);

		setTimeout(() => {
			dispatch(logOut());
			setLogoutLoading(false);
			hidePopover();
		}, 1800);
	};

	const portalMenuItems = () => {
		const items = user?.tenantInfoDetails?.map((tenant: any, index: number) => {
			const { firstName, lastName, tenantURL } = tenant;

			return {
				key: `${index}_${tenantURL}`, // Ensure a unique key
				label: (
					<a
						onClick={hidePopover}
						target="_blank"
						href={`http://${tenantURL}`}
					>
						{`${firstName} ${lastName}`}
					</a>
				)
			};
		});

		return items || [];
	};

	const handlePortalMenuClick = (e: any) => {
		if (e.key === '3') {
		}
	};

	const handleLanguageClick = (e: any) => {
		const selectedLang = e.key === '1' ? 'tr-TR' : 'en-US';
		i18n.changeLanguage(selectedLang);
		setSelectedLanguage(selectedLang);
		location.reload();
	};

	const loggedInContent = () => {
		const hostname = window.location.hostname;
		const subdomain = hostname.split('.')[0];
		const myAccountHref =
			subdomain == 'store'
				? 'https://synergynow.io/my-account/'
				: 'https://synergynow.io/test/my-account/';
		const myOrderHistoryHref =
			subdomain == 'store'
				? 'https://synergynow.io/my-account/#?page=my-orders'
				: 'https://synergynow.io/test/my-account/#?page=my-orders';

		return (
			<div className="loggedInContent">
				<div className="loggedInContentTop">
					<BCMeta
						avatar={
							<BCAvatar
								size={56}
								icon={<UserAddOutlined />}
							/>
						}
						title={`${user?.userInfoDetails?.firstname} ${user?.userInfoDetails?.lastname}`}
						description={user?.userInfoDetails?.email}
					/>
					<BCButton
						content={t('000076')}
						href={myAccountHref}
						target="_blank"
					/>
					{/* <BCButton text={t('000076')} onClick={hidePopover} href='/#/dash' /> */}
				</div>
				<BCButton
					onClick={hidePopover}
					icon={<StarOutlined />}
					iconPosition="start"
					content={t('000077')}
					href="/#/dash/favorites/apps"
					type="text"
				/>
				<BCButton
					onClick={hidePopover}
					icon={<FieldTimeOutlined />}
					iconPosition="start"
					content={t('000078')}
					href={myOrderHistoryHref}
					target="_blank"
					type="text"
				/>
				{user?.tenantInfoDetails?.length > 1 ? (
					<BCDropdown
						items={portalMenuItems() || []}
						onClick={handlePortalMenuClick}
						overlayClassName="portalMenuItemsContainer"
					>
						<BCButton
							icon={<AppstoreOutlined />}
							iconPosition="start"
							content={
								<>
									Synergy Portal <DownOutlined />
								</>
							}
							type="text"
						/>
					</BCDropdown>
				) : (
					<BCButton
						onClick={hidePopover}
						icon={<AppstoreOutlined />}
						iconPosition="start"
						content="Synergy Portal"
						type="text"
						href={`http://${user?.tenantInfoDetails?.[0]?.tenantURL}`}
						target="_blank"
					/>
				)}
				<BCButton
					onClick={handleLogout}
					loading={logoutLoading}
					icon={<ExportOutlined />}
					iconPosition="start"
					content={t('000079')}
					type="text"
				/>
			</div>
		);
	};

	const loggedOutContent = (
		<div className="loggedOutContent">
			<BCButton
				type="text"
				size="small"
				content={t('000001')}
				icon={<UserAddOutlined />}
				iconPosition="start"
				onClick={() => {
					dispatch(openModal({ modal: 'Register' }));
					hideLoginPopover();
				}}
			/>
			<BCButton
				type="text"
				size="small"
				content={t('000002')}
				icon={<TbLogin size="18px" />}
				iconPosition="start"
				onClick={() => {
					dispatch(openModal({ modal: 'Login' }));
					hideLoginPopover();
				}}
			/>
		</div>
	);

	return (
		<BCFlex
			justify="space-between"
			align="center"
			gap="small"
			className="headerContainer"
		>
			<img
				src={logoWhite}
				className="headerLogo"
			/>
			<SearchList />
			<BCSpace>
				<BCDropdown
					className="changeLanguageContainer"
					overlayClassName="changeLanguageOverlayContainer"
					items={languages}
					onClick={handleLanguageClick}
				>
					<BCButton
						content={
							<span style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
								<IoLanguageSharp />
								<span>{selectedLanguage == 'tr-TR' ? 'TR' : 'EN'}</span>
								<DownOutlined />
							</span>
						}
						type="text"
						style={{ color: '#fff' }}
					/>
				</BCDropdown>
				{user && isAuth ? (
					<BCPopover
						open={visiblePopover}
						onOpenChange={handleVisibleChangePopover}
						content={loggedInContent}
						trigger={'click'}
						placement="bottomLeft"
					>
						<Avatar
							size={42}
							shape="circle"
							icon={<UserAddOutlined />}
							className="avatar"
						/>
					</BCPopover>
				) : (
					<div>
						<div className="loggedOutContentLargeScreen">{loggedOutContent}</div>
						<div className="loggedOutContentSmallScreen">
							<BCPopover
								trigger={'click'}
								content={loggedOutContent}
								placement="bottomLeft"
								open={openLoginPopover}
								onOpenChange={handleOpenChange}
							>
								<TbDotsVertical size="22px" />
							</BCPopover>
						</div>
					</div>
				)}
			</BCSpace>
		</BCFlex>
	);
};

export default Header;
