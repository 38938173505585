import { Layout } from 'antd';
import { ILayoutHeaderProps } from './entities';

const BCLayoutHeader = (props: ILayoutHeaderProps) => {
  const { className, style } = props;
  const { Header } = Layout;
  
  return (
    <Header
      className={className}
      style={style}
    >
      {props.children}
    </Header>
  )
}

export default BCLayoutHeader