import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BCSkeleton, BCCardFavorite, BCEmpty } from '@components/index';
import { useLazyGetFavAppsListQuery, useLazyGetFavConsultanciesListQuery, useLazyGetFavPartnersListQuery } from '@services/index'
import './assets/style.scss';

const Favorites = () => {

    const { pathname } = useLocation()
    const { t } = useTranslation()

    const [getFavApps, {
        data: favApps,
        isLoading: favAppsisLoading,
        isSuccess: favAppsisSuccess,
        isError: favAppsisError,
        error: favAppserror
    }] = useLazyGetFavAppsListQuery()

    const [getFavConsultancies, {
        data: favConsultancies,
        isLoading: favConsultanciesisLoading,
        isSuccess: favConsultanciesisSuccess,
        isError: favConsultanciesisError,
        error: favConsultancieserror
    }] = useLazyGetFavConsultanciesListQuery()

    const [getFavPartners, {
        data: favPartners,
        isLoading: favPartnersisLoading,
        isSuccess: favPartnersisSuccess,
        isError: favPartnersisError,
        error: favPartnerserror
    }] = useLazyGetFavPartnersListQuery()

    useEffect(() => {
        if (pathname == '/dash/favorites/apps') getFavApps() 
        if (pathname == '/dash/favorites/consultancies') getFavConsultancies() 
        if (pathname == '/dash/favorites/partners') getFavPartners() 
    }, [pathname])


    let content = null;
    let header = null;

    if (favAppsisLoading || favConsultanciesisLoading || favPartnersisLoading) content = [0, 1, 2].map(() => {
        return <BCSkeleton
            loading={favAppsisLoading || favConsultanciesisLoading || favPartnersisLoading}
            paragraph={{ rows: 2 }}
            round={false}
            style={{ width: "350px" }}
        />
    })

    if (pathname == '/dash/favorites/apps') {

        header = <h4>{t('000133')}</h4>

        if (favAppsisSuccess && favApps?.length) {
            content = favApps.map((app: any) => {
                return <BCCardFavorite
                    data={app}
                />
            })
        }

        if (!favAppsisLoading && !favApps?.length) {
            content = <BCEmpty style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "22vh", width: "100%" }} />
        }
    }

    if (pathname == '/dash/favorites/consultancies') {

        header = <h4>{t('000134')}</h4>

        if (favConsultanciesisSuccess && favConsultancies?.length) {
            content = favConsultancies.map((consultancy: any) => {
                return <BCCardFavorite
                    data={consultancy}
                />
            })
        }

        if (!favConsultanciesisLoading && !favConsultancies?.length) {
            content = <BCEmpty style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "22vh", width: "100%" }} />
        }
    }

    if (pathname == '/dash/favorites/partners') {

        header = <h4>{t('000135')}</h4>

        if (favPartnersisSuccess && favPartners?.length) {
            content = favPartners.map((partner: any) => {
                return <BCCardFavorite
                    data={partner}
                />
            })
        }

        if (!favPartnersisLoading && !favPartners?.length) {
            content = <BCEmpty style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "22vh", width: "100%" }} />
        }
    }

    return (
        <>
            <div className='favoritesContainer'>
                <div>{header}</div>
                <div className='favoritesContentContainer'>
                    {content}
                </div>
            </div>
        </>
    )
}

export default Favorites