import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IModalSliceState } from "./entities";
import { RootState } from "@store/index";

const initialState: IModalSliceState = {
  modal: null,
  parameters: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<IModalSliceState>) => {
      state.modal = action.payload.modal;
      state.parameters = action.payload.parameters;
    },
    closeModal: (state) => {
      state.modal = null;
      state.parameters = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
export const selectCurrentModal = (state: RootState) => state.modal;
