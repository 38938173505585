import { notification } from "antd";
import { INotificationProps } from "./entities";

const BCNotification = (props: INotificationProps) => {
  const { type, message, description } = props;

  notification.config({
    duration: 4,
  });

  notification[type]({
    message,
    description,
  });
};

export default BCNotification;
