import { Checkbox } from 'antd';
import { ICheckboxProps } from './entities';
import './assets/style.scss';

const BCCheckbox = (props: ICheckboxProps) => {
	const { label, content, checked, defaultChecked, disabled, indeterminate, onChange, name } =
		props;

	return (
		<div className="BCCheckboxContainer">
			<Checkbox
				defaultChecked={defaultChecked}
				disabled={disabled}
				indeterminate={indeterminate}
				onChange={onChange}
				name={name}
			>
				{label}
			</Checkbox>
			{content ? <span>{content}</span> : null}
		</div>
	);
};

export default BCCheckbox;
