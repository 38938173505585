import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState, logOut, setCredentials } from "@store/index";
import { ServiceList } from "..";
import { getBaseQuery, getDeployAgent } from "@constants/index";

const baseQueryResult = getBaseQuery();

const baseQuery1 = fetchBaseQuery({
  baseUrl: baseQueryResult.baseQuery.baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    headers.set(
      "Bimser-Encrypted-Data",
      baseQueryResult.baseQuery.bimserEncryptedData
    );
    headers.set("Bimser-FootPrint", baseQueryResult.baseQuery.bimserFootPrint);
    headers.set("Bimser-Language", "tr-TR");
    headers.set("Content-Type", "application/json");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQuery2 = fetchBaseQuery({
  baseUrl: baseQueryResult.baseQueryBD.baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    headers.set(
      "Bimser-Encrypted-Data",
      baseQueryResult.baseQueryBD.bimserEncryptedData
    );
    headers.set(
      "Bimser-FootPrint",
      baseQueryResult.baseQueryBD.bimserFootPrint
    );
    headers.set("Bimser-Language", "tr-TR");
    headers.set("Content-Type", "application/json");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

/*
@args: request url, method, body
@api: signal, dispatch, getState()
@extraOptions: custom like {shout: true}
*/
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any;

  if (ServiceList.baseQuery1.hasOwnProperty(api.endpoint)) {
    result = await baseQuery1(args, api, extraOptions);
  } else if (ServiceList.baseQuery2.hasOwnProperty(api.endpoint)) {
    args.url = getDeployAgent(args.url) + args.url;
    result = await baseQuery2(args, api, extraOptions);
  } else {
    args.url = getDeployAgent(args.url) + args.url;
    result = await baseQuery2(args, api, extraOptions);
  }

  // // If you want, handle other status codes, too
  // if(result?.error?.status === 403) {
  //     console.log('sending refresh token')

  //     // send refresh token to get new access token
  //     const refreshResult = await baseQuery1('/auth/refresh', api, extraOptions)

  //     if(refreshResult?.data) {
  //         const user = api.getState().auth.user

  //         //store the new token
  //         api.dispatch(setCredentials({token: refreshResult.data as string, user}))

  //         //retry the original query with new access token
  //         result = await baseQuery1(args, api, extraOptions)
  //     } else {

  //         if (refreshResult?.error?.status === 403) {
  //             refreshResult?.error?.data?.message = "Your login has expired."
  //             api.dispatch(logOut())
  //         }
  //         return refreshResult

  //     }
  // }

  return result;
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Application", "Partner", "Consultancy", "Filter"],
  endpoints: () => ({}),
});
