import { Popover } from 'antd';
import { IPopoverProps } from './entities';
import './assets/style.scss'


const BCPopover = (props: IPopoverProps) => {
    const { trigger, content, open, onOpenChange, title, placement, arrow, className } = props;

    return (

        <Popover
            trigger={trigger}
            open={open}
            onOpenChange={onOpenChange}
            content={content}
            title={title}
            placement={placement}
            arrow={arrow}
            className={className}
        >
            {props.children}
        </Popover>
    )
}

export default BCPopover