import { Layout } from 'antd';
import { ILayoutSiderProps } from './entities';

const BCLayoutSider = (props: ILayoutSiderProps) => {
  const { collapsed, style, className, width, onBreakpoint, collapsedWidth, onCollapse } = props;
  const { Sider } = Layout;

  return (
    <Sider
      className={className}
      style={style}
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsedWidth={collapsedWidth}
      width={width}
      breakpoint="sm"
      onBreakpoint={onBreakpoint}
      theme='light'
    >
      {props.children}
    </Sider >
  )
}

export default BCLayoutSider