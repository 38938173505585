import { BCCard } from '@components/index'
import { ICardSameAppProps } from './entities'
import { StarFilled } from '@ant-design/icons';
import './assets/style.scss'


const BCCardSameApp = (props: ICardSameAppProps) => {
    const { avatar, title, description, average, hoverable, bordered } = props;
    return (
        <BCCard bordered={bordered} hoverable={hoverable} bodyStyle={{ width: "100%", height: "100%", padding: 0 }}>
            <div className='cardSameAppContainer'>
                <div className='cardSameAppImage'>
                    <img src={avatar} />
                </div>
                <div className='cardSameAppContent'>
                    <h4>{title}</h4>
                    <p>{description}</p>
                    {average ? <span>{average} <StarFilled /></span> : null}
                </div>
            </div>
        </BCCard>
    )
}

export default BCCardSameApp