import { BCCard } from '@components/index'
import { ICardSameConsultancyProps } from './entities'
// import { FieldTimeOutlined } from '@ant-design/icons';
import './assets/style.scss'


const BCCardSameConsultancy = (props: ICardSameConsultancyProps) => {
    const { avatar, title, description, time, hoverable, bordered } = props;
    return (
        <BCCard bordered={bordered} hoverable={hoverable} bodyStyle={{ width: "100%", height: "100%", padding: 0 }}>
            <div className='cardSameConsultancyContainer'>
                <div className='cardSameConsultancyImage'>
                    <img src={avatar} />
                </div>
                <div className='cardSameConsultancyContent'>
                    <h4>{title}</h4>
                    <p>{description}</p>
                    {/* <time><FieldTimeOutlined size={2} /> {time}</time> */}
                </div>
            </div>
        </BCCard>
    )
}

export default BCCardSameConsultancy