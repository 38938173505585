import { Layout } from 'antd';
import { ILayoutContentProps } from './entities';

const BCLayoutContent = (props: ILayoutContentProps) => {
    const { style, className } = props;
    const { Content } = Layout;

    return (
        <Content
            className={className}
            style={style}
        >
            {props.children}
        </Content>
    )
}

export default BCLayoutContent