// import { useNavigate } from 'react-router-dom';
// import { Button, Result } from 'antd';

const ErrorBoundary = (props: any) => {
  // const navigate = useNavigate()

  // if (currentError == 500) {
  //     return (
  //         <Result
  //             status="500"
  //             title="500"
  //             subTitle="Sorry, something went wrong."
  //             extra={<Button type="primary" onClick={() => {
  //                 navigate('/')
  //                 location.reload()
  //             }}>Back Home</Button>}
  //         />
  //     )
  // }

  return <>{props.children}</>;
};

export default ErrorBoundary;
