import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	BCButton,
	BCCheckbox,
	BCForm,
	BCFormItem,
	BCInput,
	BCModal,
	BCSelect,
	BCTooltip
} from '@components/index';
import { useGetNationalCodesQuery, useTenantRegistrationMutation } from '@services/index';
import { closeModal, openModal } from '@store/index';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { Form } from 'antd';
import './assets/style.scss';

const Register = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [canModalClose, setCanModalClose] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

	const [nationalCode, setNationalCode] = useState<string>('+90');

	const [submittable, setSubmittable] = useState<boolean>(false);
	const [form] = Form.useForm();
	const values = Form.useWatch([], form);

	useEffect(() => {
		form.validateFields({ validateOnly: true })
			.then(() => setSubmittable(true))
			.catch(() => setSubmittable(false));
	}, [form, values]);

	const {
		data: nationalCodes,
		isLoading: nationalCodesIsLoading,
		isSuccess: nationalCodesIsSuccess,
		isError: nationalCodesIsError,
		error: nationalCodesError
	} = useGetNationalCodesQuery();

	const [
		register,
		{
			isLoading: registerIsLoading,
			isSuccess: registerIsSuccess,
			isError: registerIsError,
			error: registerError,
			data: registerData
		}
	] = useTenantRegistrationMutation();

	useEffect(() => {
		if (registerIsSuccess) {
			dispatch(openModal({ modal: 'Login' }));
			setCanModalClose(true);
		}
	}, [registerIsSuccess]);

	useEffect(() => {
		if (registerIsLoading) setCanModalClose(false);
	}, [registerIsLoading]);

	useEffect(() => {
		if (registerIsError) setCanModalClose(true);
	}, [registerIsError]);

	const getNationalCodeOptions = () => {
		return (nationalCodes || []).map((code: any) => ({
			label: code.key,
			value: code.key,
			desc: `${code.text} (${code.key})`
		}));
	};

	const filterOption = (
		input: string,
		option?: { label: string; value: string; desc: string }
	) => {
		return (option?.desc ?? '').toLowerCase().includes(input.toLowerCase());
	};

	const handleNationalCodeChange = (value: string, option: any) => {
		setNationalCode(value);
	};

	const handleCancel = () => {
		if (canModalClose) {
			setIsModalOpen(false);
			dispatch(closeModal());
		}
	};

	const handleFinish = (values: any) => {
		try {
			const data = {
				firstName: values.user.firstName,
				lastName: values.user.lastName,
				email: values.user.email,
				company: values.user.company,
				dnsName: values.user.dnsName,
				jobTitle: values.user.jobTitle,
				phoneNumber: `${nationalCode}${values.user.phoneNumber}`,
				country: values.user.country,
				product: 69584,
				productPlan: 72288,
				languages: [values.user.languages],
				createSampleData: values.createSampleData.target.checked,
				agreePDPL: values.agreePDPL.target.checked,
				staticip: '0000000000'
			};

			register(data);
		} catch (error) {
			console.log('handleLoginError', error);
		}
	};

	return (
		<BCModal
			className="registerContainer"
			open={isModalOpen}
			title={t('000001')}
			onCancel={handleCancel}
			centered
			width={650}
			footer={[
				<BCButton
					content={t('000001')}
					htmlType="submit"
					disabled={!submittable}
					onClick={() => form.submit()}
					loading={registerIsLoading}
					style={{
						backgroundColor: !submittable ? '#F5F5F5' : '#00104E',
						color: !submittable ? '#B8B8B8' : '#fff'
					}}
				/>,
				<BCButton
					content={t('000083')}
					onClick={handleCancel}
					style={{ backgroundColor: '#fff', color: '#00104E' }}
				/>
			]}
		>
			<BCForm
				className="registerForm"
				name="validateOnly"
				form={form}
				onFinish={handleFinish}
			>
				<div>
					<BCFormItem
						name={['user', 'firstName']}
						label={t('000117')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[
							{ required: true, message: t('000150') },
							{
								validator: (_, value) => {
									if (/\d/.test(value)) {
										return Promise.reject(new Error(t('000146')));
									}
									return Promise.resolve();
								}
							}
						]}
					>
						<BCInput placeholder={t('000118')} />
					</BCFormItem>

					<BCFormItem
						name={['user', 'lastName']}
						label={t('000119')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[
							{ required: true, message: t('000150') },
							{
								validator: (_, value) => {
									if (/\d/.test(value)) {
										return Promise.reject(new Error(t('000147')));
									}
									return Promise.resolve();
								}
							}
						]}
					>
						<BCInput placeholder={t('000120')} />
					</BCFormItem>

					<BCFormItem
						name={['user', 'email']}
						label={t('000035')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[
							{ required: true, message: t('000150') },
							{ type: 'email', message: t('000151') }
						]}
					>
						<BCInput placeholder={t('000121')} />
					</BCFormItem>

					<BCFormItem
						name={['user', 'phoneNumber']}
						label={t('000036')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[
							{ required: true, message: t('000150') },
							{
								validator: (_, value) => {
									const phoneNumberRegex = /^[\d\s]+$/; // Sadece rakamlar ve boşlukları kabul eden regex
									if (value && !phoneNumberRegex.test(value)) {
										return Promise.reject(new Error(t('000148')));
									}
									return Promise.resolve();
								}
							}
						]}
					>
						<BCInput
							addonBefore={
								<BCSelect
									showSearch
									options={getNationalCodeOptions()}
									optionRender={(option: any) => <span>{option.data.desc}</span>}
									filterOption={filterOption as any}
									defaultValue="+90"
									onChange={handleNationalCodeChange}
									dropdownStyle={{ width: '310px' }}
								/>
							}
							placeholder={t('000122')}
						/>
					</BCFormItem>

					<BCFormItem
						name={['user', 'jobTitle']}
						label={t('000123')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[{ required: true, message: t('000150') }]}
					>
						<BCInput placeholder={t('000124')} />
					</BCFormItem>
				</div>
				<div>
					<BCFormItem
						name={['user', 'languages']}
						label={t('000125')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[{ required: true, message: t('000150') }]}
					>
						<BCSelect
							placeholder={t('000126')}
							options={[
								{ label: 'TR Turkish', value: 0 },
								{ label: 'Us English', value: 1 }
							]}
						/>
					</BCFormItem>

					<BCFormItem
						name={['user', 'country']}
						label={t('000127')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[{ required: true, message: t('000150') }]}
					>
						<BCSelect
							placeholder={t('000072')}
							options={[
								{ label: 'Turkey', value: 1 },
								{ label: 'USA', value: 0 }
							]}
						/>
					</BCFormItem>

					<BCFormItem
						name={['user', 'company']}
						label={t('000128')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[{ required: true, message: t('000150') }]}
					>
						<BCInput placeholder={t('000129')} />
					</BCFormItem>

					<BCFormItem
						name={['user', 'dnsName']}
						label={t('000130')}
						labelCol={{ span: 24 }}
						hasFeedback
						validateDebounce={500}
						rules={[
							{ required: true, message: t('000150') },
							{ type: 'url', message: t('000152') }
						]}
					>
						<BCInput placeholder={t('000130')} />
					</BCFormItem>

					<BCFormItem labelCol={{ span: 24 }}>
						<BCFormItem
							name="createSampleData"
							hasFeedback
							validateDebounce={200}
							rules={[
								{ required: true, message: t('000150') },
								{
									validator: (_, value) => {
										if (!value?.target?.checked) {
											return Promise.reject(
												new Error('This field must be checked')
											);
										}
										return Promise.resolve();
									}
								}
							]}
						>
							<BCCheckbox
								label={t('000131')}
								content={
									<BCTooltip
										title="Seçtiğinizde örnek veri oluşturulacaktır"
										content={<RxQuestionMarkCircled />}
										color="rgba(0, 16, 78, 0.6)"
										arrow
									/>
								}
							/>
						</BCFormItem>

						<BCFormItem
							name="agreePDPL"
							hasFeedback
							validateDebounce={200}
							rules={[
								{ required: true, message: t('000150') },
								{
									validator: (_, value) => {
										if (!value?.target?.checked) {
											return Promise.reject(new Error(t('000149')));
										}
										return Promise.resolve();
									}
								}
							]}
						>
							<BCCheckbox
								content={
									<a
										href="https://bimser.com/gizlilik-politikasi/"
										target="_blank"
									>
										{t('000132')}
									</a>
								}
							/>
						</BCFormItem>
					</BCFormItem>
				</div>
			</BCForm>
		</BCModal>
	);
};

export default Register;
