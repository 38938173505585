import { BCCol, BCForm, BCFormItem, BCInput, BCRow, BCSelect } from '@components/index'

const General = () => {

    return (
        <BCForm>
            <BCRow justify={'space-between'} align={'middle'}>
                <BCCol span={11}>
                    <BCFormItem label="Ad" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Adınızı yazınız' />
                    </BCFormItem>
                </BCCol>
                <BCCol span={11}>
                    <BCFormItem label="Soyad" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Soyadınızı yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow>
                <BCCol span={24}>
                    <BCFormItem label="Şirket Adı" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Şirket adını yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow >
                <BCCol span={24}>
                    <BCFormItem label="Ünvan" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Ünvanınızı yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow justify={'space-between'} align={'middle'}>
                <BCCol span={11}>
                    <BCFormItem label="Çalışan Sayısı" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Çalışan sayısını yazınız' />
                    </BCFormItem>
                </BCCol>
                <BCCol span={11}>
                    <BCFormItem label="Kuruluş Yılı" required labelCol={{ span: 24 }}>
                        <BCInput placeholder='Kuruluş yılını yazınız' />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow justify={'space-between'} align={'middle'}>
                <BCCol span={11}>
                    <BCFormItem label="Ülke" required labelCol={{ span: 24 }}>
                        <BCSelect placeholder="Ülkenizi seçiniz" />
                    </BCFormItem>
                </BCCol>
                <BCCol span={11}>
                    <BCFormItem label="Şehir" required labelCol={{ span: 24 }}>
                        <BCSelect placeholder="şehrinizi seçiniz" />
                    </BCFormItem>
                </BCCol>
            </BCRow>
            <BCRow >
                <BCCol span={24}>
                    <BCFormItem label="Dil Sçimi" required labelCol={{ span: 24 }}>
                        <BCSelect placeholder="Dil veya diller seçiniz" />
                    </BCFormItem>
                </BCCol>
            </BCRow>
        </BCForm>
    )
}

export default General